import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PrismicRichText } from '@prismicio/react';
import styled from '@emotion/styled';

import { CmsCtaType, CmsHomepageThinBanner } from '../../cms/types';
import { breakpoints, colors } from '../../../design-system';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';
import { hasValidCmsText } from '../../home/utils';
import { BannerCta } from '../components/BannerCta';

type Props = {
  id: string;
  content: CmsHomepageThinBanner;
};

type StyledThinBannerContainerProps = {
  textColor: string;
  textBackgroundColor: string;
  ctaType: CmsCtaType | undefined;
};

const StyledThinBannerContainer = styled.div<StyledThinBannerContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: ${breakpoints.S}px) {
    border-radius: 8px;
  }

  @media (min-width: ${breakpoints.M}px) {
    flex-direction: row;
    height: 204px;
  }

  .thin-banner-image-container {
    position: relative;
    width: 100%;
    height: 100%;

    @media (min-width: ${breakpoints.M}px) {
      width: 50%;
    }
  }

  .thin-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .thin-banner-text-on-image-dektop {
    display: none;

    @media (min-width: ${breakpoints.M}px) {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .thin-banner-text-on-image-mobile {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }

  .thin-banner-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.textBackgroundColor};
    padding: 16px;

    @media (min-width: ${breakpoints.M}px) {
      width: 50%;
    }
  }

  .thin-banner-title-desktop,
  .thin-banner-promo-description-desktop,
  .thin-banner-promotion-desktop {
    display: none;

    @media (min-width: ${breakpoints.M}px) {
      display: block;
    }
  }

  .thin-banner-title-mobile,
  .thin-banner-promo-description-mobile,
  .thin-banner-promotion-mobile {
    display: block;

    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }

  .thin-banner-text-on-image-dektop,
  .thin-banner-title-desktop,
  .thin-banner-promo-description-desktop,
  .thin-banner-promotion-desktop,
  .thin-banner-text-on-image-mobile,
  .thin-banner-title-mobile,
  .thin-banner-promo-description-mobile,
  .thin-banner-promotion-mobile {
    color: ${(props) => props.textColor};
  }

  .anchor-animated {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 700;
    color: ${(props) => (props.ctaType === 'white link' ? colors.WHITE : colors.BLACK)};
  }

  .thin-banner-cta-container {
    min-width: 244px;
  }
`;

export const ThinBanner = ({ id, content }: Props) => {
  const navigate = useNavigate();
  const {
    background_color,
    title,
    title_mobile,
    promo_description,
    promo_description_mobile,
    promo,
    promo_mobile,
    image,
    image_mobile,
    image_title,
    image_title_mobile,
    cta_name,
    cta_link,
    banner_text_color,
    cta_type,
    cta_text_color,
    cta_background_color,
  } = content ?? {};

  const handleCtaClick = () => {
    navigate(getFormatedLinkForReactRouterLink(cta_link));
  };

  const isCtaLink = cta_type === 'white link' || cta_type === 'black link';
  const isValidCta = cta_link && cta_name && cta_type;

  return (
    <StyledThinBannerContainer
      id={id}
      textColor={banner_text_color ?? colors.BLACK}
      textBackgroundColor={background_color ?? colors.WHITE}
      ctaType={cta_type}
    >
      <div className="thin-banner-image-container">
        <picture>
          <source media={`(min-width: ${breakpoints.M + 1}px)`} srcSet={image?.url} />
          <source media={`(max-width: ${breakpoints.M}px)`} srcSet={image_mobile?.url} />
          <img
            src={image?.url ?? ''}
            alt={image?.alt ?? 'image de la bannière fine'}
            className="thin-banner-image"
          />
        </picture>
        {hasValidCmsText(image_title) && (
          <div className="thin-banner-text-on-image-dektop">
            <PrismicRichText field={image_title} />
          </div>
        )}
        {hasValidCmsText(image_title_mobile) && (
          <div className="thin-banner-text-on-image-mobile">
            <PrismicRichText field={image_title_mobile} />
          </div>
        )}
      </div>
      <div className="thin-banner-text-container">
        {hasValidCmsText(title) && (
          <div className="thin-banner-title-desktop">
            <PrismicRichText field={title} />
          </div>
        )}
        {hasValidCmsText(title_mobile) && (
          <div className="thin-banner-title-mobile">
            <PrismicRichText field={title_mobile} />
          </div>
        )}
        <div className="thin-banner-promo-description-desktop">
          {hasValidCmsText(promo_description) && <PrismicRichText field={promo_description} />}
        </div>
        <div className="thin-banner-promo-description-mobile">
          {hasValidCmsText(promo_description_mobile) && (
            <PrismicRichText field={promo_description_mobile} />
          )}
        </div>
        {hasValidCmsText(promo) && (
          <div className="thin-banner-promotion-desktop">
            <PrismicRichText field={promo} />
          </div>
        )}
        {hasValidCmsText(promo_mobile) && (
          <div className="thin-banner-promotion-mobile">
            <PrismicRichText field={promo_mobile} />
          </div>
        )}
        {isCtaLink && isValidCta ? (
          <Link
            data-testid={`thin-banner-${cta_type}`}
            id={`thin-banner-${cta_type}`}
            className="anchor-animated"
            to={getFormatedLinkForReactRouterLink(cta_link)}
          >
            {cta_name}
          </Link>
        ) : isValidCta ? (
          <div className="thin-banner-cta-container">
            <BannerCta
              data-testid={`thin-banner-${cta_type}`}
              id={`thin-banner-cta-${cta_type}`}
              type={cta_type}
              text={cta_name}
              color={cta_background_color}
              fontColor={cta_text_color}
              onClick={handleCtaClick}
            />
          </div>
        ) : null}
      </div>
    </StyledThinBannerContainer>
  );
};
