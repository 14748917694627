import React from 'react';

import { Product } from '../../product/types';
import { isValidProduct } from '../../ecart/utils';
import { CatalogProduct } from '../../catalog/types';
import { ProductCard } from '../../catalog/components/ProductCard';

type Props = {
  hit: Product;
};

export const SearchMostWantedProducts = ({ hit }: Props) => {
  const product = {
    ...hit,
    objectID: `${hit?.productRef}/${hit?.colorRef}`,
    rcFilter: `${hit?.productRef}-${hit?.colorRef}`,
  };

  return product && isValidProduct(product) ? (
    <div className="search-product-card">
      <ProductCard hit={product as unknown as CatalogProduct} leftAlignToolTip />
    </div>
  ) : null;
};
