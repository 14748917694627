import styled from '@emotion/styled';
import css from '@styled-system/css';
import { InputHTMLAttributes } from 'react';
import { colors } from '../systemprovider';

type StyledFieldProps = {
  hasError?: boolean;
  hasBorder?: boolean;
  isDelivery?: boolean;
  height?: string;
  readOnly?: boolean;
};

export const StyledField = styled.div<StyledFieldProps>(
  css({
    '@media(hover: hover)': {
      ':hover': {
        bg: 'LIGHT2',
      },
    },
    ':focus-within': {
      borderBottom: '2px solid',
      borderColor: 'LIGHT',
    },
    position: 'relative',
    alignItems: 'center',
    display: 'inline-flex',
    maxWidth: 'calc(100vw - 16px)',
    minWidth: '96px',
    height: '50px',
    width: '100%',
    bg: 'BACKGROUND',
  }),
  ({ height }) =>
    height &&
    css({
      '@media(hover: hover)': {
        ':hover': {
          bg: colors.BACKGROUND,
        },
      },
      minHeight: height,
    }),
  ({ hasBorder }) =>
    hasBorder &&
    css({
      bg: 'WHITE',
      border: '1px solid',
    }),
  ({ isDelivery }) =>
    isDelivery &&
    css({
      bg: colors.BACKGROUND,
    }),
  ({ hasError }) =>
    hasError &&
    css({
      borderBottom: '2px solid',
      borderColor: 'ERROR',
      ':focus-within': {
        borderBottom: '2px solid',
        borderColor: 'ERROR',
      },
    }),
  ({ readOnly }) =>
    readOnly &&
    css({
      bg: colors.LIGHT,
      pointerEvents: 'none',
    })
);

type StyledLabelProps = {
  color?: string;
};

export const StyledLabel = styled.label<StyledLabelProps>(
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    py: 'm',
    px: 's',
    color: colors.MAIN_GREY,
    fontSize: 's',
    whiteSpace: 'nowrap',
    transition: 'all 0.1s ease-in-out',
  }),
  ({ color }) =>
    color &&
    css({
      color: color,
    })
);

type StyledInputProps = {
  id: string;
  value: string;
  placeholder?: string;
  readOnly?: boolean;
  hasLabel?: boolean;
  isAPrice?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  isEresaModal?: boolean;
  isFooterInput?: boolean;
  isDelivery?: boolean;
};

export const StyledInput = styled.input<StyledInputProps>(
  css({
    ':focus': {
      outline: 'none',
    },
    '::placeholder': {
      fontSize: ['m', 's'],
    },
    fontSize: ['m', 's'],
    border: 'none',
    color: 'BLACK',
    height: '100%',
    width: '100%',
    bg: 'inherit',
    paddingLeft: 's',
  }),
  ({ readOnly }) =>
    readOnly &&
    css({
      color: 'GREY2',
      pointerEvents: 'none',
    }),
  ({ value, placeholder, hasLabel }) =>
    (value || placeholder) && hasLabel
      ? css({
          paddingTop: 'm',
          '~ label': {
            fontSize: 'xs',
            padding: 's',
          },
        })
      : hasLabel &&
        css({
          ':focus, :-webkit-autofill': {
            paddingTop: 'm',
            '~ label': {
              fontSize: 'xs',
              padding: 's',
            },
          },
        }),
  ({ isAPrice }) =>
    isAPrice &&
    css({
      textAlign: 'right',
    }),
  ({ type }) =>
    type === 'date' &&
    css({
      '::-webkit-calendar-picker-indicator': {
        background: 'transparent',
        bottom: 0,
        color: 'transparent',
        cursor: 'pointer',
        height: 'auto',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: 'auto',
      },
      // iOS Safari only
      '::-webkit-date-and-time-value': {
        textAlign: 'left',
      },
      WebkitAppearance: 'none',
      MozAppearance: 'none',
    }),
  ({ isEresaModal }) =>
    isEresaModal &&
    css({
      fontSize: '14px',
    }),
  ({ isFooterInput }) =>
    isFooterInput &&
    css({
      '::placeholder': {
        fontSize: '14px',
        color: colors.MAIN_GREY,
      },
      paddingLeft: 'm',
    }),
  ({ isDelivery }) =>
    isDelivery &&
    css({
      '::placeholder': {
        fontSize: '12px',
        color: colors.MAIN_GREY,
      },
      backgroundColor: colors.BACKGROUND,
    })
);
