import * as React from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/core';

import { Checkbox, colors, breakpoints } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { Forms, FormNewsletter, FeedbackCallback } from '../../form/types';
import { updateNewsletter } from '../actions';
import locale from '../locale';
import { setFeedback } from '../../form/actions';

type Props = {
  form: FormNewsletter;
  updateNewsletter: (user) => void;
  setFeedback: (payload: FeedbackCallback) => void;
  isOnCartPage?: boolean;
};

const emailText = `Oui, je souhaite recevoir par e-mail les actualités et les offres personnalisées
Darjeeling.`;

const smsText = `Oui, je souhaite recevoir par SMS les actualités et les offres personnalisées
Darjeeling. Si oui, renseignez un numéro de téléphone portable.`;

export const Newsletter = ({ form, updateNewsletter, setFeedback, isOnCartPage }: Props) => {
  React.useEffect(() => {
    return () => {
      setFeedback({ form: Forms.newsletter, ok: true, message: '' });
    };
  }, []);

  return (
    <>
      <p
        css={css`
          background: ${colors.WHITE};
          color: ${colors.MAIN_SUCCESS};
          font-size: 1.2rem;
          line-height: 1.6rem;
          height: 16px;
          margin: ${isOnCartPage ? '0 -16px' : 0};
          padding: 4px 0 24px 16px;
          @media (min-width: ${breakpoints.S}px) {
            margin: 0;
            padding: 4px 0 24px 0px;
          }
        `}
      >
        {form.feedback.message}
      </p>

      <div
        css={css`
          margin: ${isOnCartPage ? '0 -16px' : 0};
          padding: ${isOnCartPage ? 16 : 24}px;
          background: ${colors.BACKGROUND};
          display: flex;
          flex-direction: column;
          gap: 16px;
          @media (min-width: ${breakpoints.S}px) {
            margin: 0;
            padding: ${isOnCartPage ? 0 : '24px'};
            background: ${isOnCartPage ? colors.WHITE : colors.BACKGROUND};
          }
        `}
      >
        <Checkbox
          id={`${Forms.newsletter}-optInEmail`}
          onChange={() => updateNewsletter({ optInEmail: !form.values.optInEmail })}
          checked={form.values.optInEmail}
        >
          <p
            css={css`
              font-size: ${isOnCartPage ? 1.2 : 1.4}rem;
              line-height: ${isOnCartPage ? 1.6 : 1.8}rem;
              margin-block-start: 0;
              margin-block-end: 0;
              @media (min-width: ${breakpoints.S}px) {
                font-size: 1.4rem;
                line-height: 1.8rem;
              }
            `}
          >
            {emailText}
          </p>
        </Checkbox>
        <Checkbox
          id={`${Forms.newsletter}-optInSMS`}
          onChange={() => updateNewsletter({ optInSMS: !form.values.optInSMS })}
          checked={form.values.optInSMS}
        >
          <p
            css={css`
              font-size: ${isOnCartPage ? 1.2 : 1.4}rem;
              line-height: ${isOnCartPage ? 1.6 : 1.8}rem;
              margin-block-start: 0;
              margin-block-end: 0;
              @media (min-width: ${breakpoints.S}px) {
                font-size: 1.4rem;
                line-height: 1.8rem;
              }
            `}
          >
            {smsText}
          </p>
        </Checkbox>
      </div>
      <div
        css={css`
          background: ${colors.WHITE};
          margin: ${isOnCartPage ? '0 -16px' : '0 16px 16px 16px'};
          padding: ${isOnCartPage ? 16 : 0}px;
          @media (min-width: ${breakpoints.S}px) {
            margin: 16px 0;
            padding: 0;
          }
        `}
      >
        <p
          css={css`
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: ${colors.GREY};
            margin-block-start: 0;
            margin-block-end: 0;
          `}
        >
          {locale.MSG_THIRD_PARTY_NOTICE}
        </p>
      </div>
    </>
  );
};

export default connect((state: RootState) => ({ form: state.form.newsletter }), {
  updateNewsletter,
  setFeedback,
})(Newsletter);
