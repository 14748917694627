import React from 'react';

import {
  cmsCatalogInit,
  cmsHomepageInit,
  cmsBrandInit,
  cmsGenericInit,
  cmsOffersInit,
  cmsTopTenInit,
  cmsOurBrandInit,
  cmsUspInit,
} from '../cms/actions';
import { productInit } from '../product/actions';
import paths from './paths';
import Cart from '../cart';
import { Catalog } from '../catalog';
import { Home } from '../home/components/Home';
import Product from '../product';
import { Offers, Brand, Generic } from '../cms';
import { StoreDetails, StoreLocator } from '../store-locator';
import { LogoutWithAuth } from '../auth';
import { SizeGuide } from '../sizeGuide/components/SizeGuide';
import { SearchPage } from '../search/components/SearchPage';
import { Affiliate } from '../affiliate/components/Affiliate';
import { StudentPage } from '../studentpage/components/StudentPage';
import { TopTenPage } from '../TopTen/components/TopTenPage';
import { BrandPage } from '../OurBrand/components/BrandPage';
import { OrderTracking } from '../orderTracking/components/OrderTracking';

export default [
  {
    component: <Cart />,
    exact: true,
    path: paths.CART,
  },
  {
    component: <Home />,
    exact: true,
    path: paths.HOME,
    prefetch: cmsHomepageInit,
  },
  {
    component: <Catalog />,
    exact: true,
    path: `${paths.CATALOG}/:lvl0/:lvl1?/:lvl2?/:lvl3?`,
    prefetch: cmsCatalogInit,
  },
  {
    component: <Catalog />,
    exact: true,
    path: `${paths.SELECTION}/:lvl0`,
    prefetch: cmsCatalogInit,
  },
  {
    component: <Product />,
    exact: true,
    path: `${paths.PRODUCT}/:id`,
    prefetch: productInit,
  },
  {
    component: <LogoutWithAuth />,
    exact: true,
    path: paths.LOGOUT,
  },
  {
    component: <SearchPage />,
    exact: true,
    path: paths.SEARCH_RESULTS,
  },
  {
    component: <Home />,
    exact: true,
    path: paths.RESET_PASSWORD,
  },
  {
    component: <StoreDetails />,
    exact: true,
    path: `${paths.STORE_LOCATOR}/:storeId`,
  },
  {
    component: <StoreLocator />,
    exact: true,
    path: paths.STORE_LOCATOR,
  },
  {
    component: <Offers />,
    exact: true,
    path: paths.OFFERS,
    prefetch: cmsOffersInit,
  },
  {
    component: <SizeGuide />,
    exact: true,
    path: paths.SIZE_GUIDE,
  },
  {
    component: <Brand />,
    exact: true,
    path: `${paths.BRAND}/:lvl0/:lvl1?/:lvl2?/:lvl3?`,
    prefetch: cmsBrandInit,
  },
  {
    component: <BrandPage />,
    exact: true,
    path: `${paths.OUR_BRAND}/:id`,
    prefetch: cmsOurBrandInit,
  },
  {
    component: <Affiliate />,
    exact: true,
    path: paths.AFFILIATE,
  },
  {
    component: <StudentPage />,
    exact: true,
    path: paths.STUDENT_PAGE,
  },
  {
    component: <TopTenPage />,
    exact: true,
    path: `${paths.TOP_TEN_PAGE}/:id`,
    prefetch: cmsTopTenInit,
  },
  {
    component: <OrderTracking />,
    exact: true,
    path: paths.ORDER_TRACKING,
    prefetch: cmsUspInit,
  },
  ...paths.GENERIC.map((path) => ({
    component: <Generic />,
    exact: true,
    path,
    prefetch: cmsGenericInit,
  })),
];
