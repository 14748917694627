import * as React from 'react';
import { Link } from 'react-router-dom';

import { CmsImage } from '../../cms/types';
import styled from '@emotion/styled';
import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  image: CmsImage | undefined;
  link: string | undefined;
  ctaType: string | undefined;
  ctaText: string | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
  index: number;
  isScrollable?: boolean;
};

const BUTTON_WIDTH_MAX_DESKTOP = 240;
const BUTTON_HEIGHT = 44;
const IMAGE_MIN_WIDTH = 218;

type StyledImageWrapperAnchorProps = {
  backgroundColor: string;
  textColor: string;
  isScrollable: boolean;
};

const StyledImageWrapperAnchor = styled(Link)<StyledImageWrapperAnchorProps>`
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.isScrollable ? 'row' : 'column')};
  margin: ${(props) => (props.isScrollable ? 0 : '0 16px 16px 16px')};
  width: ${(props) => (props.isScrollable ? '81vw' : 'calc(100% - 32px)')};
  min-width: ${IMAGE_MIN_WIDTH}px;
  @media (min-width: ${breakpoints.S}px) {
    flex-direction: row;
    width: 100%;
  }
  img.categorie-banner-image {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
  }
  div.categorie-home-banner-cta {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0%);
    width: 100%;
    padding: 0 16px;
    @media (min-width: ${breakpoints.S}px) {
      max-width: ${BUTTON_WIDTH_MAX_DESKTOP}px;
    }
  }
  p.categories-home-banner-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: 0;
    margin-block-end: 0;
    text-decoration: underline;
    text-underline-position: under;
    height: ${BUTTON_HEIGHT}px;
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 700;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        opacity: ${opacities.HOVERED};
        transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
      }
    }
  }
  .categorie-fake-btn {
    color: ${({ textColor }) => textColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    font-weight: 500;
    height: ${BUTTON_HEIGHT}px;
    font-size: 1.6rem;
    line-height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    transition: all ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (min-width: ${breakpoints.S}px) {
      max-width: ${BUTTON_WIDTH_MAX_DESKTOP}px;
    }

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
`;

export const CategoryItem = ({
  image,
  link,
  ctaType,
  ctaText,
  backgroundColor,
  textColor,
  index,
  isScrollable = false,
}: Props) => {
  return (
    <StyledImageWrapperAnchor
      id={`categorie-home-banner-item-${index}`}
      backgroundColor={backgroundColor ?? colors.WHITE}
      textColor={textColor ?? colors.BLACK}
      to={getFormatedLinkForReactRouterLink(link)}
      isScrollable={isScrollable}
      aria-label={`Lien vers la page ${link}`}
    >
      <img
        className="categorie-banner-image"
        src={image?.url ?? ''}
        alt={image?.alt ?? `categorie-home-banner--image-${index}`}
      />
      {ctaText && (
        <div className="categorie-home-banner-cta">
          {ctaType === 'Link' ? (
            <p className="categories-home-banner-text">{ctaText}</p>
          ) : (
            <div className="categorie-fake-btn" id={`categories-banner-button-${index}`}>
              {ctaText}
            </div>
          )}
        </div>
      )}
    </StyledImageWrapperAnchor>
  );
};
