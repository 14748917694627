import React from 'react';
import { css } from '@emotion/core';

import { colors, Icon, opacities } from '../../../design-system';
import { TXT_BACK } from '../locale';
import { durations } from '../constants';

type Props = {
  id: string;
  onClick: () => void;
  preset?: 'body' | 'caption' | 'darkTheme';
  backText?: string;
  isOrderDetail?: boolean;
};

export const BackButton = ({
  id,
  preset = 'body',
  isOrderDetail,
  onClick,
  backText = TXT_BACK,
}: Props) => (
  <button
    id={id}
    css={css`
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: ${preset === 'darkTheme' ? 'center' : 'initial'};
      margin-bottom: ${preset === 'caption' ? 'na' : 's'};
      margin-top: ${isOrderDetail ? '-24px' : preset === 'caption' ? 0 : '8px'};
      background-color: ${preset === 'darkTheme' ? colors.BLACK : 'unset'};
      width: ${preset === 'darkTheme' ? '73px' : 'auto'};
      height: ${preset === 'darkTheme' ? '24px' : 'auto'};
      border-radius: ${preset === 'darkTheme' ? '4px' : 'na'};
      border: none;
      transition: opacity ${durations.ENTER}ms ease-in-out;
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          opacity: ${opacities.HOVERED};
        }
      }
    `}
    onClick={onClick}
  >
    <div
      css={css`
        margin: 0 4px 0 ${preset === 'darkTheme' ? '-6px' : 0};
      `}
    >
      <Icon
        name="chevronLeft"
        size={preset === 'caption' ? 10 : 12.5}
        color={preset === 'darkTheme' ? colors.WHITE : colors.BLACK}
      />
    </div>
    <p
      css={css`
        margin: 0;
        font-size: 1.4rem;
        font-weight: 500;
        color: ${preset === 'darkTheme' ? colors.WHITE : colors.BLACK};
      `}
    >
      {backText}
    </p>
  </button>
);
