import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import { Box } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';
import { ButtonLink } from '../../../design-system/button';

type Props = {
  link: string;
  column: string;
  ctaText: string;
  ctaType?: string;
  ctaTypeMobile?: string;
};

const BrandCta = ({ ctaText, ctaType, ctaTypeMobile, link, column }: Props) => {
  const { isDesktop } = useMediaQueries();

  const mt = column === 'full' || column === 'half' ? 'xxl' : isDesktop ? 'xxl' : 'm';
  const px = isDesktop || column !== 'quarter' ? 'na' : 's';
  const width = isDesktop || column !== 'quarter' ? '240px' : 'auto';
  const maxWidth = isDesktop || column !== 'quarter' ? '240px' : '160px';

  const type = !isDesktop && ctaTypeMobile ? ctaTypeMobile : ctaType;

  return (
    <Box {...{ mt, px, width, maxWidth }} mx="auto" textAlign="center">
      {type !== 'link' ? (
        <ButtonLink
          data-testid={`link-cms-brand-${ctaText}`}
          type="button"
          id="link-cms-brand"
          to={getFormatedLinkForReactRouterLink(link)}
          preset={type === 'light' ? 'primaryLight' : type === 'subtle' ? 'subtle' : 'primary'}
          aria-label={`Lien pour accéder à la page ${link}`}
        >
          {ctaText}
        </ButtonLink>
      ) : (
        <Link
          className="anchor-animated"
          data-testid={`link-cms-brand-${ctaText}`}
          id="link-cms-brand"
          to={getFormatedLinkForReactRouterLink(link)}
          aria-label={`Lien pour accéder à la page ${link}`}
          css={css`
            text-decoration: none;
            font-size: 1.6rem;
          `}
        >
          {ctaText}
        </Link>
      )}
    </Box>
  );
};

export default BrandCta;
