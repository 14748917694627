import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Picture, ImageContainer } from '../image';
import Box from '../box';
import Icon from '../icon';
import { breakpoints, colors } from '../systemprovider';
import ZoomImage from './components/ZoomImage';
import { BannerVideo } from '../../modules/banners/components/BannerVideo';
import { useSwipeable } from 'react-swipeable';
import { ModelPDP } from '../../modules/product/types';
import { ModelSizes } from '../../modules/product/components/ModelSizes';

export type Image = {
  jpg: string;
  webp: string;
  alt: string;
};

type Props = {
  images: Image[];
  width?: string;
  children?: ReactNode;
  video?: {
    url?: string;
    thumbnail?: string;
  };
  imagePositions?: number[];
  model?: ModelPDP[] | null;
};

export const Carousel = ({
  children,
  images = [],
  video,
  width = '100vw',
  model = null,
  imagePositions = [],
}: Props) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => onSwipeLeft(),
    onSwipedRight: () => onSwipeRight(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const items = [...images, video?.url ? video : undefined].filter(Boolean);

  const [showZoom, setShowZoom] = useState(false);

  const [dotState, setDotState] = useState({
    activeIndex: 0,
    markedIndex: 0,
    showSmallRightDot: items.length > 6,
    showSmallLeftDot: false,
  });

  const { activeIndex, markedIndex, showSmallLeftDot, showSmallRightDot } = dotState;
  const FIRST_INDEX = 0;
  const LAST_INDEX = items.length - 1;
  const LAST_DOT_INDEX = 5;

  const videoIndex = items.length - 1;
  const showVideo = video?.url && activeIndex === videoIndex;

  const goToIndex = (index) => {
    if (index !== activeIndex) {
      setDotState({
        activeIndex: index,
        markedIndex:
          index === LAST_INDEX
            ? index > LAST_DOT_INDEX
              ? LAST_DOT_INDEX
              : LAST_INDEX
            : index > LAST_DOT_INDEX - 1
              ? LAST_DOT_INDEX - 1
              : index > FIRST_INDEX
                ? index
                : FIRST_INDEX,
        showSmallRightDot: items.length > 6,
        showSmallLeftDot: items.length > 6 && index >= 5,
      });
    }
  };

  const onSwipeLeft = () => {
    goToIndex(activeIndex < items.length - 1 ? activeIndex + 1 : 0);
  };

  const onSwipeRight = () => {
    goToIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
  };

  const onClick = () => {
    setShowZoom(true);
  };

  const onClose = () => {
    setShowZoom(false);
  };

  if (showZoom) {
    return <ZoomImage {...{ images, activeIndex, onClose, goToIndex }} />;
  }

  return (
    <Box width={width} overflow="hidden" position="relative" {...handlers}>
      {!showVideo && <>{children}</>}
      <CarouselItems activeIndex={activeIndex} length={items.length} width={width}>
        {images.map((item, index) => (
          <Box key={index} width={width} onClick={onClick}>
            <ImageContainer>
              <Picture>
                <source srcSet={item.webp} type="image/webp" />
                <source srcSet={item.jpg} type="image/jpeg" />
                <img src={item.jpg} alt={item.alt} />
              </Picture>
              {model && (imagePositions?.length ?? 0) > 0 && (
                <ModelSizes imagePositions={imagePositions} model={model} index={index} />
              )}
            </ImageContainer>
          </Box>
        ))}
        {video?.url && (
          <Box position="relative">
            <Box position="absolute" width={width} height="100%" zIndex="absolute" />
            <Box width={width} height="100%">
              <BannerVideo video={video.url} />
            </Box>
          </Box>
        )}
      </CarouselItems>
      <Box position="relative">
        <Box
          position="absolute"
          bottom="20px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="absolute"
        >
          {[...Array(items.length > 6 ? 6 : items.length).keys()].map((index) => (
            <Box key={index} mx="xs">
              <Icon
                id={`icon-carousel-dot-${index}`}
                name="dot"
                color={markedIndex === index ? colors.BLACK : colors.WHITE}
                size={
                  markedIndex === index ||
                  !((showSmallLeftDot && index === 0) || (showSmallRightDot && index === 5))
                    ? 6
                    : 4
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

type CarouselItemsProps = {
  activeIndex: number;
  length: number;
  width: string;
};

const CarouselItems = styled.div<CarouselItemsProps>(
  ({ activeIndex, length, width }) => css`
    display: flex;
    width: calc(${length} * ${width});
    transition: transform 0.3s ease-in-out;
    transform: translate3d(calc(-1 * ${activeIndex} * ${width}), 0px, 0px);

    @media (min-width: ${breakpoints.S}px) {
      transform: translate3d(calc(-1 * ${activeIndex} * (${width} / ${length})), 0px, 0px);
    }
  `
);
