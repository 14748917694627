import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { theme, colors, opacities, durations } from '../../../design-system/systemprovider';
import { Icon, SpinnerAndIconLinear } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { CmsText } from '../../cms/types';
import { formatPrice } from '../../common/utils';
import { PrismicRichText } from '@prismicio/react';
import { heights } from '../../common/constants';

const StyledProductUsp = styled.div`
  position: relative;
  .product-usp-button-block {
    background-color: unset;
    border: 1px solid ${colors.LIGHT};
    border-radius: ${theme.sizes.xs};
    padding: ${theme.space.m};
    width: 100%;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
    color: ${colors.BLACK};

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .product-usp-description {
    text-align: left;
    display: flex;
    align-items: center;
    gap: ${theme.space.s};
    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  .product-usp-progress-bar-container {
    margin-top: ${theme.space.s};
    display: flex;
    align-items: center;
    gap: ${theme.space.s};
  }

  .product-usp-progress-bar-amount {
    font-size: 1.2rem;
  }
`;

const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${theme.sizes.xs};
  background: ${colors.LIGHT};
  border-radius: ${theme.sizes.xxs};
  overflow: hidden;
`;

const Progress = styled.div<{ width: number }>`
  height: 100%;
  width: ${({ width }) => width}%;
  background: ${colors.MAIN_SUCCESS};
  transition: width ${durations.FOCUS_DELAY}ms ease-in-out;
`;

const StyledLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.space.m};
  border-radius: ${theme.sizes.xs};
  border: 1px solid ${colors.LIGHT};
`;

export const ProductUsp = () => {
  const { isMobile, isTablet } = useMediaQueries();

  const { cart } = useSelector((state: RootState) => state.cart);
  const { total, shippingAmount } = cart ?? {};
  const cmsContent = useSelector((state: RootState) => state.cms.cart);

  const requiredAmount = cmsContent?.shipping_promo_amount ?? 0;
  const messageFreeDelivery = cmsContent?.shipping_promo_message_free_delivery ?? '';
  const safeTotal = Number(total) || 0;
  const safeShippingAmount = Number(shippingAmount) || 0;
  const safeRequiredAmount = Number(requiredAmount) || 0;

  const totalWithoutShipping = parseFloat((safeTotal - safeShippingAmount).toFixed(2));
  const remainingAmount = parseFloat((safeRequiredAmount - totalWithoutShipping).toFixed(2));

  const achievedPercentage = safeRequiredAmount
    ? parseFloat(((totalWithoutShipping * 100) / safeRequiredAmount).toFixed(2))
    : 0;

  const [messageRemaining, setMessageRemaining] = useState<CmsText>([]);

  const ProgressBar = ({ percentage }: { percentage: number }) => {
    return (
      <ProgressBarContainer
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-labelledby="progress-label"
      >
        <Progress width={Math.min(percentage, 100)} />
      </ProgressBarContainer>
    );
  };

  useEffect(() => {
    const cmsContentCopy = { ...cmsContent };
    const newMessageRemaining = cmsContentCopy?.shipping_promo_message_remaining.map((element) => {
      const newText =
        formatPrice(remainingAmount).length === 6
          ? element.text.replace('$remain', ` ${formatPrice(remainingAmount)}`) // add extra space to prevent gap on rich text spans index
          : element.text.replace('$remain', formatPrice(remainingAmount));
      return { ...element, text: newText };
    });
    setMessageRemaining(newMessageRemaining);
  }, [remainingAmount]);

  const handleClick = () => {
    const target = document.getElementById(
      'accordion-pdp__container-child-2'
    ) as HTMLDetailsElement | null;

    if (!target) {
      return;
    }

    target.open = !target.open;

    if (!target.open) {
      return;
    }

    if (isMobile) {
      target.scrollIntoView({ block: 'center' });
      return;
    }

    const productImageContainer = document.querySelector<HTMLElement>('.product-image-container');
    const productInfoContainer = document.querySelector<HTMLElement>('.product-info-container');

    if (!productImageContainer || !productInfoContainer) {
      return;
    }

    const productImageHeight = productImageContainer.offsetHeight || 0;
    const productInfoHeight = productInfoContainer.offsetHeight || 0;
    const additionalScrollValue = Math.max(
      isTablet ? productInfoHeight - productImageHeight : productImageHeight - productInfoHeight,
      0
    );

    window.scrollTo({
      top:
        target.offsetTop +
        additionalScrollValue -
        (isTablet ? heights.HEADER_TABLET : heights.HEADER),
    });
  };

  return !cmsContent || !cart?.cartId ? (
    <StyledLoading>
      <SpinnerAndIconLinear
        color={colors.BLACK}
        size={50}
        stroke={40}
        iconName="Ddarjeeling"
        id="pdp-spinner-linear"
      />
    </StyledLoading>
  ) : (
    <StyledProductUsp id="product-usp-block">
      <button
        type="button"
        data-testid={`product-usp-btn-toggle-accordion-delivery-and-refund-details`}
        aria-label={`Bonton pour cliquer sur le panneau livraison et retour`}
        className={`product-usp-button-block`}
        onClick={handleClick}
      >
        <div className={`product-usp-description`}>
          <Icon name="delivery" size={24} />
          <PrismicRichText field={remainingAmount > 0 ? messageRemaining : messageFreeDelivery} />
        </div>
        {achievedPercentage > 0 && (
          <div className="product-usp-progress-bar-container">
            <span id="progress-label" className="sr-only">
              {`Vous avez atteint ${achievedPercentage}% de l'objectif pour obtenir la livraison gratuite.`}
            </span>
            <ProgressBar percentage={achievedPercentage} />
            <span className="product-usp-progress-bar-amount">{requiredAmount}€</span>
          </div>
        )}
      </button>
    </StyledProductUsp>
  );
};
