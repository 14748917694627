import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import {
  Image,
  ImageContainer,
  Text,
  Icon,
  opacities,
  breakpoints,
  colors,
} from '../../../design-system';
import { WishlistItem as WishlistItemType } from '../types';
import { getProductImageLink, getProductLink, productImageWidths } from '../../product/utils';
import { formatPrice } from '../../common/utils';
import { ColorText } from '../../common/components/Custom';
import { RootState } from '../../../store/rootReducer';
import { TXT_CART_ITEM_UNDO } from '../../cart/locale';
import { getPromotionColors } from '../../catalog/utils';
import { transitions, durations } from '../../common/constants';
import { AnimationContainer, CartItemContainer } from '../../cart/components/CartItem';
import { removeFromWishlist, addToWishlist } from '../actions';
import locale from '../locale';
import { moveToCartFromWishlist } from '../../cart/actions';
import { LBL_SIZE, LBL_COLOR, NONE_LEFT } from '../../product/locale';
import { openModal } from '../../common/actions';
import WishlistSizeModal from './WishlistSizeModal';
import { HighlightedName } from '../../catalog/components/HighlightedName';

type Props = {
  id: string;
  item: WishlistItemType;
  isMini: boolean;
};

export const WishlistItem = ({ id, item, isMini }: Props) => {
  const dispatch = useDispatch();

  const [showUndo, setShowUndo] = useState(false);
  const [hasWaitedForUndo, setHasWaitedForUndo] = useState(false);
  const [shouldDisappear, setShouldDisappear] = useState(false);

  const [showMove, setShowMove] = useState(false);

  const {
    productRef,
    colorRef,
    sku,
    productName,
    collectionName,
    colorLabel,
    detailedColorLabel,
    size,
    bandSize,
    cupSize,
    promotionLabel,
    promotionPercentage,
    stockQuantity,
    sizeVariants,
    storePrice,
    originalPrice,
  } = item;

  const objectID = `${productRef}-${colorRef}`;
  const isAvailable = stockQuantity > 0;

  useEffect(() => {
    if (hasWaitedForUndo) {
      if (showUndo) {
        setShouldDisappear(true);
      }
      setHasWaitedForUndo(false);
    }
  }, [hasWaitedForUndo]);

  useEffect(() => {
    if (shouldDisappear) {
      dispatch(removeFromWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
    }
  }, [shouldDisappear]);

  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
  });

  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });

  const hasPromo = storePrice !== originalPrice;
  const total = formatPrice(storePrice);
  const baseTotal = formatPrice(originalPrice);

  const productLink = getProductLink({ productRef, colorLabel, colorRef, productName });

  const handleDeleteItem = () => {
    if (isAvailable) {
      setShowUndo(true);
      setTimeout(() => {
        setHasWaitedForUndo(true);
      }, durations.UNDO_DELAY);
    } else {
      setShouldDisappear(true);
    }
  };

  const handleUndo = () => {
    setShowUndo(false);
  };

  const handleOpenSizeModal = () => {
    dispatch(
      openModal({
        content: (
          <WishlistSizeModal
            id={id}
            sku={sku}
            sizeVariants={sizeVariants}
            handleSizeChange={handleSizeChange}
            productRef={productRef}
            colorRef={colorRef}
          />
        ),
        preset: (sizeVariants ?? []).length > 30 ? 'l' : 's',
      })
    );
  };

  const handleMoveToCart = (sku: string) => {
    setShowMove(true);
    dispatch(
      moveToCartFromWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction
    );
  };

  const handleMoveClick = () => {
    if (sku) {
      handleMoveToCart(sku);
    } else {
      handleOpenSizeModal();
    }
  };

  const handleSizeChange = (sku: string) => {
    dispatch(addToWishlist({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
  };

  return (
    <CartItemContainer showUndo={showUndo} showMove={showMove} id={`box-wishlist-item-${id}`}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
            width: calc(100% - 32px);
            margin: ${isMini ? '16px' : '24px'} 16px 8px 16px;
            transition: ${transitions.GENERIC};
            opacity: ${showUndo || showMove ? 0 : 1};
            gap: 16px;

            @media (min-width: ${breakpoints.S}px) {
              margin: ${isMini ? '16px' : '24px'} 0 ${isMini ? '8px' : '24px'} 0;
              width: ${isMini ? 'calc(100% - 32px)' : '100%'};
            }
          `}
        >
          <div
            css={css`
              opacity: ${isAvailable ? 1 : opacities.DISABLED};
              min-width: ${`${productImageWidths.CART}px`};
              justify-self: center;
              align-self: center;
              border: none;
              backgound: unset;
              padding: 0;
              border-radius: 8px;
              transition: opacity ${durations.ENTER}ms ease-in-out;
              pointer-events: ${isAvailable ? 'auto' : 'none'};

              @media (hover: hover) {
                &:hover {
                  cursor: pointer;
                  opacity: ${opacities.HOVERED};
                }
              }
            `}
          >
            <Link id={`link-wishlist-item-image-${productRef}-${colorRef}`} to={productLink}>
              <ImageContainer>
                <Image isAbsolute src={thumbnail} alt="Product Image" />
              </ImageContainer>
            </Link>
          </div>

          <div
            css={css`
              display: flex;
              gap: 8px;
              width: 100%;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 8px;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  justify-content: center;
                  gap: 8px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    gap: 8px;
                  `}
                >
                  <HighlightedName
                    objectID={objectID}
                    productName={productName}
                    collectionName={collectionName}
                    isCartPage
                  />

                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 6px;
                      align-items: flex-end;
                    `}
                  >
                    <span
                      id={`text-wishlist-item-row-total-${id}`}
                      css={css`
                        font-size: 1.6rem;
                        font-weight: 700;
                      `}
                    >
                      {total}
                    </span>
                    {hasPromo && (
                      <span
                        id={`text-wishlist-item-base-row-total-${id}`}
                        css={css`
                          font-size: 1.6rem;
                          color: ${colors.GREY2};
                          text-decoration: line-through;
                        `}
                      >
                        {baseTotal}
                      </span>
                    )}
                  </div>
                </div>
                {(promotionPercentage || promotionLabel) && (
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 8px;
                    `}
                  >
                    {(promotionPercentage || promotionLabel) && (
                      <ColorText
                        borderRadius={4}
                        display="inline-flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        py="xxs"
                        px="s"
                        cmsColor={textColor}
                        bg={backgroundColor}
                        fontWeight="bold"
                        preset="caption"
                        alignSelf="start"
                      >
                        {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
                      </ColorText>
                    )}
                  </div>
                )}
                <div
                  css={css`
                    display: flex;
                    flex-direction: ${isMini ? 'column' : 'row'};
                    flex-wrap: wrap;
                    gap: 16px;
                  `}
                >
                  <p
                    css={css`
                      margin: 0;
                      font-size: 1.4rem;
                      color: ${colors.MAIN_GREY};
                    `}
                  >
                    {`${LBL_COLOR} `}
                    <span
                      css={css`
                        font-weight: 700;
                      `}
                    >
                      {detailedColorLabel}
                    </span>
                  </p>
                  <div
                    css={css`
                      font-size: 1.4rem;
                      color: ${colors.MAIN_GREY};
                    `}
                  >
                    {`${LBL_SIZE} `}
                    {sku ? (
                      <>
                        <span
                          css={css`
                            font-weight: 700;
                            color: ${isAvailable ? colors.BLACK : colors.ERROR};
                          `}
                        >
                          {size || `${bandSize} ${cupSize}`}
                        </span>
                        <button
                          type="button"
                          className="anchor-animated"
                          data-testid={`btn-wishlist-item-change-size-${id}`}
                          id={`btn-wishlist-item-change-size-${id}`}
                          onClick={handleOpenSizeModal}
                          css={css`
                            font-size: 1.2rem;
                            margin-left: 8px;
                            text-transform: uppercase;
                            font-weight: 500;
                          `}
                        >
                          {locale.CHANGE_SIZE}
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="anchor-animated"
                        data-testid={`btn-wishlist-item-select-size-${id}`}
                        id={`btn-wishlist-item-select-size-${id}`}
                        onClick={handleOpenSizeModal}
                        css={css`
                          font-size: 1.2rem;
                          margin-left: 8px;
                          text-transform: uppercase;
                          font-weight: 500;
                        `}
                      >
                        {locale.SELECT_SIZE}
                      </button>
                    )}
                  </div>
                </div>
                {!isAvailable && (
                  <p
                    css={css`
                      margin-top: 4px;
                      font-size: 1.4rem;
                      color: ${colors.ERROR};
                    `}
                    id={`text-wishlist-item-size-error-message-${id}`}
                  >
                    {NONE_LEFT}
                  </p>
                )}
              </div>
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  gap: 16px;
                `}
              >
                {!isMini && (
                  <div
                    css={css`
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                    `}
                  >
                    <button
                      data-testid={`btn-wishlist-move-to-cart-${id}`}
                      id={`btn-wishlist-move-to-cart-${id}`}
                      type="button"
                      onClick={handleMoveClick}
                      css={css`
                        font-size: 1.4rem;
                        font-weight: 500;
                        text-transform: uppercase;
                        border: none;
                        border-radius: 8px;
                        background-color: ${colors.BLACK};
                        height: 44px;
                        width: fit-content;
                        color: ${colors.WHITE};
                        pointer-events: ${isAvailable ? 'auto' : 'none'};
                        opacity: ${isAvailable ? 1 : opacities.DISABLED};
                        transition: opacity ${durations.ENTER}ms ease-in-out;

                        @media (hover: hover) {
                          &:hover {
                            cursor: pointer;
                            opacity: ${opacities.HOVERED};
                          }
                        }

                        @media (min-width: ${breakpoints.S}px) {
                          width: 220px;
                        }
                      `}
                    >
                      {locale.MOVE_TO_CART}
                    </button>
                    <button
                      data-testid={`btn-wishlist-item-remove-${id}`}
                      id={`btn-wishlist-item-remove-${id}`}
                      type="button"
                      onClick={handleDeleteItem}
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: none;
                        padding: 0;
                        border: ${colors.BLACK} 1px solid;
                        border-radius: 8px;
                        height: 44px;
                        width: 44px;
                        min-width: 44px;
                        transition: opacity ${durations.ENTER}ms ease-in-out;

                        @media (hover: hover) {
                          &:hover {
                            cursor: pointer;
                            opacity: ${opacities.HOVERED};
                          }
                        }
                      `}
                    >
                      <Icon name="trash" size={24} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isMini && (
          <div
            css={css`
              display: flex;
              gap: 8px;
              width: calc(100% - 32px);
              justify-content: space-between;
              padding-bottom: 16px;
              border-bottom: 1px solid ${colors.LIGHT};
              transition: ${transitions.GENERIC};
              opacity: ${showUndo || showMove ? 0 : 1};
            `}
          >
            <button
              data-testid={`btn-mini-wishlist-move-to-cart-${id}`}
              id={`btn-mini-wishlist-move-to-cart-${id}`}
              type="button"
              onClick={handleMoveClick}
              css={css`
                font-size: 1.4rem;
                font-weight: 500;
                text-transform: uppercase;
                padding: 0 16px;
                border: none;
                border-radius: 8px;
                background-color: ${colors.BLACK};
                height: 44px;
                width: 100%;
                color: ${colors.WHITE};
                pointer-events: ${isAvailable ? 'auto' : 'none'};
                opacity: ${isAvailable ? 1 : opacities.DISABLED};
                transition: opacity ${durations.ENTER}ms ease-in-out;

                @media (hover: hover) {
                  &:hover {
                    cursor: pointer;
                    opacity: ${opacities.HOVERED};
                  }
                }
              `}
            >
              {locale.MOVE_TO_CART}
            </button>
            <button
              data-testid={`btn-mini-wishlist-item-remove-${id}`}
              id={`btn-mini-wishlist-item-remove-${id}`}
              type="button"
              onClick={handleDeleteItem}
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                padding: 0;
                border: ${colors.BLACK} 1px solid;
                border-radius: 8px;
                height: 44px;
                width: 44px;
                min-width: 44px;
                transition: opacity ${durations.ENTER}ms ease-in-out;

                @media (hover: hover) {
                  &:hover {
                    cursor: pointer;
                    opacity: ${opacities.HOVERED};
                  }
                }
              `}
            >
              <Icon name="trash" size={24} />
            </button>
          </div>
        )}
        <AnimationContainer
          show={showUndo || showMove}
          shouldDisappear={shouldDisappear}
          id={`wishlist-item-animation-container-${id}`}
        >
          {showUndo ? (
            <>
              <Text id={`text-wishlist-removed-${id}`} mb="s">
                {locale.ITEM_REMOVED}
              </Text>
              <button
                className="anchor-animated"
                data-testid={`btn-wishlist-item-undo-${id}`}
                id={`btn-wishlist-item-undo-${id}`}
                aria-label={`Bouton pour restaurer le produit ${productName} dans la liste des souhaits`}
                type="button"
                onClick={handleUndo}
                css={css`
                  font-size: 1.4rem;
                `}
              >
                {TXT_CART_ITEM_UNDO}
              </button>
            </>
          ) : showMove ? (
            <Text
              id={`text-wishlist-moved-${id}`}
              color="WHITE"
              fontWeight="bold"
              preset="subheading"
            >
              {locale.MOVED_TO_CART}
            </Text>
          ) : null}
        </AnimationContainer>
      </div>
    </CartItemContainer>
  );
};
