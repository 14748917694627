import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { numberOfItemsSelector } from '../../cart/selectors';
import { Icon } from '../../../design-system';
import { MenuMobile } from '../../menu';
import paths from '../../routing/paths';
import accountPaths from '../../account/paths';
import { RootState } from '../../../store/rootReducer';
import { setRedirectTo } from '../../auth/actions';
import { MiniWishlist } from '../../wishlist';
import { breakpoints, colors, theme } from '../../../design-system/systemprovider/constants';
import { Logo } from '../../common/components/Logo';
import { persistData } from '../../common/utils';
import { durations } from '../../common/constants';
import { openSidebar } from '../../common/actions';
import { CustomIconToSvg } from './CustomIconToSvg';

const StyledHeaderRightSide = styled.div`
  display: flex;
  grid-gap: ${theme.space.l};
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${breakpoints.M}px) {
    justify-content: right;
  }
  .header-mobile__left-side {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }
  .header-mobile__menu-button {
    border: none;
    padding: 0;
    background: none;
  }
  .header-mobile__search-button {
    border: none;
    padding: 0;
    background: none;
    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }
  .header-mobile__logo-container {
    display: block;
    @media (min-width: ${breakpoints.M}px) {
      display: none;
    }
  }
  .header-content__right-side {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (min-width: ${breakpoints.M}px) {
      gap: 12px;
    }
  }
  .header-account__icon-container {
    padding: 0;
    height: 24px;
    @media (min-width: ${breakpoints.M}px) {
      padding: 2px;
    }
  }
  .header-account__button {
    background: unset;
    padding: 0;
    border: unset;
    cursor: pointer;
    height: 24px;
    @media (min-width: ${breakpoints.M}px) {
      padding: 2px;
    }
  }
  .header-cart__icon-container {
    position: relative;
    padding: 0px;
    height: 24px;
    @media (min-width: ${breakpoints.M}px) {
      padding: 2px;
    }
  }
  .header-cart__number-of-items-container {
    position: absolute;
    top: 10px;
    right: -6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${colors.ERROR};
    z-index: 10;
  }
  .header-cart__number-text {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.xs};
    color: ${colors.WHITE};
    text-align: center;
  }
  .mini-wishlist__container {
    display: none;
    @media (min-width: ${breakpoints.M}px) {
      display: inherit;
    }
  }
`;

export const HeaderRightSide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const numberOfCartItems = useSelector((state: RootState) => numberOfItemsSelector(state));
  const { isOpen } = useSelector((state: RootState) => state.sidebar);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const { pathname } = useLocation();
  const isSearchPage = pathname.startsWith(paths.SEARCH_RESULTS);

  const handleMenuMobileClick = () => {
    persistData('fromLeft', true);
    dispatch(
      openSidebar({
        content: <MenuMobile />,
      })
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isOpen) {
      timer = setTimeout(() => persistData('fromLeft', false), durations.EXIT);
    }
    return () => {
      if (!isOpen) {
        clearTimeout(timer);
      }
    };
  }, [isOpen]);

  const handleAccountClick = () => {
    const destination = paths.ACCOUNT + accountPaths.INFO;
    dispatch(setRedirectTo(destination));
  };
  const handleSearchClick = () => {
    if (isSearchPage) {
      window.scrollTo(0, 0);
    } else {
      navigate(paths.SEARCH_RESULTS);
    }
  };

  return (
    <StyledHeaderRightSide>
      <div className="header-mobile__left-side">
        <button
          aria-label="Bouton pour ouvrir le menu mobile"
          className="header-mobile__menu-button"
          data-testid="header-mobile-menu-button"
          type="button"
          onClick={handleMenuMobileClick}
        >
          <Icon name="menu" size={24} color="#FFF" className="burger-menu__icon" />
        </button>
        <button
          aria-label="Bouton pour rechercher"
          className="header-mobile__search-button"
          data-testid="header-mobile-search-button"
          type="button"
          onClick={handleSearchClick}
        >
          <Icon name="search" size={24} className="search__icon" />
        </button>
      </div>
      <div className="header-mobile__logo-container">
        <Logo />
      </div>
      <div className="header-content__right-side">
        {isLoggedIn ? (
          <Link
            id="header-account__link"
            className="header-account__link"
            data-testid="header-account-link"
            aria-label="Lien vers la page mon compte"
            to={paths.ACCOUNT + accountPaths.INFO}
          >
            <div className="header-account__icon-container">
              <CustomIconToSvg name="account" />
            </div>
          </Link>
        ) : (
          <button
            aria-label="Bouton pour ouvrir la page mon compte"
            className="header-account__button"
            data-testid="header-account-button"
            type="button"
            onClick={handleAccountClick}
          >
            <CustomIconToSvg name="account" />
            <span className="sr-only">Mon compte</span>
          </button>
        )}
        <Link
          id="header-cart__link"
          className="header-cart__link"
          data-testid="header-cart-link"
          aria-label="Lien vers la page panier"
          to={paths.CART}
        >
          <div className="header-cart__icon-container">
            {numberOfCartItems > 0 && (
              <div className="header-cart__number-of-items-container">
                <p className="header-cart__number-text">{numberOfCartItems}</p>
              </div>
            )}
            <CustomIconToSvg name="miniCart" />
          </div>
        </Link>
        <div className="mini-wishlist__container">
          <MiniWishlist />
        </div>
      </div>
    </StyledHeaderRightSide>
  );
};
