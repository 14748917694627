import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { RootState } from 'src/shared/store/rootReducer';
import { Dropdown, Icon, colors } from '../../../design-system';
import { countryOptions } from '../../account/utils';
import { LBL_COUNTRY_FRANCE } from '../locale';
import { Forms, FormAddress } from '../../form/types';
import { durations } from '../../common/constants';
import { opacities } from '../../../design-system';

type Props = {
  addressOptions: any;
  form: FormAddress;
  type: Forms;
  onChange: (value: string) => void;
  setEditAddressType: (value: Forms | null) => void;
  showBilling: boolean;
  onShowBillingClick: () => void;
};

export const AddressCard = ({
  addressOptions,
  form,
  type,
  onChange,
  setEditAddressType,
  showBilling,
  onShowBillingClick,
}: Props) => {
  const addresses = useSelector((state: RootState) => state.account.user?.addresses) ?? [];
  return (
    <>
      <div
        id={`cart-address-card-info-on-${type}-form`}
        css={css`
          margin-top: ${type === Forms.shipping ? '16px' : 0};
        `}
      >
        <Dropdown
          id={`${type}-form-dropdown-address`}
          selected={form.values.id}
          options={addressOptions}
          onChange={onChange}
          background="WHITE"
          border={`solid 1px ${colors.LIGHT}`}
          isPositionAbsolute={false}
        />
      </div>
      <div
        id={`cart-${type}-user-address-selected-info`}
        css={css`
          background-color: ${colors.BACKGROUND};
          font-size: 1.4rem;
          line-height: 110%;
          color: ${colors.MAIN_GREY};
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 16px;
          margin-top: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              color: ${colors.BLACK};
              text-transform: capitalize;
            `}
          >
            {`${form.values.firstName} ${form.values.lastName}`}
          </div>
          <button
            type="button"
            data-testid={`button-edit-home-${type}-address`}
            aria-label={`Bouton pour modifier l'adresse`}
            onClick={() => {
              if (type === Forms.shipping && showBilling && addresses.length === 1) {
                onShowBillingClick();
              }
              setEditAddressType(type);
            }}
            css={css`
              @media (hover: hover) {
                &:hover {
                  opacity: ${opacities.HOVERED};
                  transition: opacity ${durations.ENTER}ms ease-in-out;
                }
              }
              background-color: initial;
              border: none;
              padding: 0 0;
              cursor: pointer;
            `}
          >
            <Icon name="edit" size={16} />
          </button>
        </div>
        <div
          css={css`
            margin-top: 4px;
          `}
        >
          {form.values.street}
        </div>
        {form.values.streetAdditional && (
          <div
            css={css`
              margin-top: 4px;
            `}
          >
            {form.values.streetAdditional}
          </div>
        )}
        <div
          css={css`
            text-transform: capitalize;
            margin-top: 4px;
          `}
        >
          {`${form.values.postal} ${form.values.city}`}
        </div>
        <div
          css={css`
            margin-top: 4px;
          `}
        >
          {type === Forms.billing
            ? (countryOptions.filter((country) => country.value === form.values.country)?.[0]
                ?.label ?? '')
            : LBL_COUNTRY_FRANCE}
        </div>
      </div>
    </>
  );
};
