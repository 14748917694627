import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import Hammer from 'react-hammerjs';

import Box from '../../box';
import useScrollLock from '../../../modules/common/hooks/useScrollLock';
import { Image } from '../Carousel';
import { Icon } from '../..';
import ZoomPagination from './ZoomPagination';
import ZoomSlider from './ZoomSlider';
import Text from '../../text';
import { zIndex } from '../../../modules/common/constants';

type Props = {
  images: Image[];
  activeIndex: number;
  goToIndex: (index: number) => void;
  onClose: () => void;
  maxScale?: number;
};

const DEFAULT_MAX_ZOOM_MULTIPLIER = 2.5;
const CONTROL_WRAPPER_HEIGHT = '100px';
const IMAGE_HEIGHT = `calc(100vh - ${CONTROL_WRAPPER_HEIGHT})`;

const ZoomImage = ({
  images,
  activeIndex,
  goToIndex,
  onClose,
  maxScale = DEFAULT_MAX_ZOOM_MULTIPLIER,
}: Props) => {
  const [ref, setRef] = useState<HTMLDivElement>();
  const onRefChange = useCallback((node) => {
    setRef(node);
  }, []);

  useScrollLock({ ref, isActive: true });

  const [state, setState] = useState({
    scale: 1,
    lastScale: 1,
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
    hasZoomed: false,
  });

  const { webp, jpg, alt } = images[activeIndex];

  const getXY = ({ deltaX, deltaY, scale }) => {
    const newX = state.lastX + deltaX;
    const newY = state.lastY + deltaY;
    const maxX = ((scale - 1) * (ref?.clientWidth ?? 0)) / 2;
    const maxY = ((scale - 1) * (ref?.clientHeight ?? 0)) / 2;
    const x = newX > maxX ? maxX : newX < -maxX ? -maxX : newX;
    const y = newY > maxY ? maxY : newY < -maxY ? -maxY : newY;
    return { x, y };
  };

  const onPan = ({ deltaX, deltaY }) => {
    if (state.scale === 1) {
      return;
    }

    const { x, y } = getXY({ deltaX, deltaY, scale: state.scale });

    setState({
      ...state,
      x,
      y,
    });
  };

  const onPanEnd = () => {
    setState({ ...state, lastX: state.x, lastY: state.y });
  };

  const onDoubleTap = () => {
    const midScale = (maxScale + 1) / 2;
    const newScale =
      state.scale >= 1 && state.scale < midScale
        ? midScale
        : state.scale >= midScale && state.scale < maxScale
          ? maxScale
          : 1;
    const { x, y } = getXY({ deltaX: state.x, deltaY: state.y, scale: newScale });
    setState({
      ...state,
      scale: newScale,
      lastScale: newScale,
      x,
      y,
      lastX: x,
      lastY: y,
      hasZoomed: true,
    });
  };

  const onPinch = ({ scale, deltaX, deltaY }) => {
    const newScale = Math.max(1, Math.min(state.lastScale * scale, maxScale));
    const { x, y } = getXY({ deltaX, deltaY, scale: newScale });

    setState({ ...state, scale: newScale, x, y, hasZoomed: true });
  };

  const onPinchEnd = () => {
    setState({ ...state, lastScale: state.scale, lastX: state.x, lastY: state.y });
  };

  const getScale = (pos: number) => (pos / 200) * (maxScale - 1) + 1;
  const getPos = (s: number) => ((s - 1) / (maxScale - 1)) * 200;

  const onSliderPan = ({ deltaX }) => {
    const newScale = getScale(deltaX);
    onPinch({ deltaX: state.x, deltaY: state.y, scale: newScale });
  };

  const onSliderPanEnd = () => {
    onPinchEnd();
  };

  const sliderPos = getPos(state.scale);

  const zoomImageContent = (
    <Box
      position="fixed"
      zIndex={zIndex.MODAL}
      width="100%"
      height="100%"
      bg="WHITE"
      top={0}
      left={0}
      display="grid"
      gridTemplateRows={`auto ${CONTROL_WRAPPER_HEIGHT}`}
    >
      <Box position="relative" overflow="hidden" ref={onRefChange}>
        <Box
          position="absolute"
          zIndex="absolute"
          top="m"
          right="m"
          size="xxl"
          bg="WHITE"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onClose}
        >
          <Icon name="close" />
        </Box>
        <Hammer
          {...{ onDoubleTap, onPinch, onPinchEnd, onPan, onPanEnd }}
          options={{
            recognizers: {
              pinch: { enable: true },
            },
          }}
        >
          <ZoomedPicture scale={state.scale} x={state.x} y={state.y}>
            <source srcSet={webp} type="image/webp" />
            <source srcSet={jpg} type="image/jpeg" />
            <img src={jpg} alt={alt} />
          </ZoomedPicture>
        </Hammer>
        {!state.hasZoomed && (
          <Box position="absolute" zIndex="absolute" bottom="xxl" left={0} width="100%">
            <Box
              display="grid"
              gridAutoFlow="column"
              gridGap="s"
              m={['m', 'xxxl']}
              bg="WHITE"
              opacity={0.8}
              alignItems="center"
              justifyContent="center"
              height="xxxl"
            >
              <Icon name="pinch" size={36} />
              <Text>Pincez ou tappez pour zoomer</Text>
            </Box>
          </Box>
        )}
      </Box>
      <Box m="m" display="grid" gridGap="m" gridTemplateRows="30px 24px" mb="h">
        <ZoomSlider
          onPan={onSliderPan}
          onPanEnd={onSliderPanEnd}
          pos={sliderPos}
          {...{ maxScale }}
        />
        <ZoomPagination {...{ activeIndex, goToIndex }} numberOfItems={images.length} />
      </Box>
    </Box>
  );

  return ReactDOM.createPortal(zoomImageContent, document.body);
};

type ZoomedPictureProps = {
  x: number;
  y: number;
  scale: number;
};

const ZoomedPicture = styled.picture<ZoomedPictureProps>(({ x, y, scale }) => ({
  'source, img': {
    height: `${IMAGE_HEIGHT}`,
    position: 'absolute',
    top: 0,
    left: '50%',
    right: '50%',
    transform: `translate(-50%, 0) translate(${x}px, ${y}px) scale(${scale})`,
    transformOrigin: 'center',
    transition: 'transform 0.2s',
  },
}));

export default ZoomImage;
