import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pathOr } from 'ramda';
import { css } from '@emotion/core';

import { Box, Breadcrumbs, breakpoints, DynamicButton } from '../../../design-system';
import Images from './Images';
import { GiftCardInfoPanel } from './GiftCardInfoPanel';
import { GiftCardCustomInfos } from './GiftCardCustomInfos';
import { Selectors } from './Selectors';
import { GiftCardDescription } from './GiftCardDescription';
import { UspSection } from '../../home/components/UspSection';
import { CommitmentsBanner } from '../../banners';
import { heights } from '../../common/constants';
import { PromotionLabel } from './PromotionLabel';
import { GiftCardMiniDescription } from './GiftCardMiniDescription';
import { CTA_CONTENT } from '../locale';
import { UpSell } from './UpSell';
import { ImagesMozaic } from './ImagesMozaic';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Product } from '../types';
import { sortImagePositions, TGiftCardErrors } from '../utils';
import { RootState } from 'src/shared/store/rootReducer';
import { BreadcrumbsItems } from '../../catalog/types';
import { getBreadcrumbItems } from '../../catalog/utils';
import {
  PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_DESKTOP,
  PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_TABLET,
  TGiftCardInfos,
} from './Product';
import { Status } from '../../../design-system/button/components/DynamicButton';

type Props = {
  product: Product;
  giftCardInfos: TGiftCardInfos;
  handleGiftCardInfo: (infos: TGiftCardInfos) => void;
  handleNewAmount: (newAmount: string) => void;
  handleCtaClick: () => void;
  isCustomAmountDeselected: boolean;
  giftCardErrMsg: TGiftCardErrors;
  isQuickAddOnPDP: boolean;
  pdpAddToCartRef: MutableRefObject<HTMLDivElement | null>;
};

export const GiftCard = ({
  product,
  giftCardInfos,
  handleGiftCardInfo,
  handleNewAmount,
  handleCtaClick,
  isCustomAmountDeselected,
  giftCardErrMsg,
  isQuickAddOnPDP,
  pdpAddToCartRef,
}: Props) => {
  const {
    productRef,
    colorRef,
    productName,
    colorLabel,
    videoUrl,
    model,
    categories,
    detailedColorLabel,
    sizeVariants,
    colorVariants,
    status,
    promoLongLabel: promotionLongLabel,
    promoTColor: promotionTextColor,
    promoBColor: promotionBackgroundColor,
    label1: rankLabel,
    label2: attributeLabel,
  } = product;
  const { pathname } = useLocation();
  const imagePositions: number[] = sortImagePositions(pathOr([], ['images'], product));
  const { product: cmsProduct } = useSelector((state: RootState) => state.cms);
  const cmsCommitmentsBanner = cmsProduct.body?.find(
    (el) => el.slice_type === 'commitments_banner'
  );
  const { size, sizeErrMsg } = useSelector((state: RootState) => state.product);
  const { ctaState } = useSelector((state: RootState) => state.cart);
  const { usp: uspContent, usp_main_title: uspTitle } = useSelector(
    (state: RootState) => state.cms.usp
  );
  const cmsThumbnailImage = cmsProduct?.thumbnail_image?.thumbnail?.url ?? '';
  const objectID = `${productRef}/${colorRef}`;
  const { lvl0, lvl1, lvl2, lvl3 } = categories ?? {};
  const categoryLevels = [lvl0, lvl1, lvl2, lvl3].filter(Boolean);
  const breadcrumbItems: BreadcrumbsItems = getBreadcrumbItems(categoryLevels);
  const breadcrumbItemsWithCurrentProduct = [
    ...breadcrumbItems,
    {
      label: `${productName} - ${detailedColorLabel}`,
      value: '#',
      readonly: true,
    },
  ];
  const { giftCardAmount } = giftCardInfos;

  const { isMobile, isTablet } = useMediaQueries();
  return (
    <div>
      {isMobile ? (
        <Box display="grid" gridGap="s" mx="m">
          <Box display="grid" gridGap="s">
            <Box mx="-16px">
              <Images
                productRef={productRef}
                colorRef={colorRef}
                productName={productName}
                colorLabel={colorLabel}
                imagePositions={imagePositions}
                videoUrl={videoUrl}
                cmsThumbnailImage={cmsThumbnailImage}
                product={product}
                model={model}
                breadcrumbItems={breadcrumbItemsWithCurrentProduct}
              />
            </Box>
            <Box>
              <GiftCardInfoPanel amount={giftCardAmount} />
            </Box>
            <GiftCardCustomInfos
              onGiftCardInfo={handleGiftCardInfo}
              isCustomAmountDeselected={isCustomAmountDeselected}
              giftCardErrMsg={giftCardErrMsg}
              giftCardInfos={giftCardInfos}
            />
            <Selectors
              size={size}
              sizeErrMsg={sizeErrMsg}
              sizeVariants={sizeVariants}
              colorRef={colorRef}
              detailedColorLabel={detailedColorLabel}
              colorVariants={colorVariants}
              productRef={productRef}
              pathname={pathname}
              ctaState={ctaState}
              status={status}
              objectID={objectID}
              isGiftCard={true}
              handleNewAmount={handleNewAmount}
              isCustomAmountDeselected={isCustomAmountDeselected}
              isQuickAddOnPDP={isQuickAddOnPDP}
              onCtaClick={handleCtaClick}
              onGiftCardInfo={handleGiftCardInfo}
              giftCardInfos={giftCardInfos}
              giftCardErrMsg={giftCardErrMsg}
              giftCardAmount={giftCardAmount}
              handleCustomSizeBlockToggle={() => {}}
              customSizeBlockForVariantTwoIsOpen={false}
            />
            <GiftCardDescription />
            <CommitmentsBanner
              id={cmsCommitmentsBanner?.id}
              items={cmsCommitmentsBanner?.items}
              title={cmsCommitmentsBanner?.primary.commitments_banner_title}
            />
            <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
            <UspSection content={uspContent} title={uspTitle ?? ''} isProductPage />
          </Box>
        </Box>
      ) : isTablet ? (
        <>
          <div
            css={css`
              display: grid;
              grid-gap: 24px;
              margin: 0 16px;
            `}
          >
            <Box display="grid" gridTemplateColumns="1fr 1fr" justifySelf="center">
              <Box
                display="grid"
                gridGap="xl"
                gridAutoRows="min-content"
                alignSelf="start"
                position="sticky"
                top={`${heights.HEADER_TABLET}px`}
                className="product-image-container"
              >
                <Images
                  productRef={productRef}
                  colorRef={colorRef}
                  productName={productName}
                  colorLabel={colorLabel}
                  imagePositions={imagePositions}
                  videoUrl={videoUrl}
                  cmsThumbnailImage={cmsThumbnailImage}
                  product={product}
                  model={model}
                  breadcrumbItems={breadcrumbItemsWithCurrentProduct}
                />
                <Box ml={'m'}>
                  <GiftCardDescription />
                </Box>
              </Box>
              <Box
                ml="m"
                alignSelf="start"
                position="sticky"
                top={`${heights.HEADER_TABLET}px`}
                className="product-info-container"
              >
                <Box display="grid" gridGap="s">
                  <Box display="grid" gridGap="m" gridAutoRows="min-content">
                    <GiftCardInfoPanel amount={giftCardAmount} />
                    <GiftCardMiniDescription />
                    <PromotionLabel
                      promotionLongLabel={promotionLongLabel}
                      promotionTextColor={promotionTextColor}
                      promotionBackgroundColor={promotionBackgroundColor}
                      rankLabel={rankLabel}
                      attributeLabel={attributeLabel}
                      objectID={objectID}
                    />
                    <Selectors
                      size={size}
                      sizeErrMsg={sizeErrMsg}
                      sizeVariants={sizeVariants}
                      colorRef={colorRef}
                      detailedColorLabel={detailedColorLabel}
                      colorVariants={colorVariants}
                      productRef={productRef}
                      pathname={pathname}
                      ctaState={ctaState}
                      status={status}
                      objectID={objectID}
                      isGiftCard={true}
                      handleNewAmount={handleNewAmount}
                      isCustomAmountDeselected={isCustomAmountDeselected}
                      isQuickAddOnPDP={isQuickAddOnPDP}
                      onCtaClick={handleCtaClick}
                      onGiftCardInfo={handleGiftCardInfo}
                      giftCardInfos={giftCardInfos}
                      giftCardErrMsg={giftCardErrMsg}
                      giftCardAmount={giftCardAmount}
                      handleCustomSizeBlockToggle={() => {}}
                      customSizeBlockForVariantTwoIsOpen={false}
                    />
                  </Box>
                  <div ref={pdpAddToCartRef}>
                    <DynamicButton
                      id="btn-add-to-cart"
                      onClick={handleCtaClick}
                      data={CTA_CONTENT(isMobile)}
                      feedback={isQuickAddOnPDP ? Status.Default : ctaState}
                      disabled={!status}
                    />
                  </div>
                </Box>
              </Box>
            </Box>
            <UpSell />
          </div>
          <CommitmentsBanner
            id={cmsCommitmentsBanner?.id}
            items={cmsCommitmentsBanner?.items}
            title={cmsCommitmentsBanner?.primary.commitments_banner_title}
          />
          <Box mx={'m'}>
            <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
          </Box>
          <UspSection content={uspContent} title={uspTitle ?? ''} isProductPage />
        </>
      ) : (
        <div
          css={css`
            display: grid;
            grid-gap: 24px;
            margin: 0 24px;

            @media (min-width: ${breakpoints.XL + 24}px) {
              margin: 0;
            }
          `}
        >
          <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
          <Box display="flex" gridGap="48px">
            <Box
              flex="1"
              alignSelf="start"
              position="sticky"
              top={`${heights.HEADER}px`}
              className="product-image-container"
            >
              <ImagesMozaic
                productRef={productRef}
                colorRef={colorRef}
                productName={productName}
                colorLabel={colorLabel}
                imagePositions={imagePositions}
                videoUrl={videoUrl}
                product={product}
                model={model}
              />
            </Box>
            <div
              css={css`
                flex: 0 1 ${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_TABLET}px;
                align-self: start;
                position: sticky;
                top: ${heights.HEADER}px;

                @media (min-width: ${breakpoints.L}px) {
                  flex: 0 1 ${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_DESKTOP}px;
                }
              `}
              className="product-info-container"
            >
              <Box display="grid" gridGap="s">
                <Box display="grid" gridGap="m" gridAutoRows="min-content">
                  <GiftCardInfoPanel amount={giftCardAmount} />
                  <GiftCardMiniDescription />

                  <PromotionLabel
                    promotionLongLabel={promotionLongLabel}
                    promotionTextColor={promotionTextColor}
                    promotionBackgroundColor={promotionBackgroundColor}
                    rankLabel={rankLabel}
                    attributeLabel={attributeLabel}
                    objectID={objectID}
                  />
                  <Selectors
                    size={size}
                    sizeErrMsg={sizeErrMsg}
                    sizeVariants={sizeVariants}
                    colorRef={colorRef}
                    detailedColorLabel={detailedColorLabel}
                    colorVariants={colorVariants}
                    productRef={productRef}
                    pathname={pathname}
                    ctaState={ctaState}
                    status={status}
                    objectID={objectID}
                    isGiftCard={true}
                    handleNewAmount={handleNewAmount}
                    isCustomAmountDeselected={isCustomAmountDeselected}
                    isQuickAddOnPDP={isQuickAddOnPDP}
                    onCtaClick={handleCtaClick}
                    onGiftCardInfo={handleGiftCardInfo}
                    giftCardInfos={giftCardInfos}
                    giftCardErrMsg={giftCardErrMsg}
                    giftCardAmount={giftCardAmount}
                    handleCustomSizeBlockToggle={() => {}}
                    customSizeBlockForVariantTwoIsOpen={false}
                  />
                </Box>
                <div ref={pdpAddToCartRef}>
                  <DynamicButton
                    id="btn-add-to-cart"
                    onClick={handleCtaClick}
                    data={CTA_CONTENT(isMobile)}
                    feedback={isQuickAddOnPDP ? Status.Default : ctaState}
                    disabled={!status}
                  />
                </div>
                <GiftCardDescription />
              </Box>
            </div>
          </Box>
          <UpSell />
          <CommitmentsBanner
            id={cmsCommitmentsBanner?.id}
            items={cmsCommitmentsBanner?.items}
            title={cmsCommitmentsBanner?.primary.commitments_banner_title}
          />
          <UspSection content={uspContent} title={uspTitle ?? ''} isProductPage />
        </div>
      )}
    </div>
  );
};
