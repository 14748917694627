import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import pathOr from 'ramda/src/pathOr';
import { css } from '@emotion/core';

import { Box, Text } from '../../../design-system';
import { ButtonLink } from '../../../design-system/button';
import withCommonUI from '../../common/hocs/withCommonUI';
import { CmsCustomTypes } from '../../cms/types';
import { RootState } from '../../../store/rootReducer';
import { getOrderNumber, removeToken } from '../../payment/utils';
import { getPersistedData, persistData } from '../../common/utils';
import { loadCmsContent } from '../../cms/actions';
import { setFormValues } from '../../form/actions';
import { Forms } from '../../form/types';
import { setPaypalECS } from '../actions';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

export const OrderSuccess = () => {
  const dispatch = useDispatch();

  const cmsContent = useSelector((state: RootState) => state.cms.ordersuccess);
  const {
    email: emailPaypalExpress,
    firstName: firstNamePaypalExpress,
    orderNumber: orderNumberPaypalExpress,
  } = useSelector((state: RootState) => state.cart.paypalECS);
  const image = pathOr('', ['image', 'desktop', 'url'], cmsContent);
  const imageMobile = pathOr('', ['image', 'url'], cmsContent);
  const title = pathOr('', ['title', 0, 'text'], cmsContent);
  const block = pathOr('', ['block', 0, 'text'], cmsContent);
  const cta = pathOr('', ['cta'], cmsContent);
  const link = pathOr('', ['link'], cmsContent);

  const user = useSelector((state: RootState) => state.account.user);
  const { isFetching } = useSelector((state: RootState) => state.account);
  const name = isFetching ? '' : (user?.firstName ?? firstNamePaypalExpress ?? '');
  const email = isFetching ? '' : (user?.email ?? emailPaypalExpress ?? '');

  const [orderNumber, setOrderNumber] = useState('');

  useEffect(() => {
    dispatch(loadCmsContent(CmsCustomTypes.ordersuccess) as unknown as UnknownAction);

    const token = getPersistedData('paymentToken');
    setOrderNumber(
      getOrderNumber(token) && getOrderNumber(token) !== ''
        ? getOrderNumber(token)
        : orderNumberPaypalExpress
    );
    // timeout needed in case we have one more cart opened on a different tab
    // to make sure the second tab is synchronized before removing the token
    setTimeout(() => removeToken(token), 3000);

    dispatch(
      setFormValues({
        form: Forms.delivery,
        values: {
          showBilling: false,
          showGiftMessage: false,
          isConditionsAccepted: false,
          pickupPhone: '',
          giftMessage: '',
        },
      })
    );
    return () => {
      if (emailPaypalExpress !== '') {
        dispatch(setPaypalECS({ email: '', firstName: '', orderNumber: '' }));
        persistData('isPaypalECS', false);
        persistData('subscribePaypalECS', false);
      }
    };
  }, []);

  const injectedTitle = title.replace('$name', name);
  const injectedBlock = block.replace('$orderNo', orderNumber).replace('$email', email);

  if (!orderNumber) {
    return (
      <Box>
        <Text color="ERROR">Order not found!</Text>
      </Box>
    );
  }

  return (
    <Box
      maxWidth="mwl"
      height={['100vw', '70vh']}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundImage={[`url(${imageMobile})`, `url(${image})`]}
      backgroundSize="cover"
      flexDirection="column"
      flex="1"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      mx={['na', 'na', 'na', 'l']}
      textAlign="center"
    >
      <Box mb="l">
        <Text preset="heading" color="WHITE">
          {injectedTitle}
        </Text>
      </Box>
      <Box maxWidth="60ch" mb="xl">
        <Text color="WHITE">{injectedBlock}</Text>
      </Box>

      <ButtonLink
        id="link-order-success"
        to={getFormatedLinkForReactRouterLink(link)}
        data-testid="link-order-success"
        aria-label={`Bouton pour ${cta.toLocaleLowerCase()}`}
        css={css`
          text-decoration: none;
          color: ${image ? 'BLACK' : 'WHITE'};
          width: fit-content;
        `}
        preset="primaryLight"
      >
        {cta}
      </ButtonLink>
    </Box>
  );
};

export default withCommonUI(OrderSuccess);
