export default {
  HOME: '/',
  OUR_BRAND: '/notre-marque',
  CART: '/cart',
  PRODUCT: '/produit',
  CATALOG: '/catalogue',
  SEARCH_RESULTS: '/page/resultats-recherche',
  SELECTION: '/selections',
  ACCOUNT: '/compte',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/resetpassword',
  STORE_LOCATOR: '/storelocator',
  OFFERS: '/conditions-des-offres-en-cours',
  NOT_FOUND: '/404',
  SIZE_GUIDE: '/nos-conseils/guide-des-tailles',
  AFFILIATE: '/devenir-affilie',
  STUDENT_PAGE: '/remise-etudiante',
  TOP_TEN_PAGE: '/categorie',
  BRAND: '/page',
  ORDER_TRACKING: '/suivi-commande',
  GENERIC: [
    '/cgv',
    '/faq',
    '/livraisons-et-retours',
    '/mentions-legales',
    '/politique-de-cookies',
    '/politique-de-confidentialite',
  ],
};
