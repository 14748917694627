import React from 'react';

import { Box, Text, Icon } from '../../../design-system';
import { TITLE_SOCIAL_LINKS } from '../locale';
import { CmsLink } from '../../cms/types';
import { Link } from 'react-router-dom';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  links: {
    icon?: string;
    link?: CmsLink;
  }[];
};

export const SocialLinks = ({ links }: Props) => (
  <Box
    display="grid"
    gridGap="m"
    mb={['m', 'm', 'na']}
    justifyContent={['center', 'center', 'initial']}
  >
    <Text fontWeight="bold" textTransform="uppercase" display={['none', 'none', 'block']}>
      {TITLE_SOCIAL_LINKS}
    </Text>
    <Box display="grid" gridAutoFlow="column" gridGap={['xxl', 'xxl', 'm']} justifyContent="left">
      {links.map((link, index) => (
        <Link
          key={index}
          data-testid={`footer-social-links-${index}`}
          id={`footer-social-links-${index}`}
          to={getFormatedLinkForReactRouterLink(link?.link?.url)}
          target={link?.link?.target ?? '_self'}
          aria-label={`Lien vers la page ${link?.link?.url ?? 'actuelle'}`}
        >
          <Icon name={link?.icon ?? ''} size={20} />
        </Link>
      ))}
    </Box>
  </Box>
);
