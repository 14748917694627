import React from 'react';

import { Box, Button, Icon } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { scrollToFormAffiliate } from '../utils';
import { CmsImage } from '../../cms/types';
import { ImagesScrollEffect } from './ImagesScollEffect';

type Props = {
  images: CmsImage[];
};

const ImagesBlock = ({ images }: Props) => {
  const { isMobile, isTablet } = useMediaQueries();

  return (
    <>
      <Box
        id="images-container"
        width="100%"
        mt={['98px', '64px', '88px']}
        backgroundColor="rgba(218, 176, 158, 0.3)"
        display="flex"
        flexDirection={['column-reverse', 'row', 'row']}
        justifyContent={['na', 'center', 'center']}
        pt={['na', '88px', '88px']}
      >
        <Box
          id="comment block"
          width={['100%', '40%', '40%']}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            width={['343px', '100%', '100%']}
            fontSize={['20px', '20px', '30px']}
            fontFamily="Ogg-text"
            fontWeight="700"
            mb={['16px', '32px', 'na']}
            lineHeight={['110%', '130%', '130%']}
            textAlign={['na', 'center', 'center']}
            pt={['16px', 'na', '32px']}
            position={['block', 'absolute', 'absolute']}
          >
            Les garanties Darjeeling
          </Box>
          <Box
            id="affiliate-comment-1"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Un apprentissage initial et continu
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Pour vous former vous et vos équipes sur nos produits et nos méthodes.
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-2"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Un pilotage des stocks
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Assuré par Darjeeling afin d’optimiser vos stocks en boutique.
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-3"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Reprise des stocks invendus
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Toutes les fins de saisons.
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-4"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Un réassort quotidien
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Pour assurer le développement de votre chiffre d’affaires.
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-5"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Des outils éprouvés
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Pour évaluer et développer votre business (tables rondes, ateliers en
                co-construction avec le siège...).
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-6"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Une aide quotidienne
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Dans la gestion de votre boutique (informatique, produit, commande internet,
                relation client...).
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-7"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Un soutien précieux de nos équipes
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Tout au long de la vie de votre boutique sur la partie commerciale mais aussi
                marketing et communication.
              </Box>
            </Box>
          </Box>
          <Box
            id="affiliate-comment-8"
            my={['8px', '16px', '16px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={['auto', '271px', '363px']}
          >
            <Box
              p="16px"
              width={['343px', '255px', '336px']}
              borderRadius="4px"
              backgroundColor="white"
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box display="flex">
                <Icon name="checkmark" size={16} />
                <Box
                  ml="8px"
                  fontFamily="Ogg-text"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="110%"
                >
                  Une relation régulière
                </Box>
              </Box>
              <Box fontSize="16px" mt="8px" lineHeight="130%">
                Avec votre Responsable régionale pour répondre à tous vos besoins grâce à des
                équipes dédiées.
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          id="pictures-block"
          width={['100%', '60%', '40%']}
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems={['center', 'center', 'center']}
          >
            {!isMobile && <ImagesScrollEffect images={images} />}
            {isMobile && images && images[0] && (
              <>
                <Box
                  width="343px"
                  height="216px"
                  backgroundImage={`url(${images[0].url})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  mt="-50px"
                  mb="16px"
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent={['na', 'na', 'center']}
        backgroundColor="rgba(218, 176, 158, 0.3)"
        pt={['32px', '24px', '24px']}
        pb={['48px', '88px', '80px']}
      >
        <Box
          width={['100%', '40%', '80%']}
          display="flex"
          justifyContent={['center', 'center', 'flex-start']}
        >
          <Box
            width={['343px', '255px', '50%']}
            display="flex"
            justifyContent={['center', 'center', 'center']}
          >
            <Box
              width={['343px', '255px', '336px']}
              display="flex"
              justifyContent={['center', 'flex-start', 'flex-start']}
            >
              <Box width={['343px', '173px', '188px']}>
                <Button
                  id="affiliate-button"
                  onClick={() => scrollToFormAffiliate({ isMobile, isTablet })}
                  aria-label={`Bouton pour accéder au formulaire d'affiliation`}
                  data-testid="affiliate-button"
                  type="button"
                >
                  Devenir affilié
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImagesBlock;
