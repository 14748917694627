import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { breakpoints, colors } from '../systemprovider';
import { zIndex, widths, durations } from '../../../shared/modules/common/constants';
import Icon from '../icon';
import withSidebarNew from '../../../shared/modules/common/hocs/withSidebarNew';

type StyledProps = {
  $direction: Props['direction'];
};

type Props = {
  title: React.ReactNode | string;
  direction: 'left' | 'right';
  content: React.ReactNode;
  isOpen: boolean;
  closeSidebarNew: () => void;
};

const StyledSidebar = styled.div<StyledProps>`
  pointer-events: none;
  &.sidebar--is-open {
    pointer-events: all;
    .sidebar__overlay {
      opacity: 1;
    }
    .sidebar__content {
      transform: translate3d(0, 0, 0);
    }
  }
  .sidebar__overlay {
    border: none;
    padding: 0;
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    transition: all ${durations.ENTER}ms ease-in-out;
    z-index: ${zIndex.SIDEBAR};
    opacity: 0;
  }
  .sidebar__content {
    top: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all ${durations.ENTER}ms ease-in-out;
    z-index: ${zIndex.SIDEBAR};
    background-color: ${colors.WHITE};
    width: calc(100vw - 56px);
    max-width: ${widths.SIDEBAR}px;
    @media (min-width: ${breakpoints.S}px) {
      width: ${widths.SIDEBAR}px;
    }
    @media (max-width: ${breakpoints.M}px) {
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }
    @media (min-width: ${breakpoints.M}px) {
      ${(props) =>
        props.$direction === 'left'
          ? `left: 0; transform: translate3d(-100%, 0, 0);`
          : `right: 0; transform: translate3d(100%, 0, 0); `};
    }
  }
  .sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.LIGHT};
    padding: 4px 4px 4px 16px;
  }
  .sidebar__title {
    flex: 1 0 auto;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin: 0 0 0 20px;
    text-align: center;
    text-transform: uppercase;
  }
  .sidebar__close-button {
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const SidebarNew = ({ isOpen, direction, title, content, closeSidebarNew }: Props) => {
  const [localContent, setLocalContent] = useState<React.ReactNode>(content);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const { pathname } = useLocation();
  const handleClose = () => {
    closeSidebarNew();
  };

  useEffect(() => {
    if (isOpen) {
      handleClose();
    }
  }, [pathname]);

  useEffect(() => {
    if (!isOpen) {
      timeoutId.current = setTimeout(() => {
        setLocalContent(null);
      }, durations.ENTER);
    } else {
      setLocalContent(content);
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [isOpen]);

  return (
    <StyledSidebar $direction={direction} className={`sidebar ${isOpen ? `sidebar--is-open` : ''}`}>
      {isOpen && (
        <button
          className="sidebar__overlay"
          onClick={handleClose}
          type="button"
          data-testid="sidebar-background-close-button"
          aria-label="Close sidebar"
        />
      )}
      <div className="sidebar__content">
        <header className="sidebar__header">
          <h2 className="sidebar__title">{title}</h2>
          <button
            className="sidebar__close-button"
            data-testid="sidebar-close-button"
            type="button"
            onClick={handleClose}
            aria-label="Bouton pour fermer la fenêtre latérale"
          >
            <Icon name="close" size={20} />
          </button>
        </header>
        <div className="sidebar__children">{localContent}</div>
      </div>
    </StyledSidebar>
  );
};

export const SidebarWithHoc = withSidebarNew(SidebarNew);
