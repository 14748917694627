// Should be always in this order
export const WEEK_DAYS = {
  sunday: 'dimanche',
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mercredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
};
export const MSG_NO_STORE_FOUND =
  'Désolé, nous n’avons pas trouvé de boutique correspondant à votre recherche.';
export const REOPENING_DATE = 'Date de réouverture';
export const EXCEPTIONAL_OPENINGS = 'Ouvertures exceptionnelles';
export const EXCEPTIONAL_CLOSINGS = 'Fermetures exceptionnelles';
export const ERR_LOCATION = "La géolocalisation n'est pas prise en charge";
export const ERR_LOCATION_NOT_ACTIVE =
  'Veuillez activer la localisation sur votre navigateur et dans les paramètres système.';
export const ERR_SELECT_STORE = 'Sélectionnez une boutique';
export const LABEL_INPUT = 'Insérer l’emplacement';
export const MODAL_TITLE = 'Trouver une boutique';
export const MODAL_TITLE2 = 'Sélectionnez une boutique';
export const SELECT_STORE_ERESA_MODAL = 'Choisir cette boutique';
export const MODAL_TITLE3 = 'Réservation disponible sous 1h en boutique, sans obligation d’achat';
export const SELECT_STORE = 'Sélectionner cette boutique';
export const SELECTED_STORE = 'Sélectionnée';
export const SEE_ON_THE_MAP = 'Voir sur la carte';
export const CHANGE_STORE = 'Changer de magasin';
export const YOUR_POSITION = 'Votre position';
export const SEARCH_BUTTON = 'Rechercher';

export const BACK_TO_STORE_LIST = 'Retournez à la liste des boutiques';

export const ACCEPTED_PAYMENT_METHODS = 'Moyens de paiement acceptés';
export const CREDIT_CARDS = 'Cartes bancaires';
export const GIFTS_CARDS = 'Cartes cadeaux: Darjeeling, Illicado, Kadeos';
export const CHEQUE = 'Chèque';
export const CASH = 'Espèces';
export const ITINERARY = 'Itinéraire';

export const STORE_ASSITANCES = 'Les services en boutique';
export const ARRANGE_APPOINTMENT_TXT =
  'Prenez rendez-vous avec une conseillère dans votre boutique';

export const PAGE_TITLE = 'Trouver une boutique | Darjeeling';

export const STORE_PAGE_TITLE = (name: string) => `Boutique Lingerie - ${name} `;
export const STORE_PAGE_DESCRIPTION = (name: string) =>
  `Retrouvez dans notre boutique ${name} tout notre savoir-faire corsetier. Un grand choix des soutiens-gorge du bonnet A à H.`;

export const USE_GEOLOCALISATION = 'Géolocalisation';
export const STORE_DELIVERY_SEARCH_PLACEHOLDER = 'Ville ou code postal';
export const LBL_DELIVERY_SEARCH_TITLE =
  'Entrez le nom de la ville ou le code postal ou bien procédez à la géolocalisation';
