import * as React from 'react';

import { Carousel } from '../../../design-system';
import { getImageList } from '../utils';
import { BackButton } from './BackButton';
import { ModelPDP, Product } from '../types';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  imagePositions: number[];
  productRef: string;
  colorRef: string;
  productName: string;
  colorLabel: string;
  product: Product;
  videoUrl?: string;
  cmsThumbnailImage?: string;
  breadcrumbItems: { label: string; value: string }[];
  model?: ModelPDP[] | null;
};

const Images = ({
  imagePositions,
  productRef,
  colorRef,
  productName,
  colorLabel,
  cmsThumbnailImage,
  videoUrl,
  breadcrumbItems,
  model,
}: Props) => {
  const images = getImageList({
    imagePositions,
    productRef,
    colorRef,
    productName,
    colorLabel,
  });
  const carouselImages = images.map((image) => image.default);

  const { isMobile } = useMediaQueries();

  const video = {
    url: videoUrl,
    thumbnail: cmsThumbnailImage,
  };

  return (
    <Carousel
      images={carouselImages}
      model={model}
      {...{ video }}
      width={isMobile ? '100vw' : '100%'}
      imagePositions={imagePositions}
    >
      {isMobile && <BackButton breadcrumbItems={breadcrumbItems} />}
    </Carousel>
  );
};

export default Images;
