export function getUid(pathname: string): string {
  return pathname?.slice(1).replace(/\//g, '_');
}

export function getDynamicUid(pathname: string): string {
  return getUid(`/${pathname.split('/').filter(Boolean).slice(1).join('/')}`);
}

export async function copyToClipboard(text: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
}

export function formatDate(dateString: string): string {
  return dateString.split('-').reverse().join('.');
}

export function getDiffHour(dateString: string): number {
  const today = new Date();
  const date = new Date(dateString);
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  return (date.getTime() - today.getTime()) / 36e5;
}
