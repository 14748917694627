import React from 'react';
import { css } from '@emotion/core';

import { Box, Icon, Dropdown, colors } from '../../../design-system';
import accountPaths from '../paths';
import paths from '../../routing/paths';
import { pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { User } from '../../auth/types';
import { getIsClient } from '../../common/utils';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  logout: () => void;
  user?: User;
};

const Menu = ({ logout, user }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuItems = [
    {
      value: 'info',
      label: 'Vos informations',
      link: paths.ACCOUNT + accountPaths.INFO,
    },
    { value: 'orders', label: 'Vos commandes', link: paths.ACCOUNT + accountPaths.ORDERS },
    { value: 'addresses', label: 'Vos adresses', link: paths.ACCOUNT + accountPaths.ADDRESSES },
  ];

  const mobileMenuItems = [
    ...menuItems,
    { value: 'logout', label: 'Se déconnecter', link: paths.LOGOUT },
  ];

  const currentOption = menuItems.find((menuItem) => pathname.startsWith(menuItem.link));

  const onMenuChange = (value: string) => {
    const item = mobileMenuItems.find((menuItem) => menuItem.value === value);
    if (item) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    logout();
    if (getIsClient()) {
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);

      pushToGTM(Events.updateAuthStatus, {
        eventType: 'logout',
        user: {
          id: user?.sub ?? '',
        },
      });
    }
  };

  return (
    <>
      <Box display={['none', 'block']} mx="l">
        {menuItems.map((menuItem) => (
          <Box key={menuItem.value} display="flex" alignItems="center">
            {pathname.startsWith(menuItem.link) && (
              <Box ml="-8px" mr="xs">
                <Icon name="chevronRight" size={14} />
              </Box>
            )}
            <button
              id={`btn-account-menu-${menuItem.link}`}
              onClick={() => onMenuChange(menuItem.value)}
              type="button"
              css={css`
                border: none;
                background: unset;
                padding: 8px;
                font-size: 1.4rem;
                color: ${colors.BLACK};
                font-weight: 500;
                margin: 4px 0;

                @media (hover: hover) {
                  :hover {
                    cursor: pointer;
                  }
                }
              `}
            >
              {menuItem.label}
            </button>
          </Box>
        ))}
        <button
          type="button"
          data-testid="account-logout-btn"
          id="account-logout-btn"
          onClick={handleLogout}
          css={css`
            border: none;
            background: unset;
            padding: 8px;
            font-size: 1.4rem;
            color: ${colors.BLACK};
            margin: 24px 0;
            font-weight: 500;
            @media (hover: hover) {
              :hover {
                cursor: pointer;
              }
            }
          `}
        >
          Se déconnecter
        </button>
      </Box>
      {currentOption && (
        <Box display={['block', 'none']}>
          <Dropdown
            id="dropdown-account-navigation"
            options={mobileMenuItems}
            selected={currentOption.value}
            onChange={onMenuChange}
          />
        </Box>
      )}
    </>
  );
};

export default Menu;
