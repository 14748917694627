import React from 'react';
import { Link } from 'react-router-dom';

import { CmsCatalogHighlightItem } from '../../../cms/types';
import { ProductCard } from '../../../catalog/components/ProductCard';
import { getFormatedLinkForReactRouterLink } from '../../../TopTen/utils';
import { CatalogProduct } from '../../../catalog/types';
import { Product } from 'src/shared/modules/product/types';

type Props = {
  item: CmsCatalogHighlightItem;
  hit: Product;
};

export const BannerItem = ({ item, hit }: Props) => {
  const product = {
    ...hit,
    objectID: `${hit?.productRef}/${hit?.colorRef}`,
    rcFilter: `${hit?.productRef}-${hit?.colorRef}`,
  };

  return (
    <div>
      {item.banner_product_ref?.length ? (
        <ProductCard hit={product as unknown as CatalogProduct} />
      ) : (
        <Link
          data-testid="highlighted-banner-item-link"
          to={getFormatedLinkForReactRouterLink(item.banner_link)}
        >
          <img
            className="item-visual__image"
            src={item.banner_image?.url}
            alt={item.banner_image?.alt ?? ''}
          />
        </Link>
      )}
    </div>
  );
};
