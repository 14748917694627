import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { CartItem as CartItemType } from '../types';
import {
  Icon,
  ImageContainer,
  Image,
  ProductName,
  opacities,
  breakpoints,
  colors,
} from '../../../design-system';
import { Link } from 'react-router-dom';
import { getProductImageLink, getProductLink, productImageWidths } from '../../product/utils';
import { transitions, durations } from '../../common/constants';
import { formatPrice } from '../../common/utils';
import { checkMessageSpaces, isDateCorrect } from '../utils';
import { SEE_LESS, SEE_MORE } from '../../home/locale';
import CartGiftCardEdit from './CartGiftCardEdit';
import {
  DATE_OF_SEND,
  GIFT_CARD_DATE_ERROR,
  LBL_MESSAGE,
  RECEIVER_EMAIL,
} from '../../quickadd/locale';
import { ColorText } from '../../common/components/Custom';
import { getPromotionColors } from '../../catalog/utils';
import { RootState } from 'src/shared/store/rootReducer';

type Props = {
  item: CartItemType;
  showUndo: boolean;
  showMove: boolean;
  isAvailable?: boolean;
  id: string;
  handleDeleteItem: () => void;
};

export const CartItemGiftCard = ({
  item,
  showMove,
  showUndo,
  isAvailable,
  id,
  handleDeleteItem,
}: Props) => {
  const [editGiftCard, setEditGiftCard] = useState(false);
  const [showMoreMessage, setShowMoreMessage] = useState(false);
  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  const {
    productRef,
    colorRef,
    productName,
    colorLabel,
    rowBaseTotal,
    promotionPercentage,
    promotionLabel,
  } = item;

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
  });

  const baseTotal = formatPrice(rowBaseTotal);

  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });
  const productLink = getProductLink({ productRef, colorLabel, colorRef, productName });
  const emailGiftCard = item?.gcFields?.email ?? '';
  const dateGiftCard = item?.gcFields?.date ?? '';
  const message = item?.gcFields?.message ?? '';
  const isMessageTooLong = message.length > 60;
  return (
    <>
      <div
        css={css`
          display: flex;
          margin: 16px;
          transition: ${transitions.GENERIC};
          opacity: ${showUndo || showMove ? 0 : 1};
          gap: 16px;

          @media (min-width: ${breakpoints.S}px) {
            margin: 16px 0;
          }
        `}
      >
        <div
          css={css`
            opacity: ${isAvailable ? 1 : opacities.DISABLED};
            min-width: ${productImageWidths.CART}px;
            justify-self: left;
            align-self: center;
          `}
        >
          <Link id={`link-cart-item-image-${id}-${item.gcFields?.itemId}`} to={productLink}>
            <ImageContainer>
              <Image isAbsolute src={thumbnail} alt="Product Image" />
            </ImageContainer>
          </Link>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 16px;
              margin: 16px 0 8px 0;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 8px;
                align-items: center;
              `}
            >
              <p
                css={css`
                  margin: 0;
                  font-size: 1.6rem;
                  font-weight: 700;
                  text-transform: uppercase;
                `}
                id={`link-cart-item-name-${id}-${item.gcFields?.itemId}`}
              >
                <ProductName productName={productName} />
              </p>
            </div>
            <div
              css={css`
                display: flex;
              `}
            >
              <p
                id={`p-cart-item-coupon-amount-${id}-${item.gcFields?.itemId}`}
                css={css`
                  margin: 0;
                  font-size: 1.6rem;
                  font-weight: 700;
                `}
              >
                {baseTotal}
              </p>
            </div>
          </div>
          {(promotionPercentage || promotionLabel) && (
            <ColorText
              alignItems="center"
              borderRadius={4}
              py="xxs"
              px="s"
              mb="12px"
              cmsColor={textColor}
              bg={backgroundColor}
              fontWeight="bold"
              preset="caption"
              alignSelf="start"
            >
              {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
            </ColorText>
          )}
          {!editGiftCard ? (
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  border: solid 0.5px black;
                  padding: 8px;
                  margin-bottom: 8px;
                  width: 100%;

                  @media (min-width: ${breakpoints.S}px) {
                    width: 70%;
                    margin-bottom: 16px;
                  }

                  @media (min-width: ${breakpoints.M}px) {
                    width: 50%;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <p
                    css={css`
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    margin: 0  0 10px 0;
                     >
                    `}
                  >
                    {`${RECEIVER_EMAIL} :`}
                  </p>
                  <button
                    id={`gift-cart-btn-edit-${id}-${item.gcFields?.itemId}`}
                    data-testid={`gift-cart-btn-edit-${id}-${item.gcFields?.itemId}`}
                    aria-label={`Bouton pour éditer les informations de la carte cadeau`}
                    type="button"
                    onClick={() => setEditGiftCard(true)}
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      background: unset;
                      border: none;
                      padding: 0;
                      cursor: pointer;
                      transition: opacity ${durations.ENTER}ms ease-in-out;

                      @media (hover: hover) {
                        &:hover {
                          opacity: ${opacities.HOVERED};
                        }
                      }
                    `}
                  >
                    <Icon name="edit" size={15.4} />
                  </button>
                </div>
                <p
                  css={css`
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.6rem;
                    margin: 0 0 10px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  {emailGiftCard}
                </p>
                <p
                  css={css`
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    margin: 0 0 10px 0;
                  `}
                >
                  {`${DATE_OF_SEND} :`}
                </p>
                <p
                  css={css`
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.6rem;
                    margin: 0 0 10px 0;
                    color: ${isDateCorrect(new Date(dateGiftCard)) ? colors.BLACK : colors.ERROR};
                  `}
                >
                  {new Date(dateGiftCard).toLocaleDateString('fr-FR')}
                </p>
                {!isDateCorrect(new Date(dateGiftCard)) && (
                  <p
                    css={css`
                      margin: 0 0 10px 0;
                      color: ${colors.ERROR};
                      font-size: 1.4rem;
                      line-height: 1.6rem;
                    `}
                  >
                    {GIFT_CARD_DATE_ERROR}
                  </p>
                )}
                {message && (
                  <p
                    css={css`
                      font-size: 1.4rem;
                      line-height: 1.6rem;
                      margin: 0 0 10px 0;
                    `}
                  >
                    {`${LBL_MESSAGE} :`}
                  </p>
                )}
                <div
                  css={css`
                    display: ${message ? 'flex' : 'none'};
                    overflow: hidden;
                  `}
                >
                  <p
                    css={css`
                      margin: 0;
                      font-size: 1.4rem;
                      font-weight: 700;
                      line-height: 1.6rem;
                    `}
                  >
                    {isMessageTooLong && !showMoreMessage
                      ? checkMessageSpaces(message, 45).slice(0, 59) + '...'
                      : checkMessageSpaces(message, 45)}
                    <br />
                    {isMessageTooLong && (
                      <button
                        id={`gift-cart-btn-show-${showMoreMessage ? 'less' : 'more'}-${id}-${item.gcFields?.itemId}`}
                        data-testid={`gift-cart-btn-show-${showMoreMessage ? 'less' : 'more'}-${id}-${item.gcFields?.itemId}`}
                        aria-label={`Bouton pour ${showMoreMessage ? 'réduire le' : 'afficher la totalité du'} message de la carte cadeau`}
                        type="button"
                        onClick={() => {
                          setShowMoreMessage(!showMoreMessage);
                        }}
                        css={css`
                          background: none;
                          border: none;
                          padding: 8px 0;
                          cursor: pointer;
                          transition: opacity ${durations.ENTER}ms ease-in-out;

                          @media (hover: hover) {
                            &:hover {
                              opacity: ${opacities.HOVERED};
                            }
                          }
                        `}
                      >
                        <p
                          css={css`
                            display: inline;
                            color: ${colors.GREY};
                            font-size: 1.4rem;
                            line-height: 1.6rem;
                          `}
                        >
                          {showMoreMessage ? SEE_LESS : SEE_MORE}
                        </p>
                      </button>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : item.gcFields ? (
            <CartGiftCardEdit
              item={item}
              gcFields={item.gcFields}
              onCloseEdit={() => setEditGiftCard(false)}
            />
          ) : null}
          <button
            id={`btn-cart-item-remove-${id}-${item.gcFields?.itemId}`}
            data-testid={`btn-cart-item-remove-${id}-${item.gcFields?.itemId}`}
            aria-label={`Bouton pour supprimer la carte cadeau du panier`}
            type="button"
            onClick={handleDeleteItem}
            css={css`
              background: none;
              border: none;
              padding: 0;
              display: flex;
              align-items: end;
              justify-content: flex-end;
              margin: 8px 0 0 0;
              cursor: pointer;
              transition: opacity ${durations.ENTER}ms ease-in-out;

              @media (hover: hover) {
                &:hover {
                  opacity: ${opacities.HOVERED};
                }
              }

              @media (min-width: ${breakpoints.S}px) {
                margin: 0;
              }
            `}
          >
            <Icon name="trash" size={20} />
          </button>
        </div>
      </div>
    </>
  );
};
