import { useState, useEffect, useMemo } from 'react';
import { fetchProductFromBackend } from '../../api/bff';
import { Product } from '../../product/types';

const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();
  return sorted1.every((value, index) => value === sorted2[index]);
};

export const useProductsInfos = (productsRefArray: string[]) => {
  const [hits, setHits] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [productsRef, setProductsRef] = useState<string[]>([]);

  const shouldUpdate = useMemo(
    () => !areArraysEqual(productsRefArray, productsRef),
    [productsRefArray, productsRef]
  );

  useEffect(() => {
    if (shouldUpdate) {
      setProductsRef(productsRefArray);
    }
  }, [shouldUpdate, productsRefArray]);

  useEffect(() => {
    if (productsRef.length === 0) {
      return () => {};
    }

    let ignore = false;
    const fetchProductData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const res = await fetchProductFromBackend(productsRef);
        if (res.ok) {
          const products = res.data as Product[];
          const filteredProducts = products.map((product) => ({
            ...product,
            colorVariants: product.colorVariants.filter((color) => color.status),
          }));
          setHits(filteredProducts);
        } else {
          setError(res.error || new Error('Failed to fetch products'));
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An unknown error occurred'));
      } finally {
        setIsLoading(false);
      }
    };

    if (!ignore) {
      fetchProductData();
    }

    return () => {
      ignore = true;
    };
  }, [productsRef]);

  return { hits, isLoading, error };
};
