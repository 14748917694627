import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { css } from '@emotion/core';

import { RootState } from 'src/shared/store/rootReducer';
import { UspSection } from '../../home/components/UspSection';
import { cmsUspInit } from '../../cms/actions';
import { Box, breakpoints } from '../../../design-system';

export const StudentPage = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(cmsUspInit() as unknown as UnknownAction);
  }, []);
  const cmsUsp = useSelector((state: RootState) => state.cms.usp);
  const { usp, usp_main_title } = cmsUsp ?? '';
  const META_TITLE = `Remise étudiante Darjeeling - UNiDAYS`;
  const META_DESCRIPTION = `Bénéficie de -35% sur ta commande Darjeeling en t'inscrivant et en confirmant ton statut d'étudiant.e sur UNiDAYS. Plus d'infos sur notre site.`;
  (function () {
    try {
      const a = addEventListener,
        b = function () {};
      a && a('touchstart', b);
      a && a('touchmove', b);
      a && a('touchend', b);
    } catch (e) {
      // No catch error provided by Unidays
    }
  })();
  return (
    <Box>
      <Helmet>
        <title>{META_TITLE}</title>
        <meta property="og:title" content={META_TITLE} />
        <meta name="description" content={META_DESCRIPTION} />
        <meta property="og:description" content={META_DESCRIPTION} />
      </Helmet>
      <iframe
        src="https://www.myunidays.com/partners/darjeeling/embedded/online"
        css={css`
          width: 100%;
          max-width: ${breakpoints.XL}px;
          height: 770px;
          border: none;
          @media (min-width: ${breakpoints.S}px) {
            height: 700px;
          }
        `}
      />

      {Boolean(usp?.length) && <UspSection content={usp} title={usp_main_title ?? ''} />}
    </Box>
  );
};
