import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../../design-system';
import { getProductPrice } from '../utils';
import { ProductPrice } from './ProductPrice';
import { Rating } from './Rating';

type Props = {
  productName: string;
  storePrice: number;
  originalPrice: number;
  ratingAverage: string;
  ratingCount: string;
  showReviews: boolean;
  reviewsRef: React.RefObject<HTMLDivElement>;
  collectionName: string;
  typology?: string;
};

const StyledInfoPanel = styled.div`
  .product-and-price-panel__container {
    display: flex;
    gap: 8px;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .pdp-product-name {
    color: ${colors.MAIN_GREY};
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0;
  }
  .pdp-category-name {
    font-weight: bold;
    color: ${colors.BLACK};
  }
  .review-and-recyclable-panel__container {
    display: flex;
    gap: 8px;
    align-items: start;
    justify-content: space-between;
  }
`;

export const InfoPanel = ({
  productName,
  storePrice,
  originalPrice,
  ratingAverage,
  ratingCount,
  reviewsRef,
  showReviews,
  collectionName,
  typology,
}: Props) => {
  const price = getProductPrice({ storePrice, originalPrice });
  const [nameWithoutCategory, category] = productName?.split(' - ') ?? '';

  return (
    <StyledInfoPanel>
      <div className="product-and-price-panel__container">
        <h1 className="pdp-product-name" id="pdp-product-name">
          <span className="pdp-category-name">
            {(category ?? collectionName ?? 'categorie').toLocaleUpperCase()}{' '}
          </span>
          <br />
          {nameWithoutCategory ?? typology ?? 'nom du produit'}
        </h1>
        <ProductPrice price={price} />
      </div>
      <div className="review-and-recyclable-panel__container">
        {showReviews && (
          <Rating ratingAverage={ratingAverage} ratingCount={ratingCount} reviewsRef={reviewsRef} />
        )}
      </div>
    </StyledInfoPanel>
  );
};
