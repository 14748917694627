import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Box, Text, Icon, colors, ButtonSize } from '../../../design-system';
import { Size } from '../types';
import { getIsSizeAvailable, getSizeLabel } from '../utils';
import { LINK_SIZE_GUIDE, LBL_SIZE } from '../locale';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { changeSize } from '../actions';
import { closeModal, openModalNew } from '../../common/actions';
import { SizeGuide } from '../../sizeGuide';

type Props = {
  objectID: string;
  sortedSizes: Size[];
  errMsg: string;
  sizeMessage: string;
  selectedSku: string;
  setSelectedSku: (sku: string) => void;
  setHoveredSku: (sku: string) => void;
  isQuickAdd?: boolean;
  isWishlist?: boolean;
  isStickyCta?: boolean;
  setSizeBlockIsOpen?: (value: boolean) => void;
  isNoLongerAvailable: string[];
  isEresaModal?: boolean;
  isVariantTwo?: boolean;
  handleCustomSizeBlockToggle?: (value: boolean) => void;
};

const StyledSizeMessage = styled.p<{ errMsg: string; isVariantEnabled: boolean }>`
  font-size: ${(props) => (props.isVariantEnabled ? '1.2rem' : '1.4rem')};
  line-height: ${(props) => (props.isVariantEnabled ? '1.4rem' : '1.6rem')};
  color: ${(props) => (props.errMsg ? colors.ERROR : colors.GREY)};
  margin: 0;
`;

export const Sizes = ({
  objectID,
  sortedSizes,
  errMsg,
  sizeMessage,
  selectedSku,
  setHoveredSku,
  setSelectedSku,
  isQuickAdd,
  isWishlist,
  isStickyCta,
  setSizeBlockIsOpen = () => null,
  isNoLongerAvailable,
  isEresaModal,
  isVariantTwo = false,
  handleCustomSizeBlockToggle = () => null,
}: Props) => {
  const { isMobile } = useMediaQueries();
  const { sku: skuEresa } = useSelector((state: RootState) => state.product.size);
  const dispatch = useDispatch();
  const onSizeGuideClick = () => {
    if (isVariantTwo) {
      handleCustomSizeBlockToggle(false);
    }
    setSizeBlockIsOpen(false);
    dispatch(closeModal());
    dispatch(
      openModalNew({
        content: <SizeGuide isPopup />,
        preset: 'mobileFull',
      })
    );
  };
  const fragments = {
    sizeGuide: (
      <button
        type="button"
        className="anchor-animated"
        data-testid="btn-size-bar-size-guide"
        aria-label="Bouton pour ouvrir la modale de sélection de la taille"
        id="btn-size-bar-size-guide"
        onClick={onSizeGuideClick}
        css={css`
          font-size: 1.2rem;
          font-weight: 500;
          text-transform: uppercase;
        `}
      >
        {LINK_SIZE_GUIDE}
      </button>
    ),
  };

  const selectedSize = sortedSizes.find(({ sku }) => sku === selectedSku);
  const [skuEresaModal, setSkuEresaModal] = useState('');

  useEffect(() => {
    isEresaModal && setSkuEresaModal(skuEresa);
  }, []);
  return (
    <>
      {isMobile && !isStickyCta && (
        <Box
          id="size-block-mobile"
          display="grid"
          alignItems="center"
          justifyContent="left"
          gridAutoColumns="1fr"
          px={isVariantTwo ? 'm' : 'na'}
          mt={isVariantTwo ? 'm' : 'na'}
          mb={isWishlist || isVariantTwo ? 'm' : 'na'}
        >
          {isVariantTwo && (
            <Box pb="xl" display="flex" justifyContent="space-between">
              {fragments.sizeGuide}
              <button
                data-testid="size-modal-variant-two-close-button"
                aria-label="Bouton pour fermer la modale de sélection de la taille"
                css={css`
                  padding: 0;
                  background-color: unset;
                  border: none;
                  color: ${colors.BLACK};
                `}
                type="button"
                onClick={() => handleCustomSizeBlockToggle(false)}
              >
                <Icon name="close" size={16} />
              </button>
            </Box>
          )}
          {!isQuickAdd && !isWishlist && !isVariantTwo && (
            <Box
              bg="BACKGROUND"
              py="m"
              display="flex"
              mt="-16px"
              mx="-16px"
              mb="m"
              justifyContent="center"
            >
              {fragments.sizeGuide}
            </Box>
          )}
          <Box
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            gridGap="xs"
            justifyContent={isEresaModal || isVariantTwo ? 'flex-start' : 'space-between'}
          >
            <Box
              display="grid"
              gridAutoFlow="column"
              justifyContent="left"
              alignItems="center"
              gridGap="xs"
            >
              {isWishlist ? (
                <Text whiteSpace="pre-wrap" preset="subheading" fontWeight="bold" mr="s">
                  {LBL_SIZE}
                </Text>
              ) : (
                <Text whiteSpace="pre-wrap" textTransform="uppercase" fontWeight="bold">
                  {LBL_SIZE}
                </Text>
              )}
              {selectedSize && !isWishlist && !isEresaModal && (
                <Text fontWeight="bold" id={`product-modal-${objectID}-size-label`}>
                  {getSizeLabel(selectedSize)}
                </Text>
              )}
            </Box>
            <StyledSizeMessage errMsg={errMsg} isVariantEnabled={isVariantTwo}>
              {errMsg || sizeMessage}
            </StyledSizeMessage>
            {(isQuickAdd || isWishlist) && !isEresaModal && fragments.sizeGuide}
          </Box>
        </Box>
      )}
      {!isMobile && !isStickyCta && (
        <Box
          display="grid"
          alignItems="center"
          justifyContent="left"
          gridAutoFlow="column"
          gridTemplateColumns="auto 1fr auto"
          mb={isWishlist ? 'm' : 'na'}
          height="26px"
        >
          {isWishlist ? (
            <Text whiteSpace="pre-wrap" preset="subheading" fontWeight="bold" mr="s">
              {LBL_SIZE}
            </Text>
          ) : (
            <Text
              whiteSpace="pre-wrap"
              textTransform="uppercase"
              fontWeight="700"
            >{`${LBL_SIZE} `}</Text>
          )}
          <Box display={'flex'} alignItems={'center'} height="26px">
            {selectedSize && !isWishlist && (
              <Text
                id={`product-modal-${objectID}-size-label`}
                marginLeft="4px"
                color={colors.MAIN_GREY}
              >
                {getSizeLabel(selectedSize)}
              </Text>
            )}
            <p
              css={css`
                margin: 0;
                padding-left: 4px;
                color: ${errMsg ? colors.ERROR : colors.MAIN_GREY};
                font-size: 1.4rem;
              `}
              id={`product-modal-${objectID}-size-message`}
            >
              {errMsg || sizeMessage}
            </p>
          </Box>
          {!isEresaModal && fragments.sizeGuide}
        </Box>
      )}
      {isStickyCta && (
        <Box
          width="100%"
          mb={isMobile ? 'na' : 'm'}
          display="flex"
          alignItems="center"
          flexDirection="row"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box>{fragments.sizeGuide}</Box>
              <Box
                alignSelf="center"
                mr={isMobile ? 'na' : 'l'}
                onClick={() => setSizeBlockIsOpen(false)}
              >
                <Icon name="close" size={16} />
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" mt="l">
              <Box fontSize="14px">
                <span style={{ fontWeight: 400, marginRight: '4px' }}>{`${LBL_SIZE}`}</span>
                <span style={{ fontWeight: 700 }}>
                  {selectedSize ? getSizeLabel(selectedSize) : ''}
                </span>
              </Box>
              <Box color={errMsg ? 'ERROR' : 'GREY'} fontSize="12px" ml="s" alignSelf="center">
                {errMsg || sizeMessage}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="start"
        px={isVariantTwo ? 'm' : 'na'}
        overflowY={isMobile ? 'auto' : 'initial'}
        maxHeight={
          isEresaModal || isVariantTwo
            ? 'auto'
            : isMobile
              ? isQuickAdd
                ? '168px'
                : isStickyCta
                  ? '100%'
                  : '240px'
              : 'initial'
        }
        gridGap="16px"
        paddingBottom={isMobile && isQuickAdd ? '16px' : '0'}
        className="size-selector__container"
      >
        {sortedSizes.map((size, index) => {
          const { sku } = size;
          const isSelected = sku === selectedSku || (sku === skuEresaModal && isEresaModal);
          const isDisabled = !getIsSizeAvailable(size) || isNoLongerAvailable.includes(sku);
          return (
            <div
              key={isStickyCta ? index + '-sticky-cta' : index}
              id={`product-modal-${objectID}-size-${index}`}
              onMouseEnter={() => ((isMobile && !selectedSize) || !isMobile) && setHoveredSku(sku)}
              onMouseLeave={() => setHoveredSku('')}
            >
              <ButtonSize
                type="button"
                aria-label={`Bouton pour sélectionner la taille ${getSizeLabel(size)}`}
                id={`product-modal-${objectID}-size-${index}-button`}
                isSelected={isSelected ?? false}
                onClick={() => {
                  isEresaModal && setSkuEresaModal('');
                  setSelectedSku(sku);
                  isEresaModal && dispatch(changeSize(size));
                }}
                disabled={isDisabled}
                data-testid={`select-size-button-pdp-${index}`}
              >
                {getSizeLabel(size)}
              </ButtonSize>
            </div>
          );
        })}
      </Box>
    </>
  );
};
