import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { ShippingTypes } from '../../cart/types';
import { Accordion, Text, colors, Button } from '../../../design-system';
import { ProductComposition } from './ProductComposition';
import {
  TXT_DETAILS_DESCRIPTION,
  TXT_DETAILS_COMPOSITION,
  TXT_DETAILS_E_RESERVATION,
  TXT_DETAILS_DELIVERY_AND_RETURN,
  ECART_CTA_CONTENT,
} from '../locale';
import { KNOW_MORE } from '../../home/locale';
import { LBL_DELIVERY_CHOICE, LBL_FREE, LBL_RETURN_AND_EXCHANGES } from '../../cart/locale';
import { Ereservation } from '../../ecart/components/Ereservation';
import { openModal } from '../../common/actions';
import { RootState } from 'src/shared/store/rootReducer';
import { formatPrice } from '../../common/utils';
import { Product } from '../types';

type Props = {
  productDescription: string;
  cleaningAdvice: string;
  productComposition: string;
  moreDetails?: string;
  product: Product;
  isHomeMadeRecommendationsEnabled: boolean;
};
const StyledAccordion = styled(Accordion)`
  .accordion__item {
    display: grid;
    grid-gap: 16px;
    margin-bottom: 16px;
    &.accordion__item--delivery-return {
      grid-gap: 24px;
    }
  }
  .delivery-return-method__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .delivery-return-method__item:last-child {
    margin-bottom: 16px;
  }
  .accordion-item__description {
    font-size: 1.4rem;
    line-height: 150%;
    color: ${colors.MAIN_GREY};
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .delivery-return-method__description {
    font-size: 1.4rem;
    line-height: 150%;
    color: ${colors.MAIN_GREY};
    margin-block-start: 0;
    margin-block-end: 0;
    &.delivery-return-method__description--free {
      color: ${colors.MAIN_SUCCESS};
    }
  }
  .delivery-return-method__link {
    margin-top: 8px;
    font-size: 1.4rem;
  }
`;
export const Details = ({
  productDescription,
  cleaningAdvice,
  productComposition,
  moreDetails,
  product,
  isHomeMadeRecommendationsEnabled,
}: Props) => {
  const dispatch = useDispatch();
  const { reservation_text_line_one, reservation_text_line_two, reservation_text_line_three } =
    useSelector((state: RootState) => state.cms.ecart) ?? {};
  const { shippingTypes } = useSelector((state: RootState) => state.cart);
  const homeShippingPrice =
    shippingTypes?.find((item) => item.label === ShippingTypes.HOME)?.amount ?? 4.5;
  const pickUpShippingPrice =
    shippingTypes?.find((item) => item.label === ShippingTypes.PICKUP)?.amount ?? 4.5;
  const cmsContent = useSelector((state: RootState) => state.cms.cart);
  const requiredAmountHome = cmsContent?.shipping_promo_amount ?? 85;
  const requiredAmountPickUp = cmsContent?.pickup_shipping_promo_amount ?? 85;
  const { eReservation, status } = product;
  const onEreservationClick = () => {
    dispatch(
      openModal({
        content: (
          <Ereservation
            product={product}
            isHomeMadeRecommendationsEnabled={isHomeMadeRecommendationsEnabled}
          />
        ),
        preset: 'eresaModal',
      })
    );
  };
  const items = [
    {
      title: TXT_DETAILS_DESCRIPTION,
      content: (
        <div className="accordion__item">
          <Text className="accordion-item__description" whiteSpace="pre-wrap">
            {productDescription}
          </Text>
          {moreDetails && (
            <Text className="accordion-item__description" whiteSpace="pre-wrap">
              {moreDetails}
            </Text>
          )}
        </div>
      ),
      index: 0,
    },
    productComposition
      ? {
          title: TXT_DETAILS_COMPOSITION,
          content: (
            <div className="accordion__item">
              <ProductComposition
                cleaningAdvice={cleaningAdvice}
                productComposition={productComposition}
              />
            </div>
          ),
          index: 1,
        }
      : { title: '', content: '', index: 0 },
    {
      title: TXT_DETAILS_DELIVERY_AND_RETURN,
      content: (
        <div className="accordion__item accordion__item--delivery-return">
          <div className="delivery-return-method__list">
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">{LBL_DELIVERY_CHOICE[0]}</p>
              <p className="delivery-return-method__description delivery-return-method__description--free">
                {LBL_FREE}
              </p>
            </div>
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">
                {`${LBL_DELIVERY_CHOICE[1]} `}
                {requiredAmountHome !== 0 && homeShippingPrice > 0 ? (
                  <span className="delivery-return-method__description delivery-return-method__description--free">
                    {`(gratuite à partir de ${requiredAmountHome}€)`}
                  </span>
                ) : (
                  <span className="delivery-return-method__description">
                    {`(sans minimum d'achat)`}
                  </span>
                )}
              </p>
              <p className="delivery-return-method__description">
                {formatPrice(homeShippingPrice)}
              </p>
            </div>
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">
                {`${LBL_DELIVERY_CHOICE[2]} `}
                {requiredAmountPickUp !== 0 && pickUpShippingPrice > 0 ? (
                  <span className="delivery-return-method__description delivery-return-method__description--free">
                    {`(gratuite à partir de ${requiredAmountPickUp}€)`}
                  </span>
                ) : (
                  <span className="delivery-return-method__description">
                    {`(sans minimum d'achat)`}
                  </span>
                )}
              </p>
              <p className="delivery-return-method__description">
                {formatPrice(pickUpShippingPrice)}
              </p>
            </div>
            <Link
              className="anchor-animated"
              data-testid="delivery-return-method__link"
              id="delivery-return-method__link"
              to="https://faq.darjeeling.fr/hc/fr/sections/16814101533596-Exp%C3%A9ditions-Livraisons"
            >
              <p className="delivery-return-method__link">{KNOW_MORE}</p>
            </Link>
          </div>
          <div className="delivery-return-method__list">
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">{LBL_RETURN_AND_EXCHANGES[0]}</p>
              <p className="delivery-return-method__description">30 jours</p>
            </div>
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">{LBL_RETURN_AND_EXCHANGES[1]}</p>
              <p className="delivery-return-method__description delivery-return-method__description--free">
                {LBL_FREE}
              </p>
            </div>
            <div className="delivery-return-method__item">
              <p className="delivery-return-method__description">{LBL_RETURN_AND_EXCHANGES[2]}</p>
            </div>
            <Link
              className="anchor-animated"
              data-testid="delivery-return-method__link"
              id="delivery-return-method__link"
              to="https://faq.darjeeling.fr/hc/fr/sections/16814073258268-Retours-Echanges"
            >
              <p className="delivery-return-method__link">{KNOW_MORE}</p>
            </Link>
          </div>
        </div>
      ),
      index: productComposition ? 2 : 1,
    },
    eReservation
      ? {
          title: TXT_DETAILS_E_RESERVATION,
          content: (
            <div className="accordion__item">
              <div className="accordion-item__description">
                {reservation_text_line_one}
                {reservation_text_line_two && (
                  <>
                    <br />
                    {reservation_text_line_two}
                  </>
                )}
                {reservation_text_line_three && (
                  <>
                    <br />
                    {reservation_text_line_three}
                  </>
                )}
              </div>
              <Button
                id="btn-add-to-ecart"
                preset="eresa"
                onClick={onEreservationClick}
                disabled={!status}
              >
                {ECART_CTA_CONTENT?.[0]}
              </Button>
            </div>
          ),
          index: productComposition ? 3 : 2,
        }
      : { title: '', content: '', index: 0 },
  ];
  return (
    <StyledAccordion
      id="accordion-pdp__container"
      items={items}
      iconToOpen="plus"
      iconToClose="minus"
      separateLineColor="BLACK"
      iconSize={14}
    />
  );
};
