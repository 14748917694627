import styled from '@emotion/styled';
import { variant } from 'styled-system';
import css from '@styled-system/css';

import Box from '../box';
import { Props as BoxProps } from '../box/Box';
import { opacities } from '../systemprovider';

type Preset =
  | 'bannerLarge'
  | 'bannerMedium'
  | 'bannerSmall'
  | 'heading'
  | 'subheading'
  | 'body'
  | 'caption'
  | 'promoBanner'
  | 'promoBannerMobile'
  | 'subheadingPromoBanner'
  | 'desktopFeedbackModal'
  | 'darkTheme'
  | 'pdpSubHeading';

type Color = 'BLACK' | 'WHITE' | 'GREY' | 'ERROR' | 'SUCCESS' | 'MAIN_SUCCESS' | 'PROMO';

type Props = {
  preset?: Preset | Preset[];
  color?: string | Color | Color[];
  itemProp?: string;
  withEllipsis?: boolean;
  disabled?: boolean;
  overflowWrap?: string;
} & BoxProps;

const Text = styled(Box)<Props>(
  css({
    color: 'BLACK',
    fontSize: 's',
    lineHeight: 's',
  }),
  variant({
    scale: 'textStyles',
    prop: 'preset',
    variants: {
      body: {
        fontSize: 's',
        lineHeight: 's',
      },
      bannerLarge: {
        fontSize: 'xxxl',
        lineHeight: 'xxxl',
      },
      bannerMedium: {
        fontSize: 'xxl',
        lineHeight: 'xxl',
      },
      bannerSmall: {
        fontSize: 'xl',
        lineHeight: 'xl',
      },
      heading: {
        fontSize: 'l',
        lineHeight: 'l',
      },
      subheading: {
        fontSize: 'm',
        lineHeight: 'm',
      },
      caption: {
        fontSize: 'xs',
        lineHeight: 'xs',
      },
      promoBanner: {
        fontSize: '50px',
        lineHeight: '55px',
        fontWeight: 700,
      },
      promoBannerMobile: {
        fontSize: '30px',
        lineHeight: '33px',
        fontWeight: 700,
      },
      subheadingPromoBanner: {
        fontSize: 'm',
        lineHeight: 'm',
        fontWeight: 700,
      },
      desktopFeedbackModal: {
        fontSize: '12px',
        fontWeight: 700,
        color: '#5A5A5A',
        lineHeight: '14px',
        fontFamily: 'MaisonNeue',
      },
      darkTheme: {
        color: 'white',
        fontSize: '12px',
        fontWeight: 400,
      },
      pdpSubHeading: {
        fontSize: 's',
        lineHeight: 's',
        fontWeight: 700,
      },
    },
  }),
  ({ color }) =>
    color &&
    css({
      color: color,
      borderColor: color,
    }),
  ({ withEllipsis }) =>
    withEllipsis && {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  // @ts-ignore
  ({ overflowWrap }) =>
    overflowWrap && {
      overflowWrap: overflowWrap,
    },
  ({ disabled }) =>
    disabled && {
      opacity: opacities.DISABLED,
    },
  ({ onClick }) =>
    typeof onClick === 'function' && {
      cursor: 'pointer',
    }
);

export default Text;
