import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Button, Icon, breakpoints, colors } from '../../../design-system';
import { Radio } from '../../../design-system/radiogroup/components/Radio';
import { ShippingTypes } from '../../cart/types';
import Address from './Address';
import Pickup from '../../pickup';
import { StoreDelivery } from '../../store-locator';
import { FormFieldCallback, Forms } from '../../form/types';
import { Address as AddressType } from '../../account/types';
import { SHIPPING_AMOUNT_FREE } from '../../cart/locale';
import { RootState } from '../../../store/rootReducer';
import {
  ADD_NEW_ADDRESS,
  HOME_DELIVERY_INFO,
  LBL_COLISSIMO,
  LBL_GET_ESTIMATION_DATE,
  LBL_NO_ADDRESS_RECORDED,
  STORE_SEARCH_TITLE,
} from '../locale';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { formatPrice } from '../../common/utils';
import { durations } from '../../common/constants';
import { opacities } from '../../../design-system';
import { AddressCard } from './AddressCard';
import { deliveryMethodsDesktopWidth, calculateDeliveryDates } from '../utils';

type Props = {
  addressOptionsForShipping: { value: string; label: string }[];
  formErrorType: Forms | null;
  onRadioItemClick: (item: ShippingTypes, index: number) => void;
  onFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  onFieldChange: ({ form, key, value }: FormFieldCallback) => void;
  addingAddressType: Forms | null;
  setAddingAddressType: (value: Forms | null) => void;
  editAddressType: Forms | null;
  setEditAddressType: (value: Forms | null) => void;
  handleClickSave: (newAddress: AddressType) => void;
  shippingSelectorRef: React.RefObject<HTMLDivElement>;
  billingSelectorRef: React.RefObject<HTMLDivElement>;
  shippingAddressId: string;
  billingAddressId: string;
  onAddressSelectShipping: (value: string) => void;
  onShowBillingClick: () => void;
  item: { id: ShippingTypes; value: string | undefined; amount: number };
  index: number;
  isError: boolean;
  isLoading: boolean;
  isShippingTypeLoading: boolean | undefined;
  deliveryBlockRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
  isMapBoxAddressSuggestionsEnabled: boolean;
};

export const DeliveryMethodItems = ({
  addressOptionsForShipping,
  formErrorType,
  onRadioItemClick,
  onFieldBlur,
  onFieldChange,
  addingAddressType,
  setAddingAddressType,
  editAddressType,
  setEditAddressType,
  handleClickSave,
  shippingSelectorRef,
  billingSelectorRef,
  shippingAddressId,
  billingAddressId,
  onAddressSelectShipping,
  onShowBillingClick,
  item,
  index,
  isError,
  isLoading,
  isShippingTypeLoading,
  deliveryBlockRefs,
  isMapBoxAddressSuggestionsEnabled,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  deliveryBlockRefs.current[index] = ref;
  const { cart } = useSelector((state: RootState) => state.cart);
  const { shippingType, storeId, freeShipping } = cart ?? {};
  const {
    home_delivery_min_day,
    home_delivery_max_day,
    store_delivery_min_day,
    store_delivery_max_day,
    pickup_delivery_min_day,
    pickup_delivery_max_day,
  } = useSelector((state: RootState) => state.cms.delivery) ?? {};

  const { shipping, delivery } = useSelector((state: RootState) => state.form);
  const { showBilling } = delivery.values;
  const addresses = useSelector((state: RootState) => state.account.user?.addresses) ?? [];
  const shippingAddress = addresses.filter((address) => address.country === 'FR');
  const shippingBlockRef = React.useRef<HTMLDivElement>(null);
  const isFreeShipping = item.id === ShippingTypes.STORE || freeShipping;
  const { dateStart, dateEnd } = calculateDeliveryDates(
    item.id,
    home_delivery_min_day,
    home_delivery_max_day,
    store_delivery_min_day,
    store_delivery_max_day,
    pickup_delivery_min_day,
    pickup_delivery_max_day
  );
  const isChecked = item.id === shippingType;
  return (
    <>
      <div
        ref={ref}
        key={`cart-delivery-method-${item.id}-${index}`}
        id={`cart-delivery-method-${item.id}-${index}`}
        onClick={() =>
          !isChecked && !isShippingTypeLoading ? onRadioItemClick(item.id, index) : null
        }
        css={css`
          scroll-margin-top: 8px;
          background-color: ${colors.WHITE};
          margin-bottom: 8px;
          padding: 16px 16px;
          text-align: left;
          min-height: 70px;
          position: relative;
          border: ${isChecked && !isError
            ? `1px solid ${colors.BLACK}`
            : isChecked && isError
              ? `1px solid ${colors.ERROR}`
              : `1px solid ${colors.LIGHT}`};
          transition: ${isError && isChecked
            ? 'none'
            : `border ${durations.ENTER}ms ease-in-out, opacity ${durations.ENTER}ms ease-in-out`};
          cursor: ${isChecked || isShippingTypeLoading ? 'initial' : 'pointer'};
          @media (hover: hover) {
            :hover {
              opacity: ${isChecked || isShippingTypeLoading ? 1 : opacities.HOVERED};
            }
          }
          @media (max-width: ${breakpoints.S - 1}px) {
            max-width: calc(100vw - 32px);
          }
          @media (min-width: ${breakpoints.S}px) {
            margin-bottom: 24px;
            scroll-margin-top: 24px;
          }
          @media (min-width: ${breakpoints.L}px) {
            width: ${deliveryMethodsDesktopWidth}px;
          }
        `}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <Radio
                id={`cart-delivery-${item.id}-radio-button-${index}`}
                checked={isChecked}
                disabled={isShippingTypeLoading}
                isCartDeliveryMethod
              >
                <p
                  css={css`
                    font-size: 1.6rem;
                    margin: 0;
                  `}
                >
                  {item.value}
                </p>
                <p
                  css={css`
                    font-size: 1.2rem;
                    line-height: 110%;
                    color: ${colors.MAIN_GREY};
                    margin: 8px 0 0 0;
                  `}
                >
                  {LBL_GET_ESTIMATION_DATE(dateStart, dateEnd)}
                </p>
              </Radio>
              <div
                css={css`
                  font-size: 1.6rem;
                  line-height: 110%;
                  align-self: center;
                  color: ${isFreeShipping ? colors.MAIN_SUCCESS : colors.BLACK};
                `}
              >
                {isFreeShipping ? SHIPPING_AMOUNT_FREE : formatPrice(item.amount)}
              </div>
            </div>
            {item.id === ShippingTypes.HOME && (
              <div
                css={css`
                  margin: 8px 0 0 16px;
                  padding: 4px 8px;
                  background-color: ${colors.BACKGROUND};
                  font-size: 1.2rem;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  width: max-content;
                `}
              >
                <div
                  css={css`
                    margin-right: 8px;
                  `}
                >
                  {HOME_DELIVERY_INFO}
                </div>
                <Icon name="mapMarkerColissimo" size={14} />
                <div
                  css={css`
                    margin-left: 4px;
                    font-weight: 700;
                  `}
                >
                  {LBL_COLISSIMO}
                </div>
              </div>
            )}
            {item.id === ShippingTypes.STORE && isChecked ? (
              <>
                {!storeId && (
                  <div
                    css={css`
                      margin: 16px 0 8px 0;
                      font-size: 1.4rem;
                      line-height: 110%;
                      color: ${colors.MAIN_GREY};
                    `}
                  >
                    {STORE_SEARCH_TITLE}
                  </div>
                )}
                <div
                  css={css`
                    margin-top: ${storeId ? '16px' : 0};
                  `}
                >
                  <StoreDelivery />
                </div>
              </>
            ) : item.id === ShippingTypes.PICKUP && isChecked ? (
              <div
                css={css`
                  margin-top: 16px;
                  @media (max-width: ${breakpoints.S - 1}px) {
                    width: calc(100vw - 64px);
                  }
                `}
              >
                <Pickup form={delivery} onFieldBlur={onFieldBlur} onFieldChange={onFieldChange} />
              </div>
            ) : item.id === ShippingTypes.HOME && isChecked ? (
              <>
                {addingAddressType === Forms.shipping || editAddressType === Forms.shipping ? (
                  <div
                    ref={shippingBlockRef}
                    css={css`
                      scroll-margin-top: 16px;
                    `}
                  >
                    <Address
                      type={Forms.shipping}
                      onFieldBlur={onFieldBlur}
                      onFieldChange={onFieldChange}
                      form={shipping}
                      onSave={handleClickSave}
                      editAddressType={editAddressType}
                      setAddingAddressType={setAddingAddressType}
                      setEditAddressType={setEditAddressType}
                      shippingType={shippingType}
                      shippingSelectorRef={shippingSelectorRef}
                      billingSelectorRef={billingSelectorRef}
                      billingAddressId={billingAddressId}
                      shippingAddressId={shippingAddressId}
                      formErrorType={formErrorType}
                      isMapBoxAddressSuggestionsEnabled={isMapBoxAddressSuggestionsEnabled}
                    />
                  </div>
                ) : (!addingAddressType || addingAddressType === Forms.billing) &&
                  (!editAddressType || editAddressType === Forms.billing) &&
                  shippingAddress.length > 0 ? (
                  <AddressCard
                    addressOptions={addressOptionsForShipping}
                    form={shipping}
                    type={Forms.shipping}
                    onChange={onAddressSelectShipping}
                    setEditAddressType={setEditAddressType}
                    showBilling={showBilling}
                    onShowBillingClick={onShowBillingClick}
                  />
                ) : null}
                {addingAddressType !== Forms.shipping && editAddressType !== Forms.shipping && (
                  <>
                    {!shippingAddress.length && !addingAddressType && !editAddressType && (
                      <div
                        css={css`
                          font-size: 1.2rem;
                          line-height: 110%;
                          margin-top: 16px;
                          color: ${formErrorType === Forms.shipping ? colors.ERROR : colors.BLACK};
                        `}
                      >
                        {LBL_NO_ADDRESS_RECORDED}
                      </div>
                    )}
                    <StyledButton
                      id="add-new-address-btn"
                      type="button"
                      onClick={() => {
                        if (showBilling && addresses.length === 1) {
                          onShowBillingClick();
                        }
                        setAddingAddressType(Forms.shipping);
                      }}
                    >
                      {ADD_NEW_ADDRESS}
                    </StyledButton>
                  </>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export const StyledButton = styled(Button)(
  css({
    padding: '0 40px',
    borderRadius: '8px',
    backgroundColor: 'inherit',
    border: `solid 1px ${colors.BLACK}`,
    color: colors.BLACK,
    fontSize: '1.6rem',
    marginTop: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    height: '44px',
    transition: `opacity ${durations.ENTER}ms ease-in-out`,
    '@media(hover: hover)': {
      ':hover': {
        opacity: opacities.HOVERED,
      },
    },
    [`@media(max-width: ${breakpoints.S - 1}px)`]: {
      width: '100%',
    },
  })
);
