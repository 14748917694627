import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import { css } from '@emotion/core';

import { Box, Text, Button } from '../../../design-system';
import { Address as AddressType } from '../types';
import { RootState } from '../../../store/rootReducer';
import AddressContainer from './Address';
import { loadUserDetails, updateAddresses } from '../actions';
import { FormAddress } from '../../form/types';
import { scrollToTop } from '../../common/utils';
import { ADD_NEW_ADDRESS } from '../../shipping/locale';

type Props = {
  addresses: AddressType[];
  form: FormAddress;
  updateAddresses: (addresses: AddressType[]) => void;
};

export const Addresses = ({ addresses, form, updateAddresses }: Props) => {
  const dispatch = useDispatch();
  const emptyAddress: AddressType = {
    id: '',
    name: '',
    company: '',
    siret: '',
    idTva: '',
    firstName: '',
    lastName: '',
    street: '',
    streetAdditional: '',
    postal: '',
    city: '',
    phone: '',
    country: 'FR',
  };
  const [address, setAddress] = useState(emptyAddress);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const formRef = useRef<HTMLDivElement>(null);

  const handleClickDelete = (id: string) => {
    updateAddresses(addresses.filter((address) => address.id !== id));
  };

  const handleClickEdit = (id: string) => {
    setIsFormOpen(true);
    const address = addresses.find((address) => address.id === id);
    if (address) {
      setAddress(address);
    }
    const node = formRef.current;
    if (node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickNew = () => {
    setIsFormOpen(true);
  };

  const handleClickSave = (newAddress: AddressType) => {
    if (newAddress.id) {
      updateAddresses(
        addresses.map((address) => (address.id === newAddress.id ? newAddress : address))
      );
    } else {
      updateAddresses([...addresses, newAddress]);
    }

    scrollToTop();
  };

  useEffect(() => {
    if (form.feedback.ok) {
      setAddress(emptyAddress);
      setIsFormOpen(false);
    }
  }, [form.feedback.ok]);

  useEffect(() => {
    dispatch(loadUserDetails() as unknown as UnknownAction);
  }, []);

  return (
    <Box display="grid" gridTemplateAreas="'message' 'title' 'addresses' 'form'" gridGap="xl">
      <Box gridArea="title">
        <Text preset="heading">Vos adresses</Text>
      </Box>
      <Box gridArea="message" textAlign="center">
        {form.feedback.message && (
          <Text
            color={form.feedback.ok ? 'MAIN_SUCCESS' : 'ERROR'}
            id={`account-address-form-feedback-${form.feedback.ok ? 'success' : 'error'}`}
          >
            {form.feedback.message}
          </Text>
        )}
      </Box>
      <Box
        gridArea="addresses"
        display="grid"
        gridGap="xxl"
        gridTemplateColumns={['auto', 'repeat(auto-fill, minmax(176px, 1fr))']}
      >
        {addresses.length > 0 ? (
          addresses.map((address) => (
            <div key={address.id}>
              <Box mb="s">
                <Text preset="subheading" wordBreak="break-word">
                  {address.name}
                </Text>
              </Box>
              <Text wordBreak="break-word">{address.company}</Text>
              <Text wordBreak="break-word">
                {address.lastName} {address.firstName}
              </Text>
              <Text wordBreak="break-word">{address.street}</Text>
              <Text wordBreak="break-word">{address.streetAdditional}</Text>
              <Text wordBreak="break-word">
                {address.postal} {address.city}
              </Text>
              <Text wordBreak="break-word">{address.country}</Text>
              <Box
                display="grid"
                gridAutoFlow="column"
                gridGap="l"
                gridAutoColumns="max-content"
                my="m"
              >
                {['Supprimer', 'Éditer'].map((item) => {
                  const isDeletion = item === 'Supprimer';
                  return (
                    <button
                      key={item}
                      className="anchor-animated"
                      data-testid={`account-address-${isDeletion ? 'delete' : 'edit'}-btn`}
                      aria-label={`Bouton pour ${item.toLocaleLowerCase()} cette adresse`}
                      id={`account-address-${isDeletion ? 'delete' : 'edit'}-btn`}
                      type="button"
                      onClick={() =>
                        isDeletion ? handleClickDelete(address.id) : handleClickEdit(address.id)
                      }
                      css={css`
                        font-size: 1.4rem;
                      `}
                    >
                      {item}
                    </button>
                  );
                })}
              </Box>
            </div>
          ))
        ) : isFormOpen ? null : (
          <Text whiteSpace="nowrap">Vous n’avez pas enregistré d’adresse.</Text>
        )}
      </Box>
      <Box gridArea="form" ref={formRef}>
        {isFormOpen ? (
          <AddressContainer onSave={handleClickSave} address={address} />
        ) : (
          <Box width={['100%', '256px']} borderRadius={8}>
            <Button id="btn-new-address" onClick={handleClickNew}>
              {ADD_NEW_ADDRESS}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    form: state.form.address,
    addresses: state.account.user?.addresses ?? [],
  }),
  { updateAddresses }
)(Addresses);
