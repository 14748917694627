import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { getBFFData, Queries, getReviews } from '../api';
import types from './actionTypes';
import { Product, Size } from './types';
import { cmsProductInit } from '../cms/actions';
import { Reviews } from '../reviews/types';

export const requestProduct = () => action(types.REQUEST_PRODUCT_LOADING, null);
export const requestProductSuccess = (payload: Product) =>
  action(types.REQUEST_PRODUCT_SUCCESS, payload);

export const requestProductSuccessModal = (payload: Product) =>
  action(types.REQUEST_PRODUCT_MODAL, payload);

export const changeSizeModal = (payload: Size) => action(types.CHANGE_SIZE_MODAL, payload);

export const requestProductFailure = (payload: string) =>
  action(types.REQUEST_PRODUCT_FAILURE, payload);

export const requestReviews = () => action(types.REQUEST_REVIEWS_LOADING, null);
export const requestReviewsSuccess = (payload: Reviews) =>
  action(types.REQUEST_REVIEWS_SUCCESS, payload);
export const requestReviewsFailure = (payload: string) =>
  action(types.REQUEST_REVIEWS_FAILURE, payload);

export const changeSize = (payload: Size) => action(types.CHANGE_SIZE, payload);
export const setSizeErrMsg = (payload: string) => action(types.SET_SIZE_ERR_MSG, payload);

type AsyncProductActions = ActionType<
  | typeof requestProduct
  | typeof requestProductSuccess
  | typeof requestProductFailure
  | typeof requestProductSuccessModal
>;

type AsyncReviewsActions = ActionType<
  typeof requestReviews | typeof requestReviewsSuccess | typeof requestReviewsFailure
>;

export type ProductActions =
  | AsyncProductActions
  | ActionType<
      | typeof changeSize
      | typeof setSizeErrMsg
      | typeof changeSizeModal
      | typeof setCrossSellLoading
      | typeof setUpSellLoading
    >
  | AsyncReviewsActions;

export type LoadProductParams = {
  productRef: string;
  colorRef: string;
};

export function loadProduct({ productRef, colorRef }: LoadProductParams) {
  return async (dispatch: Dispatch<ProductActions>) => {
    dispatch(requestProduct());
    const response = await getBFFData(Queries.getProduct, { productRef, colorRef });
    if (response.ok) {
      const product = response.data.product ?? {};
      dispatch(requestProductSuccess(product));
    } else {
      dispatch(requestProductFailure(response.data));
    }
  };
}

export function loadReviews(productCode: string) {
  return async (dispatch) => {
    dispatch(requestReviews());
    const response = await getReviews(productCode);
    if (response.product_code) {
      dispatch(requestReviewsSuccess(response));
    } else {
      dispatch(requestReviewsFailure(response));
    }
  };
}

export function productInit({ productRef, colorRef }: LoadProductParams) {
  return async (dispatch) => {
    await Promise.all([
      dispatch(loadProduct({ productRef, colorRef })),
      dispatch(cmsProductInit()),
      dispatch(loadReviews(productRef)),
    ]);
  };
}

export const setCrossSellLoading = (payload: boolean) =>
  action(types.SET_CROSS_SELL_LOADING, payload);

export const setUpSellLoading = (payload: boolean) => action(types.SET_UP_SELL_LOADING, payload);
