import styled from '@emotion/styled';
import { breakpoints, colors } from '../systemprovider';
import { ComponentPropsWithRef } from 'react';

type StyledSelectProps = {
  hasError?: boolean;
  mini?: boolean;
  isEresaModal?: boolean;
};

export const StyledSelect = styled.select<ComponentPropsWithRef<'select'> & StyledSelectProps>`
  color: ${colors.BLACK};
  appearance: none;
  background-color: ${colors.BACKGROUND};
  border-radius: 0;
  font-size: 1.6rem;
  padding-left: 8px;
  outline: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23767676%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23767676%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: none;
  height: 50px;
  width: 100%;
  @media (min-width: ${breakpoints.S}px) {
    font-size: 1.4rem;
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${colors.LIGHT2};
    }
  }
  &:focus-visible {
    border-bottom: 2px solid ${colors.LIGHT};
  }

  ${(props) =>
    props.mini &&
    `
      width: 64px;
      height: 32px;
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.LIGHT2};
    `}

  ${(props) =>
    props.isEresaModal &&
    `
      width: 100%;
      height: 31px;
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.LIGHT};
      @media (min-width: ${breakpoints.S}px) {
        width: 218px;
      }
    `}

  ${(props) =>
    props.hasError &&
    `
      border: 2px solid ${colors.ERROR};
    `}
`;

export const StyledDiv = styled.div`
  padding-top: 4px;
  height: 8px;
  .additional-note__container {
    display: flex;
    gap: 4px;
    align-items: center;
    color: ${colors.GREY};
  }
`;
