import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { Icon, breakpoints, colors, durations, opacities } from '../../../design-system';
import { CmsHomepageCommitmentsBannerSlice } from '../../cms/types';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  id?: string;
  items?: CmsHomepageCommitmentsBannerSlice['items'];
  title?: string;
  isHomepage?: boolean;
};

type StyledCommitmentsBannerProps = {
  isHomepage: boolean;
};
const StyledCommitmentsBanner = styled.div<StyledCommitmentsBannerProps>`
  overflow: hidden;
  padding: ${(props) => (props.isHomepage ? '0 0 0 16px' : '0 0 24px 0')};
  @media (min-width: ${breakpoints.S}px) {
    padding: ${(props) => (props.isHomepage ? '0' : '24px 16px')};
  }
  @media (min-width: ${breakpoints.M}px) {
    margin-top: 56px;
    padding: ${(props) => (props.isHomepage ? '0' : '24px 0')};
  }

  @media (min-width: ${breakpoints.XL}px) {
    padding: ${(props) => (props.isHomepage ? '0' : '24px 0')};
  }
  .commitments__title {
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: 'Ogg-text';
    text-align: center;
    margin: ${(props) => (props.isHomepage ? '0 16px 16px 0' : '0 0 16px 0')};
    @media (min-width: ${breakpoints.S}px) {
      margin: 0 0 24px 0;
    }
    @media (min-width: ${breakpoints.M}px) {
      font-size: 3rem;
      line-height: 3.4rem;
    }
  }

  .commitments-items__container {
    display: flex;
    gap: 16px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    padding-right: 16px;
    padding-bottom: 8px;
    width: ${(props) => (props.isHomepage ? 'unset' : 'calc(100% + 16px)')};
    @media (min-width: ${breakpoints.L}px) {
      scroll-snap-type: unset;
      -webkit-overflow-scrolling: unset;
      overflow-x: unset;
      justify-content: center;
      padding-right: 0;
      width: unset;
    }
  }
  .commitment__item {
    position: relative;
    scroll-snap-align: start;
    flex: 0 0 66vw;
    @media (min-width: ${breakpoints.S}px) {
      flex: 0 0 394px;
    }
    @media (min-width: ${breakpoints.L}px) {
      scroll-snap-align: unset;
      flex: unset;
    }
  }
  .commitment__link {
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
  .commitment__visual {
    max-width: 100%;
    filter: brightness(0.65);
    border-radius: 8px;
  }
  .commitment-description__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 16px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    @media (min-width: ${breakpoints.S}px) {
      padding: 16px 24px;
    }
    @media (min-width: ${breakpoints.L}px) {
      padding: 16px 48px;
    }
  }
  .commitment-description__pictogram {
    max-width: 100%;
    width: 32px;
    height: 32px;
  }
  .commitment--description {
    color: ${colors.WHITE};
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    font-weight: 700;
    margin: 0;
  }
`;

export const CommitmentsBanner = ({ id, items, title, isHomepage = false }: Props) => {
  return !items?.length ? null : (
    <StyledCommitmentsBanner id={id} isHomepage={isHomepage}>
      <h2 className="commitments__title">{title}</h2>
      <div data-carousel id="commitments__carousel">
        <div
          className="commitments-items__container"
          id="commitments-items__container"
          data-carousel-slides
        >
          {items?.map((item, index) => {
            const content = (
              <>
                {item.image1 && (
                  <img
                    src={item.image1.url ?? ''}
                    alt={item.image1.alt ?? ''}
                    className="commitment__visual"
                  />
                )}
                {(item.icon || item.description) && (
                  <div className="commitment-description__container">
                    {item.icon && (
                      <Icon
                        name={item.icon}
                        className="commitment-description__pictogram"
                        color={colors.WHITE}
                      />
                    )}
                    {item.description && (
                      <p className="commitment--description">{item.description}</p>
                    )}
                  </div>
                )}
              </>
            );
            if (item.link) {
              return (
                <Link
                  id={`slide-${index + 1}`}
                  to={getFormatedLinkForReactRouterLink(item.link)}
                  className="commitment__item commitment__link"
                  key={`commitments_banner_item${index}`}
                  data-testid={`commitment-banner-item-${index + 1}-${
                    isHomepage ? 'home' : 'product'
                  }-page-link-to-${item.link}`}
                  data-carousel-item
                >
                  {content}
                </Link>
              );
            }
            return (
              <div
                id={`slide-${index + 1}`}
                className="commitment__item"
                key={`commitments_banner_item${index}`}
                data-carousel-item
              >
                {content}
              </div>
            );
          })}
        </div>
      </div>
    </StyledCommitmentsBanner>
  );
};
