import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';

import { breakpoints } from '../../../design-system';
import { colors, theme } from '../../../design-system/systemprovider/constants';
import paths from '../../routing/paths';
import { isItGiftCard, getProductIdsFromUrl } from '../../product/utils';
import { MSG_PHOTOS_RETOUCHED } from '../locale';

const LegalLinks = () => {
  const { pathname } = useLocation();
  const needAdditionalMarginForStickyCta =
    pathname.startsWith(paths.PRODUCT) && !isItGiftCard(getProductIdsFromUrl(pathname).productRef);
  const currentYear = new Date().getFullYear();
  return (
    <div
      css={css`
        padding-top: 16px;
        padding-bottom: 16px;
        @media (min-width: ${breakpoints.S - 1}px) {
          padding-top: 20px;
          padding-bottom: ${needAdditionalMarginForStickyCta ? '100px' : '20px'};
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: ${breakpoints.S - 1}px) {
            justify-content: space-between;
          }
        `}
      >
        <div
          css={css`
            display: none;
            @media (min-width: ${breakpoints.S - 1}px) {
              display: flex;
              gap: 40px;
            }
            > a {
              text-decoration: none;
              color: ${colors.MAIN_GREY};
              font-size: ${theme.fontSizes.xs};
              @media (hover: hover) {
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          `}
        >
          <Link
            className="anchor-animated"
            data-testid="mobile-link-to-cgv"
            to="/cgv"
            aria-label="Lien pour accéder aux conditions générales de vente"
          >
            CGV
          </Link>
          <Link
            className="anchor-animated"
            data-testid="mobile-link-to-legal-notices"
            to="/mentions-legales"
            aria-label="Lien pour accéder aux mentions légales"
          >
            Mentions légales
          </Link>
          <Link
            className="anchor-animated"
            data-testid="mobile-link-to-privacy-policy"
            to="/politique-de-confidentialite"
            aria-label="Lien pour accéder à la politique de confidentialité"
          >
            Politique de confidentialité
          </Link>
          <Link
            className="anchor-animated"
            data-testid="mobile-link-to-cookies-policy"
            to="/politique-de-cookies"
            aria-label="Lien pour accéder à la politique de cookies"
          >
            Politique de cookies
          </Link>
        </div>
        <div
          css={css`
            text-align: center;
            @media (min-width: ${breakpoints.S - 1}px) {
              text-align: right;
            }
            > p {
              line-height: 150%;
              margin: 0;
            }
          `}
        >
          <p
            css={css`
              font-size: ${theme.fontSizes.xs};
              color: ${colors.MAIN_GREY};
            `}
          >
            DARJEELING © {currentYear}
          </p>
          <p
            css={css`
              font-size: 10px;
              color: ${colors.GREY2};
              font-style: italic;
            `}
          >
            {MSG_PHOTOS_RETOUCHED}
          </p>
        </div>
      </div>
    </div>
  );
};

export { LegalLinks };
