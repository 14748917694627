export default {
  REQUEST_PRODUCT_LOADING: 'PRODUCT / REQUEST PRODUCT / LOADING',
  REQUEST_PRODUCT_SUCCESS: 'PRODUCT / REQUEST PRODUCT / SUCCESS',
  REQUEST_PRODUCT_MODAL: ' PRODUCT / REQUEST PRODUCT / MODAL',
  REQUEST_PRODUCT_FAILURE: 'PRODUCT / REQUEST PRODUCT / FAILURE',
  REQUEST_REVIEWS_LOADING: 'PRODUCT / REQUEST REVIEWS / LOADING',
  REQUEST_REVIEWS_SUCCESS: 'PRODUCT / REQUEST REVIEWS / SUCCESS',
  REQUEST_REVIEWS_FAILURE: 'PRODUCT / REQUEST REVIEWS / FAILURE',
  CHANGE_SIZE: 'PRODUCT / CHANGE SIZE',
  CHANGE_SIZE_MODAL: 'PRODUCT / CHANGE SIZE MODAL',
  SET_SIZE_ERR_MSG: 'PRODUCT / SET SIZE ERR MSG',
  SET_CROSS_SELL_LOADING: 'PRODUCT / SET_CROSS_SELL_LOADING',
  SET_UP_SELL_LOADING: 'PRODUCT / SET_UP_SELL_LOADING',
} as const;
