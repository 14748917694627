export enum Queries {
  getIsRegistered = 'getIsRegistered',
  getOrder = 'getOrder',
  getCart = 'getCart',
  getOrders = 'getOrders',
  getProduct = 'getProduct',
  getUser = 'getUser',
  getUserDetails = 'getUserDetails',
  getFbUsername = 'getFbUsername',
  getGoogleUsername = 'getGoogleUsername',
  getAppleUsername = 'getAppleUsername',
  getColissimoToken = 'getColissimoToken',
  getPickupStations = 'getPickupStations',
  getStoresByText = 'getStoresByText',
  getStoresByPosition = 'getStoresByPosition',
}

export enum Mutations {
  addCoupon = 'addCoupon',
  addShippingType = 'addShippingType',
  addToCart = 'addToCart',
  addGiftCard = 'addGiftCard',
  removeGiftCard = 'removeGiftCard',
  updateGiftCard = 'updateGiftCard',
  addToECart = 'addToECart',
  deleteCoupon = 'deleteCoupon',
  eReservation = 'eReservation',
  initEreservation = 'initEreservation',
  removeItem = 'removeItem',
  bulkRemove = 'bulkRemove',
  bulkECartRemove = 'bulkECartRemove',
  updateQuantity = 'updateQuantity',
  mergeCart = 'mergeCart',
  updateUser = 'updateUser',
  setCheckoutAddresses = 'setCheckoutAddresses',
  setCheckoutStore = 'setCheckoutStore',
  setCheckoutPickupStation = 'setCheckoutPickupStation',
  subscribeNewsletter = 'subscribeNewsletter',
  addToWishlist = 'addToWishlist',
  removeFromWishlist = 'removeFromWishlist',
  mergeWishlist = 'mergeWishlist',
  moveToCartFromWishlist = 'moveToCartFromWishlist',
  moveToWishlistFromCart = 'moveToWishlistFromCart',
  mergeCartAndWishlist = 'mergeCartAndWishlist',
  reserveOrderNumber = 'reserveOrderNumber',
  paypalECS = 'paypalECS',
  sendAffiliationForm = 'sendAffiliationForm',
}
