import types from './actionTypes';
import { ProductActions } from './actions';

import { productState, emptyProduct, emptyReviews } from './state';
import { State } from './types';

export default function product(state = productState, action: ProductActions): State {
  switch (action.type) {
    case types.REQUEST_PRODUCT_LOADING:
      return {
        ...state,
        isFetching: true,
        errMsg: '',
        product: { ...emptyProduct },
      };
    case types.REQUEST_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errMsg: action.payload,
      };
    case types.REQUEST_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        isFetching: false,
      };
    case types.REQUEST_PRODUCT_MODAL:
      return {
        ...state,
        productModal: action.payload,
      };
    case types.REQUEST_REVIEWS_LOADING:
      return {
        ...state,
        productReviews: {
          ...state.productReviews,
          isFetching: true,
        },
      };
    case types.REQUEST_REVIEWS_FAILURE:
      return {
        ...state,
        productReviews: {
          isFetching: false,
          errMsg: '',
          reviews: {
            ...emptyReviews,
          },
        },
      };
    case types.REQUEST_REVIEWS_SUCCESS:
      return {
        ...state,
        productReviews: {
          isFetching: false,
          errMsg: '',
          reviews: {
            ...action.payload,
          },
        },
      };
    case types.CHANGE_SIZE:
      return { ...state, size: action.payload };
    case types.CHANGE_SIZE_MODAL:
      return { ...state, sizeModal: action.payload };
    case types.SET_SIZE_ERR_MSG:
      return { ...state, sizeErrMsg: action.payload };
    case types.SET_CROSS_SELL_LOADING:
      return { ...state, crossSellIsLoading: action.payload };
    case types.SET_UP_SELL_LOADING:
      return { ...state, upSellIsLoading: action.payload };
    default:
      return state;
  }
}
