import * as React from 'react';
import { css } from '@emotion/core';

import { breakpoints, colors, DynamicButton, DynamicButtonStatus } from '../../../design-system';
import { Forms } from '../../form/types';
import {
  CTA_TO_DELIVERY,
  CTA_TO_PAYMENT,
  LBL_ERROR_FORM_BILLING,
  LBL_ERROR_FORM_OPEN,
  LBL_ERROR_FORM_SHIPPING,
} from '../locale';
import { CartStep } from '../types';

type Props = {
  step: CartStep;
  disabled: boolean;
  ctaState: DynamicButtonStatus;
  isActive: boolean;
  onClick: () => void;
  formErrorType: Forms | null;
  openedFormType: Forms | null;
};

export const Cta = ({
  step,
  disabled,
  onClick,
  ctaState,
  isActive,
  formErrorType,
  openedFormType,
}: Props) => (
  <>
    <DynamicButton
      id={`cart-btn-step-${step}`}
      data-cy={`cart-btn-step-${step}`}
      onClick={onClick}
      disabled={disabled}
      isActive={isActive}
      data={step === CartStep.LISTING ? CTA_TO_DELIVERY : CTA_TO_PAYMENT}
      feedback={ctaState}
      icon="secure"
    />
    {formErrorType || openedFormType ? (
      <p
        css={css`
          font-size: 1.2rem;
          height: 12px;
          color: ${colors.MAIN_ERROR};
          margin-block-start: 0;
          margin-block-end: 0;
          margin-top: -16px;
          display: none;
          @media (min-width: ${breakpoints.S}px) {
            display: block;
          }
        `}
      >
        {formErrorType === Forms.shipping
          ? LBL_ERROR_FORM_SHIPPING
          : formErrorType === Forms.billing
            ? LBL_ERROR_FORM_BILLING
            : LBL_ERROR_FORM_OPEN}
      </p>
    ) : null}
  </>
);
