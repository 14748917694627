import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { useOutsideClick } from '../../common/hooks/useOutsideClick';
import { Icon, colors, breakpoints } from '../../../design-system';
import { Suggestion } from '../types';
import { zIndex } from '../../common/constants';

type Props = {
  query: string;
  suggestionsList: Suggestion[];
  onClick: (name: string) => void;
  forceAutcompleteClosing?: boolean;
  isStoreLocator?: boolean;
  isPositionAbsolute?: boolean;
  isEreservation?: boolean;
};

type StyledUlProps = {
  width: number;
  position: 'absolute' | 'initial';
  bgColor: string;
};

const StyledUl = styled.ul<StyledUlProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `calc(100vw - ${props.width}px)`};
  position: ${(props) => props.position};
  transform: translate3d(0, 0, 0);
  z-index: ${zIndex.SEARCH};
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${(props) => props.bgColor};

  @media (min-width: ${breakpoints.S}px) {
    width: 100%;
  }

  .autocomplete-li {
    border-bottom: solid 1px ${colors.LIGHT};
    height: 50px;
    @media (hover: hover) {
      :hover {
        background-color: ${colors.WHITE};
        cursor: pointer;
      }
    }
  }

  .autocomplete-button {
    width: 100%;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 16px;
    text-align: left;
    color: ${colors.BLACK};
    cursor: pointer;
  }

  .autocomplete-icon-container {
    width: 17px;
  }

  .autocomplete-paragraph {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 16px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StoreAutoComplete = ({
  query,
  suggestionsList,
  onClick,
  forceAutcompleteClosing,
  isStoreLocator = false,
  isPositionAbsolute = false,
  isEreservation = false,
}: Props) => {
  const ref = useRef<HTMLUListElement | null>(null);
  const [hasClickedOutside, setHasClickedOutside] = useState(false);
  useOutsideClick(ref, () => setHasClickedOutside(true));

  useEffect(() => {
    setHasClickedOutside(false);
  }, [query]);

  useEffect(() => {
    if (forceAutcompleteClosing) {
      setHasClickedOutside(true);
    }
  }, [forceAutcompleteClosing]);

  const handleSuggestionClick = (name: string) => {
    setHasClickedOutside(true);
    onClick(name);
  };

  const suggestions = (suggestionsList ?? [])
    .slice(0, isStoreLocator ? 4 : 3)
    .map((suggestion, index) => (
      <li key={index} className="autocomplete-li">
        <button
          type="button"
          onClick={() => handleSuggestionClick(suggestion.name)}
          data-testid={`autocomplete-suggestion-click-on-${index}-${suggestion.name}-on-${
            isEreservation ? 'ereservation' : isStoreLocator ? 'store-locator' : 'cart-delivery'
          }`}
          aria-label={`Bouton pour lancer la recherche avec cette adresse ${suggestion.name}`}
          className="autocomplete-button"
        >
          <div className="autocomplete-icon-container">
            <Icon name="mapMarkerFull" size={17} />
          </div>
          <p id={`suggestion-${index}`} className="autocomplete-paragraph">
            {suggestion.name}
          </p>
        </button>
      </li>
    ));

  useEffect(() => {
    setHasClickedOutside(true);
    const handleScroll = () => {
      setHasClickedOutside(true);
    };
    document.addEventListener('wheel', handleScroll, { passive: true });
    document.addEventListener('touchmove', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchmove', handleScroll);
    };
  }, []);

  const numberOfSuggestions = suggestions.length;
  const showSuggestions = numberOfSuggestions > 0 && query.length > 0 && !hasClickedOutside;

  return !showSuggestions ? null : (
    <StyledUl
      id={`autocomplete-suggestions-list-${
        isEreservation ? 'ereservation' : isStoreLocator ? 'store-locator' : 'cart-delivery'
      }`}
      ref={ref}
      width={isStoreLocator || isEreservation ? 32 : 64}
      position={isPositionAbsolute ? 'absolute' : 'initial'}
      bgColor={isStoreLocator ? colors.BACKGROUND : colors.LIGHT2}
    >
      {suggestions}
    </StyledUl>
  );
};
