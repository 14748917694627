import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/core';

import { Box, Icon } from '../../design-system';
import { closeModal } from '../common/actions';
import { breakpoints, colors } from '../../design-system/systemprovider';
import { persistData } from '../common/utils';
import { Link } from 'react-router-dom';

export const PaypalExpressModalError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      persistData('isPaypalECS', false);
      persistData('subscribePaypalECS', false);
    };
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      mx="m"
      mt="m"
      mb="xxl"
      flexDirection="column"
      id="paypal-express-modal-error"
    >
      <Box alignSelf="end">
        <Icon name="closeSmall" size={20} onClick={() => dispatch(closeModal())} />
      </Box>
      <Box textAlign="center" mt="m" mb="l" fontSize={['20px', '30px']} fontWeight={700}>
        Oups...
      </Box>
      <Box
        fontSize={['14px', '16px']}
        lineHeight={['18px', '20px']}
        textAlign="center"
        width="80%"
        mb="xl"
      >
        <Box>
          <Box mb="xs">
            <span style={{ color: colors.ERROR }}>Une erreur est survenue</span> lors de la
            validation du paiement avec le raccourci PayPal Express.
          </Box>
          Accédez à votre panier en{' '}
          <button
            className="anchor-animated"
            type="button"
            onClick={() => dispatch(closeModal())}
            data-testid="paypal-express-modal-error-btn-close"
            id="paypal-express-modal-error-btn-close"
            css={css`
              font-size: 1.4rem;

              @media (min-width: ${breakpoints.S}px) {
                font-size: 1.6rem;
              }
            `}
          >
            cliquant ici
          </button>
          {' ou '}
          <Link
            className="anchor-animated"
            id="paypal-express-modal-error-link-customer-services-mail"
            to="https://faq.darjeeling.fr/hc/fr/requests/new"
            aria-label="Bouton pour ouvrir le formulaire de contact"
            data-testid="paypal-express-modal-error-link-customer-services-mail"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              text-decoration: none;
              font-size: 1.4rem;
              color: ${colors.BLACK};

              @media (min-width: ${breakpoints.S}px) {
                font-size: 1.6rem;
              }
            `}
          >
            contactez notre service client
          </Link>
          .
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <img
          height="20px"
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="PayPal"
        />
      </Box>
    </Box>
  );
};
