import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { setUpSellLoading } from '../actions';
import { breakpoints } from '../../../design-system';
import { RecommendationsBlock } from '../../recommendations/components/RecommendationsBlock';

const StyledUpSellContainer = styled.div`
  overflow-x: hidden;
  margin-top: 24px;

  @media (min-width: ${breakpoints.S}px) {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.M}px) {
    margin-top: 56px;
  }
`;

export const UpSell = () => {
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.upSell
  );
  const { up_sell_title: title } = useSelector((state: RootState) => state.cms.product);
  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setUpSellLoading(false));
    }
  }, [recommendedProducts, hits, isFetching]);

  if (!recommendedProducts || isFetching) {
    return null;
  }

  return (
    <StyledUpSellContainer>
      <RecommendationsBlock id="product-page" hits={hits} title={title} tracking={tracking} />
    </StyledUpSellContainer>
  );
};
