import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import type { Range } from 'node_modules/instantsearch.js/es/connectors/range/connectRange';
import {
  useClearRefinements,
  useCurrentRefinements,
  usePagination,
  useRange,
} from 'react-instantsearch';
import { css } from '@emotion/core';

import { Box, Text, Icon } from '../../../design-system';
import { HIERARCHICAL_ITEMS } from '../../catalog/components/HierarchicalMenu';
import { updateRefinementsItems } from '../actions';
import { getRefinementLabel } from '../utils';

export const formatPriceStore = (
  refinement: CurrentRefinementsConnectorParamsRefinement[],
  range: Range
) => {
  if (refinement.length === 1) {
    return refinement[0].operator === '>='
      ? `${refinement[0].value}€ - ${range.max}€`
      : `${range.min}€ - ${refinement[0].value}€`;
  } else if (refinement.length === 2) {
    return `${refinement[0].value}€ - ${refinement[1].value}€`;
  }
  return null;
};

export const Refinements: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { items: currentRefinements, refine } = useCurrentRefinements({
    excludedAttributes: [...HIERARCHICAL_ITEMS, 'query'],
  });
  const { range } = useRange({ attribute: 'storePrice' });
  const { currentRefinement: pageRefinement } = usePagination();
  const { refine: clearRefine } = useClearRefinements({
    excludedAttributes: [...HIERARCHICAL_ITEMS, 'query'],
  });

  useEffect(() => {
    if (currentRefinements[0]) {
      dispatch(updateRefinementsItems(currentRefinements[0]?.refinements));
    }
  }, [currentRefinements]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const pageParam = searchParams.get('page');
    if (pageParam === '1') {
      searchParams.delete('page');
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }, [pageRefinement, navigate]);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      css={css`
        gap: 8px;
      `}
    >
      {currentRefinements.map((currentRefinement, index) => {
        if (currentRefinement.attribute === 'storePrice') {
          return (
            <Box
              key={`currentRefinement_${index}`}
              display="grid"
              gridAutoFlow="column"
              gridAutoColumns="min-content"
              gridGap="s"
              alignItems="center"
              height="xl"
              border="1px solid"
              borderColor="BLACK"
              px="xs"
            >
              <Text maxWidth="xh" withEllipsis>
                {formatPriceStore(currentRefinement.refinements, range)}
              </Text>
              <Icon
                name="close"
                size={12}
                onClick={() =>
                  currentRefinement.refinements.forEach((refinement) => refine(refinement))
                }
              />
            </Box>
          );
        }

        return currentRefinement.refinements.map((refinement) => (
          <Box
            key={`currentRefinement_${refinement.value}_${index}`}
            display="grid"
            gridAutoFlow="column"
            gridAutoColumns="min-content"
            gridGap="s"
            alignItems="center"
            height="xl"
            border="1px solid"
            borderColor="BLACK"
            px="xs"
          >
            <Text maxWidth="xh" withEllipsis>
              {getRefinementLabel(refinement.label)}
            </Text>
            <Icon name="close" size={12} onClick={() => refine(refinement)} />
          </Box>
        ));
      })}
      {currentRefinements.length > 0 && (
        <button
          className="anchor-animated"
          type="button"
          data-testid="btn-reset-filters"
          aria-label="Bouton pour réinitialiser les filtres"
          id="btn-reset-filters"
          onClick={clearRefine}
          css={css`
            font-size: 1.4rem;
          `}
        >
          Réinitialiser les filtres
        </button>
      )}
    </Box>
  );
};
