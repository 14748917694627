import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { breakpoints, Divider } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { Newsletter } from './Newsletter';
import { Links } from './Links';
import { PaymentMethods } from './PaymentMethods';
import { SocialLinks } from './SocialLinks';
import { LegalLinks } from './LegalLinks';

export const Footer = () => {
  const cmsContent = useSelector((state: RootState) => state.cms.footer);

  const mainLinks = cmsContent?.body ?? [];
  const socialLinks = cmsContent?.contact ?? [];
  const paymentsMethodsImages = cmsContent?.payment_methods ?? [];

  return (
    <footer id="darjeeling-footer">
      <Newsletter {...{ cmsContent }} />
      <div
        css={css`
          padding: 0 16px;
          margin: 0 auto;
          @media (min-width: ${breakpoints.S}px) {
            padding: 0 24px;
          }
        `}
      >
        <div
          css={css`
            display: grid;
            grid-auto-flow: row;
            grid-gap: none;
            justify-content: initial;
            align-items: flex-start;
            margin: 0;

            @media (min-width: ${breakpoints.M}px) {
              margin: 24px 0 16px 0;
            }

            @media (min-width: ${breakpoints.L}px) {
              grid-auto-flow: column;
              grid-gap: 80px;
              justify-content: center;
              margin: 48px 0;
            }
          `}
        >
          <Links links={mainLinks} />
          <div
            css={css`
              display: block;
              margin-bottom: 16px;
              @media (min-width: ${breakpoints.L}px) {
                display: none;
              }
            `}
          >
            <Divider />
          </div>
          <div
            css={css`
              display: grid;
              grid-gap: 16px;
              justify-content: initial;
              @media (min-width: ${breakpoints.L}px) {
                grid-gap: 24px;
                justify-content: center;
              }
            `}
          >
            <PaymentMethods images={paymentsMethodsImages} />
            <div
              css={css`
                display: block;
                @media (min-width: ${breakpoints.L}px) {
                  display: none;
                }
              `}
            >
              <Divider />
            </div>
            <SocialLinks links={socialLinks} />
          </div>
        </div>
        <Divider />
        <LegalLinks />
      </div>
    </footer>
  );
};
