import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import { Box, Field, Button, Icon, Text } from '../../../design-system';
import { YOUR_POSITION, SEARCH_BUTTON } from '../locale';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  onIconClick: () => void;
  onChange: (value: string) => void;
  onClick: () => void;
  value: string;
  errMsg: string;
  placeholder?: string;
  isDelivery?: boolean;
  isPositioned?: boolean;
  isEresaModal?: boolean;
  setForceAutcompleteClosing?: (value: boolean) => void;
};

const Search = ({
  onIconClick,
  onChange,
  onClick,
  value,
  errMsg,
  placeholder,
  isDelivery,
  isPositioned,
  isEresaModal,
  setForceAutcompleteClosing = () => null,
}: Props) => {
  const { isMobile } = useMediaQueries();

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  const onKeyPress = (event) => {
    if (event && event.key === 'Enter') {
      setForceAutcompleteClosing(true);
      isEresaModal || isDelivery ? handleClick(event) : onClick();
    }
  };

  const onIconClickCustom = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onIconClick();
  };

  return (
    <form autoComplete="off">
      <Box display="flex">
        <Box width="100%" mr={isEresaModal || isDelivery ? 's' : 'na'}>
          <Field
            id="store-locator-input-field"
            icon={isEresaModal || isDelivery ? 'search' : 'autoLocation'}
            iconSize={isEresaModal || isDelivery ? 16 : 24}
            iconMargin={isEresaModal || isDelivery ? '0' : 'm'}
            autoComplete="off"
            iconOpacity={isPositioned ? 1 : 0.4}
            hasBorder={!(isEresaModal || isDelivery)}
            isEresaModal={isEresaModal}
            isDelivery={isDelivery}
            customClick={handleClick}
            onIconClick={onIconClick}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            errMsg={errMsg}
            label={isPositioned ? YOUR_POSITION : placeholder}
          />
        </Box>
        <Box width={isEresaModal || isDelivery ? '50px' : isMobile ? '70px' : '200px'}>
          <StyledButton
            id="store-locator-search-button"
            onClick={isEresaModal || isDelivery ? onIconClickCustom : handleClick}
          >
            {isMobile || isEresaModal || isDelivery ? (
              <Icon
                name={isEresaModal ? 'mapMarkerEmpty' : isDelivery ? 'mapMarkerFull' : 'search'}
                size={isEresaModal || isDelivery ? 24 : 17}
                color="WHITE"
              />
            ) : (
              <Text color="WHITE">{SEARCH_BUTTON}</Text>
            )}
          </StyledButton>
        </Box>
      </Box>
    </form>
  );
};

const StyledButton = styled(Button)(
  css({
    height: ['50px', '50px'],
  })
);

export default Search;
