import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { CmsTopTenDotColor, CmsTopTenItem } from '../../cms/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { breakpoints, colors, Icon } from '../../../design-system';
import { durations, heights } from '../../common/constants';
import { ProductCard } from '../../catalog/components/ProductCard';
import { CatalogProduct } from '../../catalog/types';
import { isValidProduct } from '../../ecart/utils';
import {
  TOP_TEN_PRODUCT_CARD_MIN_WIDTH,
  TOP_TEN_DOT_HEIGHT,
  TOP_TEN_DOT_TRANSPARENT_GAP,
  TOP_TEN_DOT_WIDTH,
  TOP_TEN_PAGE_MAX_WIDTH,
  TOP_TEN_CTA_BLOCK_TEXT,
  getParagraphNumberText,
  getFormatedLinkForReactRouterLink,
} from '../utils';
import { DotProduct } from './DotProduct';
import { ButtonLink } from '../../../design-system/button';

type Props = {
  item: CmsTopTenItem;
  itemsLength: number;
  index: number;
  stickySliderIsActive: boolean;
  allBlocksLoaded: boolean;
  setAllBlocksLoaded: (value: boolean) => void;
};

type StyledContainerProps = {
  dotOneColor: CmsTopTenDotColor;
  dotTwoColor: CmsTopTenDotColor;
  dotThreeColor: CmsTopTenDotColor;
  dotOneX: number;
  dotOneY: number;
  dotTwoX: number;
  dotTwoY: number;
  dotThreeX: number;
  dotThreeY: number;
  iconBackgroundColor: string;
  textBackgroundColor: string;
  textColor: string;
  stickySliderIsActive: boolean;
  isIconLabelActive: boolean;
  isTextLabelActive: boolean;
  allBlocksLoaded: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  width: 100%;
  scroll-margin-top: ${(props) =>
    props.stickySliderIsActive
      ? heights.HEADER_MOBILE + heights.STICKY_SLIDER_HEIGHT
      : heights.HEADER_MOBILE}px;
  max-width: ${TOP_TEN_PAGE_MAX_WIDTH}px;
  text-align: left;
  display: ${(props) => (props.allBlocksLoaded ? 'flex' : 'none')};
  flex-direction: column-reverse;
  margin: auto;
  padding-top: 16px;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (min-width: ${breakpoints.S}px) {
    padding-top: 24px;
    flex-direction: row;
    scroll-margin-top: ${heights.HEADER_TABLET}px;
  }
  @media (min-width: ${breakpoints.L}px) {
    scroll-margin-top: ${heights.HEADER}px;
  }
  .top-ten-image__wrapper {
    position: relative;
    width: 100%;
    padding-top: 8px;
    @media (min-width: ${breakpoints.S}px) {
      width: 60%;
      padding-right: 8px;
      padding-top: 0;
    }
    @media (min-width: ${breakpoints.L}px) {
      width: 50%;
    }
  }
  .top-ten-image__label {
    display: ${(props) => (props.isIconLabelActive || props.isTextLabelActive ? 'block' : 'none')};
    position: absolute;
    bottom: 16px;
    left: 16px;
    display: flex;

    .top-ten-label__icon {
      width: 32px;
      height: 32px;
      display: ${(props) => (props.isIconLabelActive ? 'flex' : 'none')};
      align-items: center;
      justify-content: center;
      background: ${(props) => props.iconBackgroundColor};
      border-radius: ${(props) =>
        props.isIconLabelActive && props.isTextLabelActive ? '4px 0 0 4px' : '4px'};
    }
    .top-ten-label__text {
      display: ${(props) => (props.isTextLabelActive ? 'block' : 'none')};
      width: max-content;
      padding: ${(props) => (props.isTextLabelActive ? '0 8px' : 0)};
      height: 32px;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.textBackgroundColor};
      color: ${(props) => props.textColor};
      border-radius: ${(props) =>
        props.isIconLabelActive && props.isTextLabelActive ? '0 4px 4px 0' : '4px'};
    }
  }
  .top-ten__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (min-width: ${breakpoints.S}px) {
      border-radius: 8px;
    }

    @media (min-width: ${breakpoints.L}px) {
      border-radius: 16px;
    }
  }
  .top-ten-dot__one {
    height: ${TOP_TEN_DOT_WIDTH}px;
    width: ${TOP_TEN_DOT_HEIGHT}px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background: ${(props) => props.dotOneColor};
    position: absolute;
    top: ${(props) => props.dotOneY}%;
    left: ${(props) => props.dotOneX}%;
    @media (hover: hover) {
      &:hover {
        &:before {
          transform: scale(1.2);
          transition: all ${durations.ENTER}ms ease-in-out;
        }
      }
    }
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      top: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      height: ${TOP_TEN_DOT_WIDTH + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      width: ${TOP_TEN_DOT_HEIGHT + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      border: ${(props) => `solid 2px ${props.dotOneColor}`};
      border-radius: 50%;
      transition: all ${durations.ENTER}ms ease-in-out;
    }
    .top-ten-tool-tip__one {
      opacity: 0;
      pointer-events: none;
      width: max-content;
      background: ${colors.WHITE};
      transform: ${(props) =>
        `translate(${props.dotOneX < 50 ? '0%' : props.dotOneX > 50 ? '-100%' : '-50%'}, ${
          props.dotOneY >= 50 ? '-150%' : '100%'
        })`};
      padding: 8px;
      border-radius: 30px;
      transition: opacity ${durations.ENTER}ms ease-in-out;
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 700;
        span {
          margin-left: 16px;
          font-weight: 700;
        }
      }
    }
    @media (hover: hover) {
      cursor: pointer;
      &:hover .top-ten-tool-tip__one {
        opacity: 1;
        transition: opacity ${durations.ENTER}ms ease-in-out;
      }
    }
  }
  .top-ten-dot__two {
    height: ${TOP_TEN_DOT_WIDTH}px;
    width: ${TOP_TEN_DOT_HEIGHT}px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background: ${(props) => props.dotTwoColor};
    position: absolute;
    top: ${(props) => props.dotTwoY}%;
    left: ${(props) => props.dotTwoX}%;
    @media (hover: hover) {
      &:hover:before {
        transform: scale(1.2);
        transition: all ${durations.ENTER}ms ease-in-out;
      }
    }
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      top: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      height: ${TOP_TEN_DOT_WIDTH + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      width: ${TOP_TEN_DOT_HEIGHT + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      border: ${(props) => `solid 2px ${props.dotTwoColor}`};
      border-radius: 50%;
      transition: all ${durations.ENTER}ms ease-in-out;
    }
    .top-ten-tool-tip__two {
      opacity: 0;
      pointer-events: none;
      background: ${colors.WHITE};
      width: max-content;
      transform: ${(props) =>
        `translate(${props.dotTwoX < 50 ? '0%' : props.dotTwoX > 50 ? '-100%' : '-50%'}, ${
          props.dotTwoY >= 50 ? '-150%' : '100%'
        })`};
      padding: 8px;
      border-radius: 30px;
      transition: opacity ${durations.ENTER}ms ease-in-out;
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 700;
        span {
          margin-left: 16px;
          font-weight: 700;
        }
      }
    }
    @media (hover: hover) {
      cursor: pointer;
      &:hover .top-ten-tool-tip__two {
        opacity: 1;
        transition: opacity ${durations.ENTER}ms ease-in-out;
      }
    }
  }
  .top-ten-dot__three {
    height: ${TOP_TEN_DOT_WIDTH}px;
    width: ${TOP_TEN_DOT_HEIGHT}px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background: ${(props) => props.dotThreeColor};
    position: absolute;
    top: ${(props) => props.dotThreeY}%;
    left: ${(props) => props.dotThreeX}%;
    @media (hover: hover) {
      &:hover:before {
        transform: scale(1.2);
        transition: all ${durations.ENTER}ms ease-in-out;
      }
    }
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      top: ${`-${TOP_TEN_DOT_TRANSPARENT_GAP / 2}px`};
      height: ${TOP_TEN_DOT_WIDTH + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      width: ${TOP_TEN_DOT_HEIGHT + TOP_TEN_DOT_TRANSPARENT_GAP}px;
      border: ${(props) => `solid 2px ${props.dotThreeColor}`};
      border-radius: 50%;
      transition: all ${durations.ENTER}ms ease-in-out;
    }
    .top-ten-tool-tip__three {
      opacity: 0;
      pointer-events: none;
      background: ${colors.WHITE};
      width: max-content;
      transform: ${(props) =>
        `translate(${props.dotThreeX < 50 ? '0%' : props.dotThreeX > 50 ? '-100%' : '-50%'}, ${
          props.dotThreeY >= 50 ? '-150%' : '100%'
        })`};
      padding: 8px;
      border-radius: 30px;
      transition: opacity ${durations.ENTER}ms ease-in-out;
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: 700;
        span {
          margin-left: 16px;
          font-weight: 700;
        }
      }
    }
    @media (hover: hover) {
      cursor: pointer;
      &:hover .top-ten-tool-tip__three {
        opacity: 1;
        transition: opacity ${durations.ENTER}ms ease-in-out;
      }
    }
  }
  .top-ten__description {
    width: 100%;
    padding-left: 16px;
    @media (min-width: ${breakpoints.S}px) {
      margin-top: 32px;
      width: 40%;
      padding-left: 8px;
      align-self: center;
    }
    @media (min-width: ${breakpoints.L}px) {
      width: 50%;
    }
  }
  .top-ten-block__subtitle {
    width: calc(100% - 16px);
    font-size: 1.4rem;
    line-height: 21px;
    color: ${colors.MAIN_GREY};
    margin-block-start: 0;
    margin-block-end: 0;

    @media (min-width: ${breakpoints.M}px) {
      width: 100%;
    }
  }
  .top-ten-block__number {
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 8px;
  }
  .top-ten-product__description {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
    padding: 32px 0;
    @media (min-width: ${breakpoints.M}px) {
      width: calc(100% + 24px);
    }
    @media (min-width: ${breakpoints.L}px) {
      width: 100%;
    }
  }
  .top-ten-product-description-wrapper {
    width: calc(50% - 4px);
    min-width: ${TOP_TEN_PRODUCT_CARD_MIN_WIDTH}px;
    &:not(:first-of-type) {
      margin-left: 4px;
    }
    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }
`;

type StyledSuggestionDivProps = {
  allBlocksLoaded: boolean;
};

const StyledSuggestionDiv = styled.div<StyledSuggestionDivProps>`
  display: ${(props) => (props.allBlocksLoaded ? 'flex' : 'none')};
  margin: auto;
  margin-bottom: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: ${TOP_TEN_PAGE_MAX_WIDTH}px;
  padding: 24px 0;
  @media (min-width: ${breakpoints.M}px) {
    width: calc(100% + 24px);
  }
  @media (min-width: ${breakpoints.L}px) {
    padding: 40px 0;
  }
  @media (min-width: ${TOP_TEN_PAGE_MAX_WIDTH}px) {
    width: 100%;
  }
  .top-ten-product-suggestion-wrapper {
    width: 25%;
    min-width: ${TOP_TEN_PRODUCT_CARD_MIN_WIDTH}px;
    @media (min-width: ${breakpoints.L}px) {
      width: calc(20% - 4px);
    }
    &:not(:first-of-type) {
      margin-left: 4px;
    }
    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }

  .top-ten-testimonial__desktop {
    display: none;
    @media (min-width: ${breakpoints.L}px) {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      margin-left: 4px;
      width: calc(20% - 4px);
      min-width: ${TOP_TEN_PRODUCT_CARD_MIN_WIDTH}px;
      background: ${colors.BACKGROUND};
      padding: 16px;
    }
  }
`;
const getTopTenTestimonial = ({
  identifier,
  item,
}: {
  identifier: string;
  item: CmsTopTenItem;
}): JSX.Element => {
  return (
    <div className={`top-ten-testimonial${identifier}`}>
      <div>
        <div className="icon-div">
          <Icon name="quoteIconsAffiliate" size={30} />
        </div>
        {item.top_ten_page_testimonial_title && (
          <p className="top-ten-page-testimonial__title">{item.top_ten_page_testimonial_title}</p>
        )}
        {item.top_ten_page_testimonial_text && (
          <p className="top-ten-page-testimonial__text">{item.top_ten_page_testimonial_text}</p>
        )}
        {item.top_ten_page_testimonial_author && (
          <p className="top-ten-page-testimonial__author">{item.top_ten_page_testimonial_author}</p>
        )}
      </div>
      <ButtonLink
        id={`top-ten-suggestion-link${identifier}`}
        data-testid={`top-ten-suggestion-link${identifier}`}
        className={`top-ten-suggestion-link${identifier}`}
        to={getFormatedLinkForReactRouterLink(item.top_ten_page_testimonial_link)}
        aria-label={`Bouton pour aller à la page de la collection ${item.top_ten_page_block_title}`}
      >
        {TOP_TEN_CTA_BLOCK_TEXT}
      </ButtonLink>
    </div>
  );
};

export const TopTenBlock = ({
  item,
  itemsLength,
  index,
  stickySliderIsActive,
  allBlocksLoaded,
  setAllBlocksLoaded,
}: Props) => {
  const { hash } = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    top_ten_image,
    top_ten_page_block_title,
    top_ten_page_block_subtitle,
    top_ten_page_first_bottom_product_ref,
    top_ten_page_second_bottom_product_ref,
    top_ten_page_third_bottom_product_ref,
    top_ten_page_fourth_bottom_product_ref,
    top_ten_page_first_product_ref,
    top_ten_page_second_product_ref,
    top_ten_dot_one_color,
    top_ten_image_dot_one_horizontal_position,
    top_ten_image_dot_one_vertical_position,
    top_ten_dot_two_color,
    top_ten_image_dot_two_horizontal_position,
    top_ten_image_dot_two_vertical_position,
    top_ten_dot_three_color,
    top_ten_image_dot_three_horizontal_position,
    top_ten_image_dot_three_vertical_position,
    top_ten_product_ref_for_dot_one,
    top_ten_product_ref_for_dot_two,
    top_ten_product_ref_for_dot_three,
    top_ten_label_block_icon_name,
    top_ten_label_block_icon_color,
    top_ten_label_block_icon_background_color,
    top_ten_label_block_text,
    top_ten_label_block_text_color,
    top_ten_label_block_text_background_color,
  } = item;

  const { hits: descriptionProducts } = useProductsInfos([
    top_ten_page_first_product_ref ?? '',
    top_ten_page_second_product_ref ?? '',
  ]);

  const { hits: suggestionProducts } = useProductsInfos([
    top_ten_page_first_bottom_product_ref ?? '',
    top_ten_page_second_bottom_product_ref ?? '',
    top_ten_page_third_bottom_product_ref ?? '',
    top_ten_page_fourth_bottom_product_ref ?? '',
  ]);

  const { hits: firstDotProduct } = useProductsInfos([top_ten_product_ref_for_dot_one ?? '']);
  const { hits: secondDotProduct } = useProductsInfos([top_ten_product_ref_for_dot_two ?? '']);
  const { hits: thirdDotProduct } = useProductsInfos([top_ten_product_ref_for_dot_three ?? '']);

  const memorizedDescriptionProducts = useMemo(() => {
    return descriptionProducts;
  }, [descriptionProducts]);

  const memorizedSuggestionProducts = useMemo(() => {
    return suggestionProducts;
  }, [suggestionProducts]);

  const memorizedFirstDotProduct = useMemo(() => {
    return firstDotProduct?.[0];
  }, [firstDotProduct]);

  const memorizedSecondDotProduct = useMemo(() => {
    return secondDotProduct?.[0];
  }, [secondDotProduct]);

  const memorizedThirdDotProduct = useMemo(() => {
    return thirdDotProduct?.[0];
  }, [thirdDotProduct]);

  useEffect(() => {
    if (
      index + 1 === itemsLength &&
      memorizedDescriptionProducts.length === 2 &&
      memorizedSuggestionProducts.length === 4
    ) {
      setAllBlocksLoaded(true);
    }
  }, [memorizedDescriptionProducts, memorizedSuggestionProducts]);

  useEffect(() => {
    if (allBlocksLoaded && ref?.current && hash === `#top-ten-block-${index + 1}`) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [allBlocksLoaded, ref?.current, hash]);

  return memorizedSuggestionProducts?.length < 4 &&
    memorizedDescriptionProducts.length < 2 ? null : (
    <>
      <StyledContainer
        ref={ref}
        dotOneColor={top_ten_dot_one_color ?? 'WHITE'}
        dotTwoColor={top_ten_dot_two_color ?? 'WHITE'}
        dotThreeColor={top_ten_dot_three_color ?? 'WHITE'}
        dotOneX={top_ten_image_dot_one_horizontal_position ?? 0}
        dotOneY={top_ten_image_dot_one_vertical_position ?? 0}
        dotTwoX={top_ten_image_dot_two_horizontal_position ?? 0}
        dotTwoY={top_ten_image_dot_two_vertical_position ?? 0}
        dotThreeX={top_ten_image_dot_three_horizontal_position ?? 0}
        dotThreeY={top_ten_image_dot_three_vertical_position ?? 0}
        iconBackgroundColor={top_ten_label_block_icon_background_color ?? colors.BLACK}
        textBackgroundColor={top_ten_label_block_text_background_color ?? colors.WHITE}
        textColor={top_ten_label_block_text_color ?? colors.BLACK}
        stickySliderIsActive={stickySliderIsActive}
        isIconLabelActive={Boolean(top_ten_label_block_icon_name)}
        isTextLabelActive={Boolean(top_ten_label_block_text)}
        allBlocksLoaded={allBlocksLoaded}
        id={`top-ten-block-${index + 1}`}
      >
        <div className="top-ten-image__wrapper">
          <img
            className="top-ten__image"
            src={top_ten_image?.url}
            alt={top_ten_image?.alt ?? `catalogue-slider-image-${index}`}
          />
          <div className="top-ten-image__label">
            <div className="top-ten-label__icon">
              <Icon
                name={top_ten_label_block_icon_name ?? ''}
                size={16}
                color={top_ten_label_block_icon_color ?? colors.WHITE}
              />
            </div>
            <div className="top-ten-label__text">{top_ten_label_block_text}</div>
          </div>
          {top_ten_image_dot_one_horizontal_position &&
            top_ten_image_dot_one_vertical_position &&
            top_ten_product_ref_for_dot_one &&
            isValidProduct(memorizedFirstDotProduct) && (
              <DotProduct product={memorizedFirstDotProduct} className={'top-ten-dot__one'} />
            )}
          {top_ten_image_dot_two_horizontal_position &&
            top_ten_image_dot_two_vertical_position &&
            top_ten_product_ref_for_dot_two &&
            isValidProduct(memorizedSecondDotProduct) && (
              <DotProduct product={memorizedSecondDotProduct} className="top-ten-dot__two" />
            )}
          {top_ten_image_dot_three_horizontal_position &&
            top_ten_image_dot_three_vertical_position &&
            top_ten_product_ref_for_dot_three &&
            isValidProduct(memorizedThirdDotProduct) && (
              <DotProduct product={memorizedThirdDotProduct} className="top-ten-dot__three" />
            )}
        </div>
        <div className="top-ten__description">
          {top_ten_page_block_title && (
            <h2 className="top-ten__title">{top_ten_page_block_title}</h2>
          )}
          <p className="top-ten-block__number">{getParagraphNumberText(index)}</p>

          {top_ten_page_block_subtitle && (
            <p className="top-ten-block__subtitle">{top_ten_page_block_subtitle}</p>
          )}
          <div className="top-ten-product__description">
            {memorizedDescriptionProducts.slice(0, 2).map((item, index) => {
              const product = {
                ...item,
                objectID: `${item.productRef}/${item.colorRef}`,
                rcFilter: `${item.productRef}-${item.colorRef}`,
              };
              return isValidProduct(item) ? (
                <div
                  key={`top-ten-product-description-${index}`}
                  className="top-ten-product-description-wrapper"
                >
                  <ProductCard hit={product as unknown as CatalogProduct} leftAlignToolTip />
                </div>
              ) : null;
            })}
          </div>
        </div>
      </StyledContainer>
      <StyledSuggestionDiv
        id={`top-ten-block-suggestion-${index + 1}`}
        allBlocksLoaded={allBlocksLoaded}
      >
        {memorizedSuggestionProducts.slice(0, 4).map((item, index) => {
          const product = {
            ...item,
            objectID: `${item.productRef}/${item.colorRef}`,
            rcFilter: `${item.productRef}-${item.colorRef}`,
          };
          return isValidProduct(item) ? (
            <div
              key={`top-ten-product-suggestion-${index}`}
              className="top-ten-product-suggestion-wrapper"
            >
              <ProductCard hit={product as unknown as CatalogProduct} leftAlignToolTip />
            </div>
          ) : null;
        })}
        {getTopTenTestimonial({ identifier: '__desktop', item })}
      </StyledSuggestionDiv>
      {getTopTenTestimonial({ identifier: '', item })}
    </>
  );
};
