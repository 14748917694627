import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Box, Button, colors } from '../../design-system';
import { openModal } from '../common/actions';
import PaypalModal from './PaypalModal';

type Props = {
  isCartValid: boolean;
};

export const PaypalExpressButton = ({ isCartValid }: Props) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openModal({ content: <PaypalModal />, preset: 'paypalECS' }));

  return (
    <ExpressPaypalButton id="btn-paypal-express" isCartValid={isCartValid} onClick={handleClick}>
      <Box fontSize="16px" alignSelf="center" mr="10px">
        Paiement rapide avec
      </Box>
      <Box alignSelf="center">
        <img
          height="20px"
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="PayPal"
        />
      </Box>
    </ExpressPaypalButton>
  );
};

type ExpressPaypalButtonProps = {
  isCartValid: boolean;
};

const ExpressPaypalButton = styled(Button)<ExpressPaypalButtonProps>(
  {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#F7C557',
    marginTop: '8px',
    height: '44px',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.BLACK,
  },
  ({ isCartValid }) =>
    !isCartValid && {
      pointerEvents: 'none',
      opacity: 0.4,
    }
);
