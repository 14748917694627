import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { useOutsideClick } from '../../common/hooks/useOutsideClick';
import { Selector, Field, DynamicButton, Button, breakpoints } from '../../../design-system';
import { countryOptions } from '../../account/utils';
import { Address as AddressType } from '../../account/types';
import { setFormValues, setFormValidation } from '../../form/actions';
import { DynamicButtonStatus } from '../../../design-system';
import { CART_CTA_ADDRESS_BTN_CONTENT, CTA_CANCEL_CONTENT } from '../locale';
import {
  validate,
  populateForm,
  isCtaActive,
  addSpacesForPhoneNumber,
  removeSpaces,
} from '../../form/utils';
import { setFeedback } from '../../form/actions';
import { Forms, FormFieldCallback, FieldType, FormAddress, FieldRefs } from '../../form/types';
import { RootState } from 'src/shared/store/rootReducer';
import { ShippingTypes } from '../../cart/types';
import { applyScroll, emptyAddress } from '../utils';
import { AddressAutocomplete } from './AddressAutocomplete';

type Props = {
  onFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  onFieldChange: ({ form, key, value }: FormFieldCallback) => void;
  form: FormAddress;
  type: Forms;
  onSave: (address: AddressType) => void;
  setAddingAddressType: (value: Forms | null) => void;
  editAddressType: Forms | null;
  setEditAddressType: (value: Forms | null) => void;
  shippingType: ShippingTypes | undefined;
  billingSelectorRef: React.RefObject<HTMLDivElement>;
  shippingSelectorRef: React.RefObject<HTMLDivElement>;
  shippingAddressId: string;
  billingAddressId: string;
  formErrorType: Forms | null;
  isMapBoxAddressSuggestionsEnabled: boolean;
};

const Address = ({
  type,
  form,
  onFieldBlur,
  onFieldChange,
  onSave,
  editAddressType,
  setEditAddressType,
  setAddingAddressType,
  shippingType,
  billingSelectorRef,
  shippingSelectorRef,
  shippingAddressId,
  billingAddressId,
  formErrorType,
  isMapBoxAddressSuggestionsEnabled,
}: Props) => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLFormElement | null>(null);
  const refs: FieldRefs = {};
  Object.keys(form.values).forEach((key) => {
    refs[key] = React.useRef(null);
  });
  const {
    address: addressForm,
    billing: billingForm,
    shipping: shippingForm,
  } = useSelector((state: RootState) => state.form);
  const { isDigital } = useSelector((state: RootState) => state.cart.cart) ?? {};
  const [originalAddressForm, setOriginalAddressForm] = React.useState<FormAddress>(addressForm);
  const userAddresses = useSelector((state: RootState) => state.account.user?.addresses) ?? [];
  let timer: NodeJS.Timeout;
  React.useEffect(() => {
    setOriginalAddressForm({ ...form });
    if (!editAddressType) {
      onAddressSelect('new');
    }
    if (
      (userAddresses.length > 0 && !formErrorType) ||
      (type === Forms.shipping && !addressForShipping.length)
    ) {
      timer = applyScroll({ type, billingSelectorRef, shippingSelectorRef });
    }
    if (
      (formErrorType === Forms.shipping && addressForShipping.length > 0) ||
      (formErrorType === Forms.billing && userAddresses.length > 0)
    ) {
      validateForm();
    }
    return () => {
      clearTimeout(timer);
      Object.keys(form.values).forEach((key) => {
        dispatch(
          setFormValidation({
            form: type,
            values: {
              [key]: '',
            },
          })
        );
      });
      dispatch(
        setFeedback({
          form: type,
          ok: false,
          message: '',
          isDirty: false,
        })
      );
    };
  }, []);

  const handleFieldChange = ({ form: type, key, value }) => {
    if (!form.feedback.isDirty) {
      dispatch(
        setFeedback({
          form: type,
          ok: false,
          message: '',
          isDirty: true,
        })
      );
    }
    onFieldChange({ form: type, key, value });
  };

  const getField = ({
    key,
    label,
    autoComplete,
    inputType,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    inputType?: string;
  }) => (
    <div
      css={css`
        grid-area: ${key};
      `}
    >
      <Field
        id={`${type}-form-${key}`}
        onChange={(value: string) =>
          handleFieldChange({
            form: type,
            key,
            value: key === 'phone' ? removeSpaces(value) : value,
          })
        }
        onBlur={(value: string) =>
          onFieldBlur({ form: type, key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        value={key === 'phone' ? addSpacesForPhoneNumber(form.values[key]) : form.values[key]}
        errMsg={form.validation[key]}
        ref={refs[key]}
        label={label}
        autoComplete={autoComplete}
        type={inputType}
      />
    </div>
  );

  const onAddressSelect = (value: string) => {
    const address = userAddresses.find((address) => address.id === value) || emptyAddress;
    const keys = Object.keys(address);
    dispatch(
      setFormValues({
        form: type,
        values: populateForm(address, keys),
      })
    );
    dispatch(
      setFormValidation({
        form: type,
        values: populateForm({}, keys),
      })
    );
  };

  const addressForShipping = userAddresses.filter(
    (item) => item.country === 'FR' && item.id !== billingForm.values.id
  );
  const addressForBilling = userAddresses.filter((item) => item.id !== shippingForm.values.id);

  const updateSelectOnCancel = () => {
    if (!addressForShipping.length && type === Forms.shipping) {
      Object.keys(form.values).forEach((key) => {
        dispatch(
          setFormValues({
            form: Forms.shipping,
            values: {
              [key]: '',
            },
          })
        );
      });
    }
    if (addressForShipping.length > 0 && type === Forms.shipping && !isDigital) {
      onAddressSelect(shippingAddressId);
    }
    if (
      ((addressForBilling.length > 0 && !isDigital) || (isDigital && userAddresses.length > 0)) &&
      type === Forms.billing
    ) {
      onAddressSelect(billingAddressId);
    }
    setAddingAddressType(null);
    applyScroll({ type, billingSelectorRef, shippingSelectorRef });
  };

  const validateField = (key: string, value: FieldType, country: string): string =>
    validate({
      value: String(value),
      key,
      isRequired: !['id', 'name', 'company', 'idTva', 'siret', 'streetAdditional'].includes(key),
      country,
    });
  const validateForm = (): boolean => {
    const {
      values,
      values: { country },
    } = form;

    const newErrMsgs = form.validation;
    let fieldToFocus = '';
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField(key, values[key], country);
      newErrMsgs[key] = errMsg;
      dispatch(setFormValidation({ form: type, values: { [key]: errMsg } }));
      if (errMsg && !fieldToFocus) {
        fieldToFocus = key;
      }
      return errMsg;
    });
    if (fieldToFocus) {
      const node = refs[fieldToFocus].current;

      if (node) {
        node.focus();
        node.scrollIntoView();
      }
    }
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      onSave(form.values);
    }
  };

  const onEditCardTypeCancel = () => {
    if ('values' in originalAddressForm) {
      for (const property in originalAddressForm.values) {
        dispatch(
          setFormValues({
            form: type,
            values: { [property]: originalAddressForm.values[property] },
          })
        );
      }
    }
    setEditAddressType(null);
    applyScroll({ type, billingSelectorRef, shippingSelectorRef });
  };

  const onClickOutside = () => {
    if ('values' in originalAddressForm && editAddressType) {
      for (const property in originalAddressForm.values) {
        dispatch(
          setFormValues({
            form: type,
            values: { [property]: originalAddressForm.values[property] },
          })
        );
      }
      setEditAddressType(null);
    }
    if (!editAddressType) {
      setAddingAddressType(null);
      onAddressSelect(type === Forms.billing ? billingAddressId : shippingAddressId);
    }
  };

  const shouldUseOnClickOutside =
    (shippingType === ShippingTypes.HOME && type === Forms.shipping) ||
    (userAddresses.length > 1 && shippingType === ShippingTypes.HOME && type === Forms.billing) ||
    (shippingType !== ShippingTypes.HOME && userAddresses.length > 0) ||
    (isDigital && userAddresses.length > 0);

  const shouldDisplayCancelButton =
    (shippingType !== ShippingTypes.HOME && userAddresses.length > 0) ||
    (shippingType === ShippingTypes.HOME &&
      type === Forms.billing &&
      addressForBilling.length > 0) ||
    (shippingType === ShippingTypes.HOME && type === Forms.shipping) ||
    (isDigital && userAddresses.length > 0);

  useOutsideClick(
    ref,
    shouldUseOnClickOutside && !isMapBoxAddressSuggestionsEnabled ? onClickOutside : () => null
  );

  return (
    <form ref={ref} onSubmit={handleSubmit}>
      <div
        id={`cart-address-${editAddressType ? 'edition' : 'add'}-on-${type}-field`}
        css={css`
          margin-top: 16px;
          display: grid;
          grid-column-gap: 8px;
          grid-row-gap: 24px;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ${`'name name'
        'company company'
         ${form.values.company && "'idTva siret'"}
        'firstName lastName'
        'street street'
        'streetAdditional streetAdditional'
        'postal city'
        'country phone'`};

          @media (max-width: ${breakpoints.S - 26}px) {
            // to match with ipad mini width
            grid-template-columns: auto;
            grid-template-areas: ${`'name'
        'company'
        ${form.values.company && "'idTva'"}
        ${form.values.company && "'siret'"}
        'firstName'
        'lastName'
        'street'
        'streetAdditional'
        'postal'
        'city'
        'country'
        'phone'`};
          }
        `}
      >
        {getField({ key: 'name', label: 'Nom de l’adresse', autoComplete: 'address-name' })}
        {getField({ key: 'company', label: 'Entreprise', autoComplete: 'organization' })}
        {form.values.company &&
          getField({ key: 'idTva', label: 'N° d’identification TVA', autoComplete: 'tva' })}
        {form.values.company && getField({ key: 'siret', label: 'SIRET', autoComplete: 'siret' })}
        {getField({ key: 'firstName', label: 'Prénom*', autoComplete: 'given-name' })}
        {getField({ key: 'lastName', label: 'Nom*', autoComplete: 'family-name' })}
        {isMapBoxAddressSuggestionsEnabled ? (
          <AddressAutocomplete
            form={type}
            handleFieldChange={handleFieldChange}
            content={() =>
              getField({ key: 'street', label: 'Adresse*', autoComplete: 'address-line1' })
            }
          />
        ) : (
          getField({ key: 'street', label: 'Adresse*', autoComplete: 'address-line1' })
        )}

        {getField({
          key: 'streetAdditional',
          label: "Complément d'adresse",
          autoComplete: 'address-line2',
        })}
        {getField({ key: 'postal', label: 'Code postal*', autoComplete: 'postal-code' })}
        {getField({ key: 'city', label: 'Ville*', autoComplete: 'address-level2' })}
        <div
          css={css`
            grid-area: 'country';
          `}
        >
          {type === Forms.billing ? (
            <Selector
              id={`${Forms.billing}-form-country`}
              onChange={(value: string) => {
                handleFieldChange({ form: type, key: 'country', value });
                dispatch(setFormValues({ form: type, values: { country: value } }));
              }}
              value={form.values.country}
              options={countryOptions}
            />
          ) : (
            <Field
              id={`${Forms.shipping}-form-country`}
              onChange={() => {}}
              value=""
              label="Pays"
              readOnly
              placeholder="FRANCE (uniquement)"
            />
          )}
        </div>
        {getField({ key: 'phone', label: 'Téléphone*', autoComplete: 'tel', inputType: 'tel' })}
      </div>
      <div
        css={css`
          display: flex;
          gap: 16px;
          margin-top: 16px;
          @media (max-width: ${breakpoints.S - 1}px) {
            flex-direction: column;
          }
          @media (min-width: ${breakpoints.S}px) {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            @media (min-width: ${breakpoints.S}px) {
              width: 50%;
            }
          `}
        >
          <DynamicButton
            type="submit"
            id={`cart-address-${editAddressType ? 'edition' : 'add'}-on-${type}-submit-btn`}
            data-testid={`cart-address-${
              editAddressType ? 'edition' : 'add'
            }-on-${type}-submit-btn`}
            disabled={!form.feedback.isDirty}
            isActive={isCtaActive(form)}
            feedback={addressForm.ctaState || DynamicButtonStatus.Default}
            data={CART_CTA_ADDRESS_BTN_CONTENT({
              isUpdatingAddress: Boolean(editAddressType),
            })}
          />
        </div>
        {shouldDisplayCancelButton && (
          <div
            css={css`
              @media (max-width: ${breakpoints.S - 1}px) {
                width: 100%;
              }
              @media (min-width: ${breakpoints.S}px) {
                width: 50%;
              }
            `}
          >
            <Button
              type="button"
              id={`cart-address-${editAddressType ? 'edition' : 'add'}-on-${type}-cancel-btn`}
              data-testid={`cart-address-${
                editAddressType ? 'edition' : 'add'
              }-on-${type}-cancel-btn`}
              aria-label={`Bouton pour annuler l'${editAddressType ? 'édition' : 'ajout'} de l'adresse`}
              onClick={() => (editAddressType ? onEditCardTypeCancel() : updateSelectOnCancel())}
              preset="subtle"
            >
              {CTA_CANCEL_CONTENT}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default Address;
