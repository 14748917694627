import * as React from 'react';
import { Icon, breakpoints, colors } from '../../../design-system';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { zIndex } from '../../common/constants';

type TVisual = {
  image: string;
  title: string;
  description: string;
};

export type TSizeGuideVisual = {
  arrayOfVisual?: Array<TVisual>;
  id: string;
};

type StyledArrowButtonProps = {
  direction: 'left' | 'right';
};

const Arrow = ({
  id,
  iconName,
  direction,
  onClick,
}: {
  id: string;
  iconName: string;
  direction: 'left' | 'right';
  onClick?: () => void;
}) => {
  return onClick ? (
    <StyledArrowButton id={id} onClick={onClick} direction={direction}>
      <span>
        <Icon name={iconName} />
      </span>
    </StyledArrowButton>
  ) : null;
};

const StyledArrowButton = styled.button<StyledArrowButtonProps>`
  all: unset; // reset button styles
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zIndex.ARROW_SLIDER};
  width: 54px;
  height: 54px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  left: ${(props) => (props.direction === 'left' ? '16px' : 'unset')};
  right: ${(props) => (props.direction === 'right' ? '16px' : 'unset')};
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :focus {
    background-color: rgba(255, 255, 255, 1);
  }
  @media (hover: hover) {
    :hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

const StyledSizeGuideFittingAdvice = styled.div`
  padding: 24px 0;
  @media (min-width: ${breakpoints.S - 1}px) {
    background: ${colors.BACKGROUND};
    padding: 32px 0;
  }
  .titles-section__wrapper {
    max-width: 800px;
    padding: 0 16px;
    margin: 0 auto 24px;
    text-align: center;
    @media (min-width: ${breakpoints.S - 1}px) {
      margin: 0 auto 32px;
      padding: 0 24px;
    }
  }
  .titles-section-wrapper__main-title {
    font-family: 'Ogg-text', serif;
    font-size: 20px;
    line-height: 110%;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 0;
    @media (min-width: ${breakpoints.S - 1}px) {
      font-size: 24px;
    }
  }
  .titles-section-wrapper__description {
    font-size: 14px;
    line-height: 150%;
    margin: 0;
  }
  .size-guide-visual__item {
    margin: 0 8px;
    padding: 0 8px;
    @media (min-width: ${breakpoints.S - 1}px) {
      margin: 0 12px;
      padding: 0 12px;
    }
  }
  .size-guide-visual__image-wrapper {
    position: relative;
  }
  .size-guide-visual__step-indicator {
    position: absolute;
    left: 16px;
    top: 16px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .size-guide-visual__image {
    max-width: 100%;
  }
  .size-guide-visual__description-wrapper {
    width: 0;
    min-width: 100%;
  }
  .size-guide-visual__title {
    font-size: 14px;
    line-height: 150%;
    font-weight: 700;
    margin: 16px 0 4px;
  }
  .size-guide-visual__subtitle {
    font-size: 12px;
    line-height: 110%;
  }
`;

const carouselSetting = {
  infinite: false,
  dots: false,
  slidesToShow: 4.35,
  slidesToScroll: 1,
  swipeToSlide: false,
  adaptativeHeight: false,
  prevArrow: <Arrow id="left" iconName="chevronLeft" direction="left" />,
  nextArrow: <Arrow id="right" iconName="chevronRight" direction="right" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.2,
        arrows: false,
      },
    },
  ],
};

export const SizeGuideFittingAdvice = ({ arrayOfVisual, id }: TSizeGuideVisual) => {
  if (!arrayOfVisual?.length) {
    return null;
  }
  return (
    <StyledSizeGuideFittingAdvice>
      <div className="titles-section__wrapper">
        <h2 className="titles-section-wrapper__main-title">
          7 points de contrôle pour un soutien-gorge à la bonne taille
        </h2>
        <p className="titles-section-wrapper__description">
          C&apos;est la notice magique pour vous assurer que vous avez choisi le bon soutien-gorge à
          la bonne taille. A vous de jouer !
        </p>
      </div>

      <Slider {...carouselSetting}>
        {arrayOfVisual.map((cell, index) => (
          <div className="size-guide-visual__item" key={`${id}-content-${cell.title}`}>
            <div className="size-guide-visual__image-wrapper">
              <span className="size-guide-visual__step-indicator">
                {index + 1}/{arrayOfVisual.length}
              </span>
              <img className="size-guide-visual__image" src={cell.image} alt="" />
            </div>
            <div className="size-guide-visual__description-wrapper">
              <p className="size-guide-visual__title">{cell.title}</p>
              {cell.description && (
                <span className="size-guide-visual__subtitle">{cell.description}</span>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </StyledSizeGuideFittingAdvice>
  );
};
