export const MSG_MORE_PRODUCTS_LOADING = 'Les produits sont en chargement...';
export const LBL_LOAD_MORE_PRODUCTS = 'Charger plus de produits';
export const LBL_LOAD_PREVIOUS_PRODUCTS = 'Voir les articles précédents';
export const STORE_SELECTION_MODAL_TITLE = 'RÉSERVEZ GRATUITEMENT';
export const SHOW_ONLY_AVAILABLE_IN_STORE = 'Voir uniquement les produits en magasin';
export const RESERVE_IN_STORE = 'Réservez en magasin';
export const LBL_STORE_SELECTION = 'Choisissez votre magasin pour voir la disponibilité';
export const SELECT_SIZE_FOR_STORE_AVAILABILITY_CHECK =
  'Veuillez sélectionner une taille dans les filtres afin de voir les produits disponibles dans ce magasin';
export const SELECT_LOCATION =
  'Saisissez votre localisation pour connaître la disponibilité des produits dans les boutiques à proximité.';
export const LBL_DARJEELING = 'Darjeeling';
