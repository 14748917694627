import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '../../../design-system';

type Props = {
  name: string;
  haveSubMenu?: boolean;
  onClickClose?: () => void;
  onClickBack?: () => void;
  isFadingIn?: boolean;
  isFadingOut?: boolean;
};

const StyledMobileMenuHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  .menu-header__go-back-button {
    margin-left: -16px;
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-header__title-container {
    flex: 1 0 auto;
    transition: opacity 300ms ease-out;
    &.fadeOut {
      opacity: 0;
    }
    &.fadeIn {
      opacity: 1;
    }
  }
  .menu-header__title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
  .menu-header__close-button {
    margin-right: -12px;
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileMenuHeader = ({
  name,
  haveSubMenu,
  onClickClose,
  onClickBack,
  isFadingIn,
  isFadingOut,
}: Props) => {
  return (
    <StyledMobileMenuHeader
      name={name}
      haveSubMenu={haveSubMenu}
      isFadingIn={isFadingIn}
      isFadingOut={isFadingOut}
    >
      {haveSubMenu && (
        <button
          className="menu-header__go-back-button"
          data-testid="menu-header-go-back-button"
          onClick={onClickBack}
          type="button"
          aria-label="Bouton pour revenir à la page précédente du menu"
        >
          <Icon name="chevronLeft" size={20} />
        </button>
      )}
      <div
        className={`menu-header__title-container ${
          isFadingOut ? 'fadeOut' : isFadingIn ? 'fadeIn' : ''
        }`}
      >
        <h2 className="menu-header__title">{name}</h2>
      </div>
      <button
        className="menu-header__close-button"
        data-testid="menu-header-close-button"
        onClick={onClickClose}
        type="button"
        aria-label="Bouton pour fermer le menu"
      >
        <Icon name="close" size={20} />
      </button>
    </StyledMobileMenuHeader>
  );
};
