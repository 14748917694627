import { DynamicButtonStatus } from '../../design-system';

export type Callback = {
  form: Forms;
  values: FormValues;
};

export type FormValues = {
  [key: string]: FieldType;
};

export type FieldCallback = {
  key: string;
  value: FieldType;
};

export type FormFieldCallback = {
  form: Forms;
} & FieldCallback;

export type FocusCallback = {
  form: Forms;
  key: string;
};

export type FieldType = string | boolean;

export type FieldRefs = { [x in string]: React.RefObject<HTMLInputElement> };

export type FeedbackCallback = {
  form: Forms;
} & Feedback;

export type Feedback = {
  ok: boolean;
  message: string;
  isDirty?: boolean;
};

export type CtaStateCallback = {
  form: Forms;
  ctaState: DynamicButtonStatus;
};

export enum Forms {
  affiliate = 'affiliate',
  signInEmail = 'signInEmail',
  signInPassword = 'signInPassword',
  signUp = 'signUp',
  forgotPassword = 'forgotPassword',
  personal = 'personal',
  newsletter = 'newsletter',
  address = 'address',
  delivery = 'delivery',
  shipping = 'shipping',
  billing = 'billing',
  payment = 'payment',
  socialLogin = 'socialLogin',
  footerNewsletter = 'footerNewsletter',
  eReservation = 'eReservation',
  eResaRequest = 'eResaRequest',
}

export type FieldAffiliate = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
};

export type FieldsSignInEmail = {
  email: string;
};

export type FormSignInEmail = {
  validation: FieldsSignInEmail;
  values: FieldsSignInEmail;
  ctaState: DynamicButtonStatus;
};

export type FieldsSignInPassword = {
  password: string;
};

export type FormSignInPassword = {
  validation: FieldsSignInPassword;
  values: FieldsSignInPassword;
  ctaState: DynamicButtonStatus;
};

export type FieldsSignIn = {
  email: string;
  password: string;
};

export type FormSignIn = {
  validation: FieldsSignIn;
  values: FieldsSignIn;
  ctaState: DynamicButtonStatus;
};

export type FieldsSignUp = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  optInEmail: boolean;
};

export type FormSignUp = {
  validation: FieldsSignUp;
  values: FieldsSignUp;
  ctaState: DynamicButtonStatus;
};

export type FieldsPersonal = {
  prefix: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  password: string;
};

export type FormAffiliate = {
  validation: FieldAffiliate;
  values: FieldAffiliate;
  focus: string;
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
};

export type FormPersonal = {
  validation: FieldsPersonal;
  values: FieldsPersonal;
  focus: string;
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
};

export type FormDelivery = {
  validation: {
    giftMessage: string;
    pickupPhone: string;
    isConditionsAccepted: string;
  };
  values: {
    showBilling: boolean;
    showGiftMessage: boolean;
    isConditionsAccepted: boolean;
    giftMessage: string;
    pickupPhone: string;
  };
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
  focus: string;
};

export type FieldsAddress = {
  id: string;
  name: string;
  company: string;
  idTva: string;
  siret: string;
  firstName: string;
  lastName: string;
  street: string;
  streetAdditional: string;
  postal: string;
  city: string;
  phone: string;
  country: string;
};

export type CartForms = {
  shipping: FormAddress;
  billing: FormAddress;
  delivery: FormDelivery;
  payment: FormPayment;
};

export type FormAddress = {
  validation: FieldsAddress;
  values: FieldsAddress;
  focus: string;
  feedback: Feedback;
  ctaState?: DynamicButtonStatus;
};

export type FormPayment = {
  validation: {};
  values: {};
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
};

export type FormNewsletter = {
  validation: {};
  values: {
    optInEmail: boolean;
    optInSMS: boolean;
  };
  feedback: Feedback;
};

export type FieldsForgotPassword = {
  email: string;
  password: string;
};

export type FormForgotPassword = {
  validation: FieldsForgotPassword;
  values: FieldsForgotPassword;
  ctaState: DynamicButtonStatus;
  feedback: Feedback;
};

export type FormSocialLogin = {
  feedback: Feedback;
  validation: {};
  values: {};
};

export type FormFooterNewsletter = {
  validation: {
    email: string;
  };
  values: {
    email: string;
  };
  ctaState: DynamicButtonStatus;
};

export type FieldsEReservation = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type FormEReservation = {
  validation: FieldsEReservation;
  values: FieldsEReservation;
  focus: string;
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
};

export type FormEResaRequest = {
  validation: {
    isConditionsAccepted: string;
  };
  values: {
    isConditionsAccepted: boolean;
  };
  focus: string;
  feedback: Feedback;
  ctaState: DynamicButtonStatus;
};

export type State = {
  affiliate: FormAffiliate;
  signInEmail: FormSignInEmail;
  signInPassword: FormSignInPassword;
  signIn: FormSignIn;
  signUp: FormSignUp;
  personal: FormPersonal;
  delivery: FormDelivery;
  shipping: FormAddress;
  billing: FormAddress;
  address: FormAddress;
  payment: FormPayment;
  newsletter: FormNewsletter;
  forgotPassword: FormForgotPassword;
  socialLogin: FormSocialLogin;
  footerNewsletter: FormFooterNewsletter;
  eReservation: FormEReservation;
  eResaRequest: FormEResaRequest;
};
