import React from 'react';

import { Box, Text, Button } from '../../../design-system';
import paths from '../../routing/paths';
import { Store as StoreType } from '../types';
import OpeningHours from './OpeningHours';
import { KNOW_MORE } from '../../home/locale';

export type Props = {
  store: StoreType;
  push(path: string): void;
};

export const InfoWindow = ({ store }: Props) => {
  const { id, name, routableCoordinate, displayCoordinate, hours, address } = store ?? {};
  const { line1, line2, postalCode, city } = address ?? {};
  let isDelivery = false;

  if (window) {
    isDelivery = window.location.pathname !== paths.STORE_LOCATOR;
  }

  return (
    <Box display="grid" gridGap="s" gridAutoRows="min-content" maxWidth="300px">
      <Text id={`store-info-window-${id}-name`} preset="subheading" textTransform="uppercase">
        {name ?? ''}
      </Text>
      <Text id={`store-info-window-${id}-address`} preset="caption" color="GREY">
        {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
      </Text>
      <OpeningHours hours={hours ?? {}} isShortenedVersion isBigSize />
      <Box display="grid" gridAutoColumns="1fr 1fr" gridAutoFlow="column" gridGap="s">
        <a
          data-testid={`info-window-go-to-store-${id}-page-anchor`}
          id={`info-window-go-to-store-${id}-page-anchor`}
          href={`${paths.STORE_LOCATOR}/${id}`}
          target={isDelivery ? '_blank' : '_self'}
          rel={isDelivery ? 'noreferrer' : undefined}
          aria-label={`Bouton pour aller à la page du magasin ${name}`}
        >
          <Button id="info-window-go-to-store-page-btn">
            <Text color="WHITE" mx="-8px">
              {KNOW_MORE}
            </Text>
          </Button>
        </a>
        <a
          data-testid={`info-window-get-directions-anchor-to-store-${id}`}
          id={`info-window-get-directions-anchor-to-store-${id}`}
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/maps/dir/?api=1&destination=${
            routableCoordinate?.latitude || displayCoordinate?.latitude
          },${routableCoordinate?.longitude || displayCoordinate?.longitude}`}
          aria-label={`Bouton pour obtenir un itinéraire vers le magasin ${name}`}
        >
          <Button id="info-window-get-directions-btn" preset="subtle">
            <Text color="GREY">Itinéraire</Text>
          </Button>
        </a>
      </Box>
    </Box>
  );
};
