import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import { css } from '@emotion/core';

import { Box, Button, Divider, Text, Icon } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { updateStep } from '../actions';
import { FilterList } from './FilterList';
import { HIERARCHICAL_ITEMS } from '../../catalog/components/HierarchicalMenu';
import { MobileFilterSelector } from './MobileFilterSelectorHook';

type Props = {
  nbHits: number;
  onClose: () => void;
};

const ClearRefinementFilters: React.FC<{ attribute?: string; clearAll?: () => void }> = ({
  attribute,
  clearAll,
}) => {
  const { refine, canRefine } = useClearRefinements({ includedAttributes: [attribute ?? ''] });
  if (!canRefine && !clearAll) {
    return null;
  }
  return (
    <button
      type="button"
      className="anchor-animated"
      data-testid={`mobile-btn-reset-${clearAll ? 'all-filters' : 'filter'}`}
      aria-label={`Bouton pour réinitialiser ${clearAll ? 'tous' : ''} les filtres`}
      id={`mobile-btn-reset-${clearAll ? 'all-filters' : 'filter'}`}
      onClick={clearAll ? clearAll : refine}
      css={css`
        font-size: 1.2rem;
      `}
    >
      {clearAll ? 'Tout Réinitialiser' : 'Réinitialiser'}
    </button>
  );
};

export const MobileFilterBar = ({ onClose, nbHits }: Props) => {
  const { items } = useCurrentRefinements({ excludedAttributes: [...HIERARCHICAL_ITEMS, 'query'] });
  const { refine, canRefine } = useClearRefinements({
    excludedAttributes: [...HIERARCHICAL_ITEMS, 'query'],
  });
  const dispatch = useDispatch();

  const {
    mobile: { step },
  } = useSelector((state: RootState) => state.filters);
  const isItemsRefined =
    step >= 0 && items?.some((item) => FilterList[step].attribute === item.attribute);

  return (
    <Box display="grid" position="relative" height="100%" gridTemplateRows="auto 80px">
      <Box>
        <Box width="100%" position="sticky" top={0} bg="WHITE" display="grid" pt="l" zIndex={1}>
          <Box
            display="grid"
            gridTemplateColumns="90px auto 90px"
            alignItems="center"
            justifyContent="space-between"
            mb="m"
          >
            <Icon
              name="chevronLeft"
              size={14}
              onClick={() => {
                step === -1 ? onClose() : dispatch(updateStep(-1));
              }}
              id="filter-bar-go-back"
            />
            <Text
              preset="subheading"
              textTransform="uppercase"
              justifySelf="center"
              id="filter-bar-title"
            >
              {step === -1 ? 'Filtres' : FilterList[step].title}
            </Text>
            <Box justifySelf="right">
              <ClearRefinementFilters
                clearAll={step === -1 && canRefine ? refine : undefined}
                attribute={FilterList[step]?.attribute}
              />
            </Box>
          </Box>
          <Divider color="BLACK" />
        </Box>
        <Box display={step === -1 ? 'block' : 'none'}>
          {FilterList.map((filter, index) => (
            <MobileFilterSelector
              attribute={filter.attribute}
              index={index}
              title={filter.title}
              onClick={() => dispatch(updateStep(index))}
              key={`mobile-filter-selector-${filter.attribute}-${index}`}
            />
          ))}
        </Box>
        <Box display={step > -1 ? 'block' : 'none'}>
          {FilterList.map((filter, index) => (
            <Box key={index} display={step === index ? 'block' : 'none'}>
              {filter.component}
            </Box>
          ))}
        </Box>
      </Box>
      <Box position="sticky" bottom={0} bg="WHITE" width="100%" py="m" alignSelf="end">
        <Button
          id="btn-filter-bar-apply-filter"
          type="button"
          data-testid="mobile-filter-bar-apply-filter"
          aria-label={`Bouton pour ${step === -1 ? 'fermer les' : 'revenir à la liste des'} filtres`}
          onClick={() => {
            step === -1 ? onClose() : dispatch(updateStep(-1));
          }}
        >
          {step === -1 && items.length > 0
            ? `Voir ${nbHits} ${nbHits === 1 ? 'résultat' : 'résultats'}`
            : step === -1
              ? 'Fermer'
              : isItemsRefined
                ? 'Appliquer'
                : 'Retour'}
        </Button>
      </Box>
    </Box>
  );
};
