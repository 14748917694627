import React, { useEffect, useState, RefObject } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Icon, Button, opacities, durations } from '../../../design-system';
import { Product, Size } from '../../product/types';
import { getImageList, getSizeLabel } from '../../product/utils';
import { ImageContainer, Picture } from '../../../design-system';
import { RootState } from 'src/shared/store/rootReducer';
import { formatPrice } from '../../common/utils';
import { EresaSizeSelector } from './EresaSizeSelector';
import Availability from '../../product/components/Availability';
import { setEresaAdditionnalList } from '../actions';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import {
  ERESA_DELETE_TEXT,
  ERESA_DELETE_TEXT_MOBILE,
  ERESA_COLOR,
  ERESA_SIZE,
  ADD_TO_ERESA,
  ERESA_OUT_OF_STOCK,
} from '../locale';
import { addToECart, bulkRemoveEreservation } from '../../ecart/actions';
import { capitalize, checkECartValidity } from '../utils';

type Props = {
  product: Product;
  isAdditionalProduct?: boolean;
  step: number;
  setIsRecentlyAdded?: (value: boolean) => void;
  refErrorOnAdditionalProduct?: RefObject<HTMLDivElement>;
};

export const EresaProductCard = ({
  product,
  isAdditionalProduct = false,
  step,
  setIsRecentlyAdded = () => null,
  refErrorOnAdditionalProduct,
}: Props) => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useMediaQueries();
  const [deleteLinkOpacity, setDeleteLinkOpacity] = useState(1);
  const [isSizeError, setIsSizeError] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [isError, setIsError] = useState(false);
  const {
    images,
    productRef,
    productName,
    colorRef,
    colorLabel,
    sizeVariants,
    detailedColorLabel,
  } = product ?? {};
  const { items } = useSelector((state: RootState) => state.ecart.ecart);
  const { additionnals_products, isLoading } = useSelector((state: RootState) => state.ecart);
  const storeId = useSelector((state: RootState) => state.ecart.ecart.storeInfo?.id);
  const objectID = productRef + '-' + colorRef;
  const image = getImageList({
    imagePositions: images ?? [],
    productRef,
    productName,
    colorRef,
    colorLabel,
  })?.[0]?.default;

  const isOnMainList =
    additionnals_products?.some(
      (it: Product) => it.productRef === productRef && it.colorRef === colorRef
    ) ?? false;

  const isInStock =
    isAdditionalProduct &&
    selectedSize !== '' &&
    (sizeVariants
      ?.find((size: Size) => getSizeLabel(size) === selectedSize)
      ?.storesAvailable.find((item) => item.store === storeId)?.quantity ?? 0) > 0;

  const onAddClick = () => {
    if (!isLoading) {
      if (selectedSize === '' || !isInStock) {
        setIsSizeError(true);
      } else {
        dispatch(
          addToECart({
            colorRef,
            productRef,
            sku: product.sizeVariants.filter(
              (size: Size) => getSizeLabel(size) === selectedSize
            )?.[0].sku,
          }) as unknown as UnknownAction
        );
        dispatch(
          setEresaAdditionnalList([
            ...additionnals_products,
            {
              ...product,
              sizeVariants: product.sizeVariants.filter(
                (size: Size) => getSizeLabel(size) === selectedSize
              ),
            },
          ])
        );
        setIsRecentlyAdded(true);
      }
    }
  };

  const onRemoveClick = () => {
    if (!isLoading) {
      const product = additionnals_products.find(
        (it: Product) => it.productRef === productRef && it.colorRef === colorRef
      );
      if (product) {
        const index = additionnals_products.indexOf(product);
        const listOfItems = [...additionnals_products];
        listOfItems.splice(index, 1);
        dispatch(setEresaAdditionnalList(listOfItems));
        dispatch(
          bulkRemoveEreservation([
            {
              productRef: product.productRef,
              colorRef: product.colorRef,
              sku: product.sizeVariants[0].sku,
            },
          ]) as unknown as UnknownAction
        );
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSizeError) {
      timer = setTimeout(() => setIsSizeError(false), durations.FOCUS_DELAY);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSizeError]);

  useEffect(() => {
    if (items && items.length > 0) {
      let itemsValidity = true;
      items.forEach((item) => {
        if (item.productRef === productRef && item.colorRef === colorRef) {
          itemsValidity = checkECartValidity([item]);
        }
      });
      setIsError(!itemsValidity);
    }
  }, [items]);

  useEffect(() => {
    if (refErrorOnAdditionalProduct?.current && isMobile) {
      refErrorOnAdditionalProduct.current.scrollIntoView();
    }
  }, [isError]);

  return (
    <>
      {isError && isAdditionalProduct && isOnMainList && (
        <Box
          id={`eresa-product-card-error-block-${objectID}`}
          ref={refErrorOnAdditionalProduct}
          width="100%"
          height="42px"
          p="s"
          fontSize="12px"
          lineHeight="110%"
          color="#E31D29"
          border="1px solid #E31D29"
          display="flex"
          alignItems="center"
          justifyContent="center"
          data-cy="ereservation-product-card-error-remove-product"
          onClick={onRemoveClick}
        >
          {ERESA_OUT_OF_STOCK}
        </Box>
      )}
      <Box
        width="100%"
        id={`eresa-product-card-${objectID}`}
        mt={
          !isAdditionalProduct
            ? '12px'
            : !isOnMainList
              ? '12px'
              : isAdditionalProduct && isOnMainList && !isError
                ? 'l'
                : 'na'
        }
        mb={isOnMainList ? 'l' : '12px'}
        borderBottom={isError && isAdditionalProduct && isOnMainList ? '1px solid #E31D29' : 'none'}
        borderLeft={isError && isAdditionalProduct && isOnMainList ? '1px solid #E31D29' : 'none'}
        borderRight={isError && isAdditionalProduct && isOnMainList ? '1px solid #E31D29' : 'none'}
        display="flex"
      >
        <Box
          width="100%"
          display="flex"
          opacity={isError && isAdditionalProduct && isOnMainList ? opacities.DISABLED : 1}
        >
          <Box
            width="120px"
            height="154px"
            minWidth="120px"
            minHeight="154px"
            maxWidth="120px"
            maxHeight="154px"
            mr="s"
            overflow="hidden"
          >
            <ImageContainer>
              <Picture>
                <source srcSet={image?.webp} type="image/webp" />
                <source srcSet={image?.jpg} type="image/jpeg" />
                <img id={`eresa-modal-${objectID}-image`} src={image?.jpg} alt={image?.alt} />
              </Picture>
            </ImageContainer>
          </Box>

          <Box
            width={isMobile || isTablet ? 'calc(100% - 136px)' : '100%'}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box width="100%">
              <Box
                mt={isOnMainList && isAdditionalProduct && isError ? 'm' : 'na'}
                display="flex"
                justifyContent="space-between"
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Box
                  width={isMobile ? '100%' : '70%'}
                  fontSize="12px"
                  lineHeight="14px"
                  pr={isMobile ? 'na' : 'm'}
                >
                  {productName ? capitalize(productName) : 'Produit - Catégorie'}
                </Box>
                <Box
                  width={isMobile ? '100%' : '30%'}
                  textAlign={isMobile ? 'left' : 'right'}
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight={700}
                  pr={isMobile ? 'na' : 's'}
                  mt={isMobile ? 's' : 'na'}
                >
                  {!isAdditionalProduct
                    ? formatPrice(product.sizeVariants[0].storePrice)
                    : selectedSize === ''
                      ? formatPrice(product.storePrice)
                      : formatPrice(
                          product.sizeVariants.find(
                            (size: Size) => getSizeLabel(size) === selectedSize
                          )?.storePrice ?? product.storePrice
                        )}
                </Box>
              </Box>
              {isAdditionalProduct && !isOnMainList && (
                <Box mt={isMobile ? 'xs' : 's'} ml="-8px" opacity={selectedSize !== '' ? 1 : 0}>
                  <Availability isInStock={isInStock} />
                </Box>
              )}
              <Box
                fontSize="12px"
                mb={!isOnMainList && isMobile ? 's' : '12px'}
                mt={
                  isAdditionalProduct && isMobile && !isOnMainList
                    ? 'xs'
                    : isAdditionalProduct && !isOnMainList
                      ? 's'
                      : 'm'
                }
              >
                {ERESA_COLOR}
                <span style={{ fontSize: '12px', fontWeight: 700, marginLeft: '4px' }}>
                  {detailedColorLabel ?? colorLabel}
                </span>
              </Box>
              {(isOnMainList || !isAdditionalProduct) && (
                <Box fontSize="12px" mt="m" mb={isOnMainList && isError && !isMobile ? 'xs' : 'm'}>
                  {ERESA_SIZE}
                  <span style={{ fontSize: '12px', fontWeight: 700, marginLeft: '4px' }}>
                    {getSizeLabel(product.sizeVariants?.[0]) ?? ''}
                  </span>
                </Box>
              )}
              {!isOnMainList && isAdditionalProduct && (
                <EresaSizeSelector
                  product={product}
                  selectedSize={selectedSize}
                  setSelectedSize={setSelectedSize}
                  isSizeError={isSizeError}
                />
              )}
            </Box>
            {isAdditionalProduct && isOnMainList && step < 4 && !isError && (
              <Box
                onMouseEnter={() => setDeleteLinkOpacity(opacities.HOVERED)}
                onMouseLeave={() => setDeleteLinkOpacity(1)}
                opacity={deleteLinkOpacity}
                fontSize="12px"
                display="flex"
                justifyContent="end"
                pr={isMobile ? 'na' : 's'}
                data-cy="ereservation-product-card-remove-link"
                onClick={onRemoveClick}
              >
                <Icon name="trash" size={12} />
                <Box fontSize="12px" ml="xs">
                  {isMobile ? ERESA_DELETE_TEXT_MOBILE : ERESA_DELETE_TEXT}
                </Box>
              </Box>
            )}
            {isAdditionalProduct && !isOnMainList && (
              <Box
                width={isMobile || isTablet ? '100%' : '218px'}
                maxWidth={isMobile ? '100%' : '218px'}
                mt="s"
                cursor={selectedSize === '' || !isInStock ? 'default !important' : 'pointer'}
              >
                <Button
                  type="button"
                  aria-label="Bouton pour ajouter un produit à votre reservation"
                  data-testid="ereservation-product-card-add-button"
                  id="eresa-modal-remove-button"
                  onClick={onAddClick}
                  preset={isMobile || isTablet ? 'eresaModal' : 'subtle'}
                  disabled={selectedSize === '' || !isInStock}
                >
                  {ADD_TO_ERESA}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
