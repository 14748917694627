import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { wishlistItemsSelector } from '../selectors';
import { WishlistItem } from './WishlistItem';
import locale from '../locale';

const StyledWislistItemList = styled.div`
  .empty-wishlist-title {
    margin: 16px 0;
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

type Props = {
  isMini?: boolean;
};

export const WishlistItemList = ({ isMini = false }: Props) => {
  const items = useSelector((state: RootState) => wishlistItemsSelector(state));

  return (
    <StyledWislistItemList id="wishlist-item-list">
      {items.length < 1 ? (
        <p className="empty-wishlist-title">{locale.EMPTY_WISHLIST_TITLE}</p>
      ) : (
        <div id="box-wishlist-item-list">
          {items.map((item) => {
            const id = `${item.productRef}${item.colorRef}${item.sku ?? ''}`;
            return <WishlistItem key={id} id={id} item={item} isMini={isMini} />;
          })}
        </div>
      )}
    </StyledWislistItemList>
  );
};
