import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Text, Button } from '../../../design-system';
import { opacities } from '../../common/constants';
import { RootState } from '../../../store/rootReducer';
import { getPickUpStations } from '../actions';
import { PickupModalMobileSteps, PickupStation, Suggestion } from '../types';
import Search from './Search';
import { ERR_REQUIRED } from '../../form/locale';
import ResultsTitle from '../../store-locator/components/ResultsTitle';
import {
  MSG_NO_PICKUP_STATION_FOUND,
  SELECTED_PICKUP_STATION,
  SELECT_PICKUP_STATION,
  ERR_LOCATION,
} from '../locale';
import { PickupStationsList } from './PickupStationsList';
import { BackButton } from '../../common/components/BackButton';
import Map from '../../store-locator/components/Map';
import { createMap, createPositionMarker, GEO_LOCATE_TIMEOUT_MS } from '../../store-locator/utils';
import useMarkers from '../hooks/useMarkers';
import { Position, ZoomLevels } from '../../store-locator/types';
import { setPickupStationId, removePickupStationInfo } from '../../cart/actions';
import { closeModal } from '../../common/actions';
import { StoreAutoComplete } from '../../store-locator/components/StoreAutoComplete';
import useGoogleMapsAutocomplete from '../../common/hooks/useGoogleMapsAutocomplete';
import { getAddress } from '../../api';
import { ERR_LOCATION_NOT_ACTIVE } from '../../store-locator/locale';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { emptyPickupStation } from '../state';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

const DEFAULT_GEO_LOCATION = {
  lat: 48.8573399,
  lng: 2.3515768,
};

type Props = {
  search?: string;
  toBePositioned?: boolean;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  autocomplete?: google.maps.places.AutocompleteService;
};

export const PickupModal = ({ search, toBePositioned, sessionToken, autocomplete }: Props) => {
  const dispatch = useDispatch();
  const { isDesktop, isMobile, isTablet } = useMediaQueries();
  const isFetching = useSelector((state: RootState) => state.pickup.isFetching);
  const { pickupStation } = useSelector((state: RootState) => state.cart.cart);
  const pickupStations = useSelector((state: RootState) => state.pickup.pickupStations) ?? [];
  const selectedStation = pickupStations.find((station) => station.id === pickupStation?.id);
  const geoCoordinate = useSelector((state: RootState) => state.pickup.geoCoordinate);

  const initialZoomLevel =
    search || toBePositioned || selectedStation ? ZoomLevels.DISTRICT : ZoomLevels.COUNTRY;

  const initialPosition = selectedStation
    ? {
        lat: selectedStation.displayCoordinate.latitude,
        lng: selectedStation.displayCoordinate.longitude,
      }
    : { lat: DEFAULT_GEO_LOCATION.lat, lng: DEFAULT_GEO_LOCATION.lng };

  const [searchText, setSearchText] = React.useState(search ?? '');
  const [lastSearch, setLastSearch] = React.useState(search ?? '');
  const [isLocating, setIsLocating] = React.useState(false);
  const [step, setStep] = React.useState(PickupModalMobileSteps.LISTING);
  const [errMsg, setErrMsg] = React.useState('');
  const [map, setMap] = React.useState<google.maps.Map>();
  const [selectedMarker, setSelectedMarker] = React.useState('');
  const [positionMarker, setPositionMarker] = React.useState<google.maps.Marker>();
  const [isPositioned, setIsPositioned] = React.useState(false);
  const [currentPosition, setCurrentPosition] = React.useState<Position>();
  const [forceAutcompleteClosing, setForceAutcompleteClosing] = React.useState<boolean>(false);

  const pickupStationRefs = pickupStations.reduce((acc, _value, currentIndex) => {
    acc[currentIndex] = React.createRef();
    return acc;
  }, {});

  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const initMap = async (element: HTMLDivElement) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const { map } = await createMap({
        center: initialPosition,
        zoom: initialZoomLevel,
        element,
      });
      setMap(map);
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
  }, [mapRef]);

  React.useEffect(() => {
    if (map) {
      if (toBePositioned) {
        handleLocate();
      } else if (selectedStation) {
        setSelectedMarker(selectedStation.id);
      }
    }
  }, [map]);

  React.useEffect(() => {
    const lat = geoCoordinate?.latitude;
    const lng = geoCoordinate?.longitude;

    if (lat && lng && map) {
      map.setCenter({ lat, lng });
    }
  }, [geoCoordinate, map]);

  React.useEffect(() => {
    if (!pickupStations?.length) {
      dispatch(removePickupStationInfo(emptyPickupStation));
    }
  }, [pickupStations]);

  const getPickUpStationsList = (search: string) => {
    dispatch(getPickUpStations(true, search) as unknown as UnknownAction);
  };

  const handleClickBack = () => {
    setStep(PickupModalMobileSteps.LISTING);
  };

  const handleChange = (value: string) => {
    setForceAutcompleteClosing(false);
    setSearchText(value);

    if (errMsg) {
      setErrMsg('');
    }
  };

  const handleLocate = () => {
    if (positionMarker) {
      positionMarker.setMap(null);
    }

    if (navigator.geolocation && map) {
      setIsLocating(true);
      setSearchText('');
      setErrMsg('');
      navigator.geolocation.getCurrentPosition(
        async function (pos) {
          const { coords } = pos;
          const { latitude, longitude } = coords;
          const position = {
            lat: latitude,
            lng: longitude,
          };
          map.setZoom(ZoomLevels.DISTRICT);
          map.setCenter(position);
          dispatch(getPickUpStations(false, '', { ...position }) as unknown as UnknownAction);
          createPositionMarker({ map, position });
          setPositionMarker(positionMarker);
          setIsPositioned(true);
          setIsLocating(false);
          setCurrentPosition(position);
          const city = await getAddress(position.lat, position.lng);
          handleAutoComplete(
            `${city.address.road ?? ''} ${city.address.postcode ?? ''} ${city.address.city ?? ''}`
          );
        },
        function () {
          setErrMsg(ERR_LOCATION_NOT_ACTIVE);
          setIsLocating(false);
        },
        {
          timeout: GEO_LOCATE_TIMEOUT_MS,
        }
      );
    } else {
      setErrMsg(ERR_LOCATION);
    }
  };

  const handleSearch = () => {
    if (searchText) {
      setLastSearch(searchText);
      if (isPositioned) {
        setIsPositioned(false);
        setCurrentPosition(undefined);
      }
      setSelectedMarker('');
      setErrMsg('');
      map?.setZoom(ZoomLevels.DISTRICT);
      getPickUpStationsList(searchText);
    } else {
      setErrMsg(ERR_REQUIRED);
    }
  };

  const handleStationSelect = (station: PickupStation) => {
    dispatch(setPickupStationId(station));
    dispatch(closeModal());
  };

  const handleMapLinkClick = () => {
    setStep(PickupModalMobileSteps.MAP);
  };

  const handleAutoComplete = (search: string) => {
    setSearchText(search);
    getPickUpStationsList(search);
    setForceAutcompleteClosing(true);
  };

  React.useEffect(() => {
    if (step === PickupModalMobileSteps.MAP && map) {
      const selectedStation = pickupStations.find((station) => station.id === selectedMarker);

      if (selectedStation) {
        map.setZoom(ZoomLevels.DISTRICT);
        map.setCenter({
          lat: selectedStation.displayCoordinate.latitude + 0.01,
          lng: selectedStation.displayCoordinate.longitude,
        });
      }
    }
  }, [step, map]);

  useMarkers({ map, stations: pickupStations, selectedMarker, onClick: setSelectedMarker });

  const predictions = useGoogleMapsAutocomplete(sessionToken, autocomplete, searchText);

  const suggestionsList: Suggestion[] = predictions.map((prediction) => {
    return { name: prediction.description };
  });

  const showMap = isDesktop || isTablet || step === PickupModalMobileSteps.MAP;
  const showList = isDesktop || isTablet || step === PickupModalMobileSteps.LISTING;
  const showSecondStep = !isDesktop && !isTablet && step === PickupModalMobileSteps.MAP;

  return (
    <Box
      display="grid"
      gridGap="l"
      gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
      gridAutoRows="500px"
      gridTemplateAreas={isMobile ? 'initial' : `'list map'`}
    >
      {showList && (
        <Box
          display="grid"
          gridGap="m"
          gridAutoRows="min-content"
          gridArea={!isMobile ? 'list' : 'initial'}
          opacity={isFetching ? opacities.LOADING : 1}
        >
          <Box position="relative">
            <Search
              onIconClick={handleSearch}
              onChange={handleChange}
              onClick={handleLocate}
              setForceAutcompleteClosing={setForceAutcompleteClosing}
              value={searchText}
              errMsg={errMsg}
              isPositioned={isPositioned}
              isPickupDelivery
            />
            <StoreAutoComplete
              suggestionsList={suggestionsList}
              query={searchText}
              onClick={handleAutoComplete}
              forceAutcompleteClosing={forceAutcompleteClosing}
              isPositionAbsolute
            />
          </Box>

          <Box>
            <ResultsTitle
              lastSearch={lastSearch}
              isPositioned={isPositioned}
              nbResults={pickupStations.length}
              isPickupDelivery
            />
            {pickupStations.length < 1 ? (
              !isFetching && !isLocating && map ? (
                <Text my="m" color="ERROR">
                  {MSG_NO_PICKUP_STATION_FOUND}
                </Text>
              ) : isMobile ? (
                <Box position="relative" height={isDesktop ? '400px' : 'calc(100vh - 184px)'}>
                  <LoadingScreen />
                </Box>
              ) : null
            ) : (
              <Box
                overflowX="hidden"
                overflowY="scroll"
                scrollBehavior="smooth"
                maxHeight={!isMobile ? '410px' : 'calc(100vh - 184px)'}
                mb={isDesktop ? 'na' : 'm'}
                position="relative"
              >
                {isMobile && isLocating && <LoadingScreen />}
                <PickupStationsList
                  selectedStationId={selectedStation ? selectedStation.id : undefined}
                  onClick={setSelectedMarker}
                  onSelect={handleStationSelect}
                  onMapLinkClick={handleMapLinkClick}
                  pickupStations={pickupStations}
                  selectedMarker={selectedMarker}
                  pickupStationRefs={pickupStationRefs}
                  currentPosition={currentPosition}
                  isPositioned={isPositioned}
                  searchText={searchText}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box
        display={showMap ? 'grid' : 'none'}
        gridTemplateRows={!isMobile ? '1fr' : 'auto 1fr auto'}
        gridGap={!isMobile ? 'na' : 'l'}
        gridArea={!isMobile ? 'map' : 'initial'}
        position="relative"
      >
        {isLocating && <LoadingScreen />}
        <Box display={showSecondStep ? 'block' : 'none'}>
          <BackButton id="store-modal-map-back" onClick={handleClickBack} />
        </Box>
        <Map mapRef={mapRef} />
        <Box display={showSecondStep ? 'block' : 'none'}>
          <Button
            type="button"
            id={`store-modal-map-button`}
            aria-label="Bouton pour sélectionner une station de livraison"
            data-testid="store-modal-map-button"
            preset={selectedMarker === selectedStation?.id ? 'secondary' : 'subtle'}
            onClick={() => {
              const selectedStationFromMap = pickupStations.find(
                (station) => station.id === selectedMarker
              );
              return selectedStationFromMap && handleStationSelect(selectedStationFromMap);
            }}
          >
            {selectedMarker === selectedStation?.id
              ? SELECTED_PICKUP_STATION
              : SELECT_PICKUP_STATION}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
