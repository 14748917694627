import styled from '@emotion/styled';

import { colors, opacities } from '../systemprovider';
import { ComponentPropsWithRef } from 'react';

type Preset =
  | 'primary'
  | 'primaryLight'
  | 'secondary'
  | 'subtle'
  | 'eresaModal'
  | 'eresa'
  | 'filter_sort_by';

export type Props = {
  id: string;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  preset?: Preset | Preset[];
  mini?: boolean;
  backgroundColor?: string;
  textColor?: string;
};

export const Button = styled.button<ComponentPropsWithRef<'button'> & Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 0 16px;
  width: 100%;
  height: 44px;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ textColor }) => textColor ?? colors.WHITE};
  background: ${({ backgroundColor }) => backgroundColor ?? colors.BLACK};
  border: none;
  border-radius: 8px;
  text-decoration: none;
  transition: all ease-in-out 0.5s;

  @media (hover: hover) {
    &:hover:not(:disabled) {
      cursor: pointer;
      opacity: ${opacities.HOVERED};
    }
  }

  &:disabled {
    opacity: ${opacities.DISABLED};
    pointer-events: none;
  }

  ${(props) => {
    switch (props.preset) {
      case 'eresa':
        return `
          color: ${colors.BLACK};
          background: ${colors.WHITE};
          border: 1px solid ${colors.BLACK};
          @media(hover: hover) {
            :hover {
              background: ${colors.BLACK};
              color: ${colors.WHITE};
            },
          },
          `;
      case 'eresaModal':
        return `
          color: ${colors.BLACK};
          background: transparent;
          border: 1px solid ${colors.BLACK};
          margin: 0;
        `;
      case 'primaryLight':
        return `
          color: ${colors.BLACK};
          background: ${colors.WHITE};
        `;
      case 'secondary':
        return `
          color: ${colors.BLACK};
          background: ${colors.LIGHT2};
        `;
      case 'subtle':
        return `
          color: ${colors.BLACK};
          background: transparent;
          border: 1px solid ${colors.BLACK};
      `;
      case 'filter_sort_by':
        return `
          max-width: 210px;
      `;
      default:
        return '';
    }
  }}

  ${(props) =>
    props.mini &&
    `
      height: 32px;
      width:48px;
    `};
`;

export default Button;
