import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { RootState } from '../../../store/rootReducer';
import { numberOfWishlistItemsSelector } from '../selectors';
import locale from '../locale';
import { WishlistBar } from './WishlistBar';
import { breakpoints, colors, theme } from '../../../design-system/systemprovider/constants';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { CustomIconToSvg } from '../../header/components/CustomIconToSvg';
import { closeSidebar, openSidebarNew } from '../../common/actions';

export const MiniWishlist = () => {
  const dispatch = useDispatch();

  const numberOfWishlistItems = useSelector((state: RootState) =>
    numberOfWishlistItemsSelector(state)
  );
  useMediaQueries();

  const handleClick = () => {
    dispatch(
      openSidebarNew({
        content: <WishlistBar isMini />,
        title: 'FAVORIS',
        direction: 'right',
      })
    );
    dispatch(closeSidebar());
  };

  return (
    <button
      className="header-mini-wishlist__link"
      type="button"
      data-testid="mini-wishlist-button"
      aria-label="Bouton pour ouvrir la liste des favoris"
      onClick={handleClick}
      css={css`
        background: unset;
        padding: 0;
        border: unset;
        color: #000;
        cursor: pointer;
        width: 100%;
        @media (min-width: ${breakpoints.M}px) {
          width: unset;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 44px;
          padding: 10px 16px;
          text-decoration: none;
          grid-gap: ${theme.space.xs};
          @media (min-width: ${breakpoints.M}px) {
            padding: 0;
            height: unset;
          }
        `}
      >
        <div
          className="box-mini-wishlist"
          css={css`
            position: relative;
            padding: 0px;
            height: 24px;
            @media (min-width: ${breakpoints.M}px) {
              padding: 2px;
            }
          `}
        >
          {numberOfWishlistItems > 0 && (
            <div
              className="mini-wishlist-number-of-items"
              css={css`
                position: absolute;
                top: 10px;
                right: -6px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: ${colors.ERROR};
                z-index: 10;
                display: none;
                @media (min-width: ${breakpoints.M}px) {
                  display: block;
                }
              `}
            >
              <p
                css={css`
                  margin-block-start: 0;
                  margin-block-end: 0;
                  font-size: ${theme.fontSizes.xs};
                  line-height: ${theme.lineHeights.xs};
                  color: ${colors.WHITE};
                `}
              >
                {numberOfWishlistItems}
              </p>
            </div>
          )}
          <CustomIconToSvg name="wishlist" />
        </div>
        <p
          css={css`
            font-size: ${theme.fontSizes.xs};
            line-height: ${theme.lineHeights.s};
            font-weight: 500;
            border-bottom: 0;
            color: ${colors.BLACK};
            margin: 0;
            text-transform: uppercase;
            @media (min-width: ${breakpoints.M - 1}px) {
              display: none;
            }
          `}
        >
          {locale.WISHLIST}
        </p>
      </div>
      <span className="sr-only">Ma liste de souhaits</span>
    </button>
  );
};
