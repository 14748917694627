import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { breakpoints, colors, Icon, opacities } from '../../../design-system';
import { scrollToTop } from '../../common/utils';
import paths from '../../routing/paths';
import { isItGiftCard, getProductIdsFromUrl } from '../../product/utils';
import { durations, zIndex } from '../../common/constants';

const StyledScrollBackToTopButton = styled.button<{
  isGiftCard: boolean;
  windowWidth: number;
  isNormalProductPage: boolean;
}>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${colors.BLACK};
  border: none;
  border-radius: 4px;
  right: 16px;
  bottom: ${({ isGiftCard }) => (isGiftCard ? '60px' : '16px')};
  z-index: ${zIndex.MODAL};
  transition: opacity ${durations.ENTER}ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: transparent;
  }

  @media (min-width: ${breakpoints.S}px) {
    display: ${({ isNormalProductPage }) => (isNormalProductPage ? 'none' : 'flex')};
    bottom: 24px;
  }

  @media (min-width: ${breakpoints.XL}px) {
    right: ${({ windowWidth }) =>
      windowWidth > breakpoints.XL ? `calc((100vw - ${breakpoints.XL}px) / 2)` : '16px'};
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      opacity: ${opacities.HOVERED};
    }
  }
`;

export const ScrollBackToTopButton = () => {
  const [isActive, setIsActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollListener = () => {
      setIsActive(window.scrollY > 600);
    };
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleWindowResize();
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isNormalProductPage =
    pathname.startsWith(paths.PRODUCT) && !isItGiftCard(getProductIdsFromUrl(pathname).productRef);

  const isGiftCardPage =
    pathname.startsWith(paths.PRODUCT) && isItGiftCard(getProductIdsFromUrl(pathname).productRef);

  return !isActive ? null : (
    <StyledScrollBackToTopButton
      id="scroll-top-header-button"
      data-testid="scroll-top-header-button"
      aria-label="Bouton pour revenir en haut de la page"
      aria-hidden={!isActive}
      type="button"
      isGiftCard={isGiftCardPage}
      isNormalProductPage={isNormalProductPage}
      windowWidth={windowWidth}
      onClick={scrollToTop}
    >
      <Icon name="arrowUp" color={colors.WHITE} size={24} />
    </StyledScrollBackToTopButton>
  );
};
