import * as React from 'react';
import styled from '@emotion/styled';

import css from '@styled-system/css';

import Text from '../../text';
import Divider from '../../divider';
import Icon from '../../icon';
import { useMediaQueries } from '../../../modules/common/hooks/useMediaQuery';
import { colors } from '../../systemprovider';

type Props = {
  id: string;
  title: string;
  icon?: string;
  isOpen: boolean;
  onClick: () => void;
  isSizeGuide: boolean;
  refContainer: React.RefObject<HTMLDivElement>;
  defaultTabIndex: number;
  index: number;
};

export const Tab = ({
  id,
  title,
  icon,
  isOpen,
  onClick,
  isSizeGuide,
  refContainer,
  defaultTabIndex,
  index,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { isMobile } = useMediaQueries();
  React.useEffect(() => {
    if (
      isMobile &&
      index === defaultTabIndex &&
      ref?.current &&
      refContainer?.current &&
      isSizeGuide
    ) {
      const containerWidth = refContainer.current.getBoundingClientRect().width;
      const elementWidth = ref.current.getBoundingClientRect().width;
      const targetLeft = ref.current.offsetLeft + (elementWidth - containerWidth) / 2;
      refContainer.current.scrollTo({ behavior: 'smooth', left: targetLeft });
    }
  }, [defaultTabIndex, ref?.current, refContainer?.current]);
  return (
    <StyledTab id={id} onClick={onClick} isSizeGuide={isSizeGuide} ref={ref}>
      <StyledTabText isOpen={isOpen} isSizeGuide={isSizeGuide}>
        {icon && <Icon name={icon} size={24} className="styled-tab__icon" />}
        {title}
      </StyledTabText>
      <Divider color={isOpen ? 'BLACK' : 'LIGHT'} my={!isSizeGuide ? 'm' : 'na'} />
    </StyledTab>
  );
};

type StyledTabTextProps = {
  isOpen: boolean;
  isSizeGuide?: boolean;
};
type StyledTabProps = {
  isSizeGuide?: boolean;
};

const StyledTab = styled.div<StyledTabProps>(
  css({
    flex: 'auto',
    textAlign: 'center',
    cursor: 'pointer',
  }),
  ({ isSizeGuide }) =>
    isSizeGuide && {
      flexGrow: 1,
      flexBasis: 0,
      minWidth: 'fit-content',
    }
);

const StyledTabText = styled(Text)<StyledTabTextProps>(
  css({
    fontWeight: 'regular',
    color: colors.GREY2,
    '&:hover': {
      color: 'inherit',
    },
    transition: 'color 0.2s ease-in-out',
  }),
  ({ isOpen }) =>
    isOpen && {
      fontWeight: 'bold',
      color: 'inherit',
    },
  ({ isSizeGuide }) =>
    isSizeGuide && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      padding: '13px 16px',
    }
);
