import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { breakpoints, colors, durations, Icon, opacities } from '../../design-system';
import { ModalPresetNew } from 'src/shared/modules/common/types';
import { withModalNew } from '../../modules/common/hocs/withModalNew';
import { useDispatch } from 'react-redux';
import { closeModalNew as closeModalNewAction } from '../../modules/common/actions';

type Props = {
  content: React.ReactNode;
  isOpen: boolean;
  closeModalNew: () => void;
  closeModal: () => void;
  /**
   * This props is used to configure a preset to define a precise size for the modal. The preset should only be used if the modal content is not known (quickAdd for example). In other cases (e.g. sizeGuide), the modal will automatically adapt to the size of the content.
   */
  preset?: ModalPresetNew;
};

const CustomModal = styled.dialog`
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
  border: none;
  padding: 0;
  opacity: 0;
  transform: scale(0);
  background: ${colors.WHITE};
  overflow: auto;
  outline: none;
  @media (min-width: ${breakpoints.M}px) {
    max-width: 885px;
  }
  @keyframes fadein {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes backdropFadeIn {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: rgb(0 0 0 / 25%);
    }
  }

  &[open] {
    animation: fadein 0.3s ease-in-out forwards;
  }

  &[open]::backdrop {
    animation: backdropFadeIn 0.5s ease-in-out forwards;
  }

  &.modal--small-variant {
    .modal__content {
      height: fit-content;
      width: 95%;
    }
    @media (min-width: ${breakpoints.M}px) {
      .modal__content {
        min-height: 550px;
        width: 800px;
      }
    }
  }
  &.modal--medium-variant {
    @media (min-width: ${breakpoints.S}px) {
      width: 95%;
    }
    @media (min-width: ${breakpoints.M}px) {
      width: unset;
    }

    .modal__content {
      height: auto;
      max-height: 100%;
    }
    @media (min-width: ${breakpoints.S}px) {
      .modal__content {
        height: 590px;
        max-height: 885px;
      }
    }
    @media (min-width: ${breakpoints.M}px) {
      .modal__content {
        width: 885px;
      }
    }
  }
  &.modal--large-variant {
    @media (min-width: ${breakpoints.S}px) {
      width: 95%;
    }
    @media (min-width: ${breakpoints.M}px) {
      width: fit-content;
    }

    .modal__content {
      height: auto;
      max-height: 100%;
    }
    @media (min-width: ${breakpoints.S}px) {
      .modal__content {
        height: 715px;
        max-height: 885px;
      }
    }
    @media (min-width: ${breakpoints.M}px) {
      .modal__content {
        width: 770px;
      }
    }
  }
  &.modal--mobileFull-variant {
    @media (max-width: ${breakpoints.S}px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      .modal__content {
        height: inherit;
      }
    }
  }
  &.modal--mobileSmall-variant {
    @media (max-width: ${breakpoints.S}px) {
      width: 95%;
      height: fit-content;
      max-height: 95%;
    }
  }
  &.modal--inheritWidth-variant {
    .modal__content {
      max-width: 90vw;
      @media (min-width: ${breakpoints.S}px) {
        min-width: unset;
      }
    }
  }

  .modal__close-button {
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .modal__content {
    width: 100%;
    height: inherit;
    transition: all 0.3s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: ${breakpoints.S}px) {
      max-width: 885px;
      min-width: 710px;
    }
  }
`;

const ModalNew = ({ content, closeModalNew, isOpen, preset, closeModal }: Props) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const { pathname } = useLocation();
  const handleClose = () => {
    modalRef?.current?.close();
    closeModalNew();
    closeModal();
  };

  useEffect(() => {
    if (isOpen) {
      modalRef?.current?.showModal();
      modalRef?.current?.focus();
      modalRef?.current?.blur();
    } else {
      handleClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      handleClose();
    }
  }, [pathname]);

  useEffect(() => {
    const handleBackdropClick = (event) => {
      if (event.target === modalRef.current) {
        handleClose();
      }
    };
    modalRef?.current?.addEventListener('click', handleBackdropClick);
    modalRef?.current?.addEventListener('close', () => dispatch(closeModalNewAction()));
  }, []);

  return (
    <CustomModal
      tabIndex={-1}
      ref={modalRef}
      className={`${preset ? `modal--${preset}-variant` : ''}`}
      id="modal"
    >
      <button
        className="modal__close-button"
        data-testid="modal-close-button"
        type="button"
        onClick={handleClose}
        aria-label="Bouton pour fermer la fenêtre modale"
      >
        <Icon name="close" size={20} />
      </button>
      <div className="modal__content">{content}</div>
    </CustomModal>
  );
};

export const ModalWithHoc = withModalNew(ModalNew);
