import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Helmet } from 'react-helmet-async';

import {
  Text,
  Box,
  Accordion,
  Button,
  opacities,
  durations,
  breakpoints,
  colors,
} from '../../../design-system';
import paths from '../../routing/paths';
import { cmsGenericInit } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { getUid } from '../utils';
import styled from '@emotion/styled';
import { hasValidCmsText } from '../../home/utils';

const CGV_UID = 'cgv';

export const Generic = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(-1);
  const { pathname } = useLocation();

  const cmsContent = useSelector((state: RootState) => state.cms.generic);
  const title = cmsContent?.title?.[0]?.text ?? '';
  const subtitle = cmsContent?.subtitle?.[0]?.text ?? '';
  const message = cmsContent?.message?.[0]?.text ?? '';
  const type = cmsContent?.type ?? '';

  const uid = pathname.startsWith(paths.CART) ? CGV_UID : getUid(pathname);

  React.useEffect(() => {
    if (uid) {
      dispatch(cmsGenericInit(uid) as unknown as UnknownAction);
    }
  }, []);

  if (uid !== type) {
    return null;
  }

  const items: { title: React.ReactNode; content: React.ReactNode }[] = (
    cmsContent?.body ?? []
  ).map((item) => {
    const title = item.primary?.title?.[0]?.text ?? '';
    if (!title) {
      return { title: '', content: '' };
    }

    const subItems: {
      title: React.ReactNode;
      content: React.ReactNode;
      index;
    }[] = (item.items ?? []).map((field, index) => ({
      title: <Text>{field.title?.[0]?.text ?? ''}</Text>,
      content: (
        <Box>
          {hasValidCmsText(field.content) && <PrismicRichText field={field.content} />}
          {hasValidCmsText(field.note) && (
            <Text color="GREY">
              <PrismicRichText field={field.note} />
            </Text>
          )}
        </Box>
      ),
      index,
    }));

    return {
      title,
      content: <Accordion id="accordion-generic" items={subItems} />,
    };
  });

  const metaTitle = `${title ? `${title} |` : ''} Darjeeling`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
      </Helmet>
      <Box display="flex" flexDirection="column" mx="m" my="xl" alignItems="center">
        <Box mb="m">
          <Text preset="heading">{title}</Text>
        </Box>
        <Text preset="subheading">{subtitle}</Text>
        <Box my="s" width={['100%', '640px']}>
          {(items ?? []).map((item, index) =>
            item.title ? (
              <Box key={index} my="m">
                <Button
                  id={`btn-faq-toggle-${index}`}
                  data-testid={`btn-faq-toggle-${index}`}
                  aria-label={`Bouton pour afficher le contenu de ${item.title}`}
                  type="button"
                  preset="secondary"
                  onClick={() => setOpen(open === index ? -1 : index)}
                >
                  {item.title}
                </Button>
                {index === open && item.content}
              </Box>
            ) : null
          )}
        </Box>
        {type === 'politique-de-cookies' ? (
          <>
            <StyledButton
              id="ot-sdk-btn"
              className="ot-sdk-show-settings"
              type="button"
              data-testid="ot-sdk-btn"
              aria-label="Bouton pour afficher les paramètres de cookies"
            >
              <p>{message}</p>
            </StyledButton>
            <div
              style={{ maxWidth: '640px', fontSize: '1.4rem', lineHeight: '110%' }}
              id="ot-sdk-cookie-policy"
            ></div>
          </>
        ) : (
          hasValidCmsText(cmsContent?.message) && (
            <Box
              display="flex"
              width={['100%', '640px']}
              justifyContent="center"
              textAlign="center"
            >
              <PrismicRichText field={cmsContent?.message} />
            </Box>
          )
        )}
      </Box>
    </>
  );
};

const StyledButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  border: none;
  background: none;
  padding: 0;
  font-size: 1.4rem;
  line-height: 100%;
  color: ${colors.BLACK};
  text-decoration: underline;
  text-underline-position: under;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      opacity: ${opacities.HOVERED};
      transition: all ${durations.FOCUS_DELAY}ms ease-in-out;
    }
  }

  @media (min-width: ${breakpoints.S}px) {
    width: 640px;
  }
`;
