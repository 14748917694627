export const zIndex = {
  PRODUCT_ALT_IMAGE: 10,
  ARROW_SLIDER: 10,
  PRODUCT_PROMOTION: 11,
  PRODUCT_QUICK_ADD: 12,
  STICKY: 20,
  PRODUCT_SELECTOR_MODAL: 24,
  POPUP: 30,
  SUBMENU: 40,
  SEARCH: 50,
  TOOLTIP: 70,
  HEADER: 71,
  STICKY_SELECTOR: 100,
  SIDEBAR: 110,
  MODAL: 120,
  SNACKBAR: 130,
} as const;

export const heights = {
  BANNER: 32,
  BANNER_WITH_TIMESTAMP: 42,
  CTA_HEIGHT: 46,
  HEADER_MOBILE: 56,
  HEADER_TABLET: 64,
  HEADER: 80,
  HEADER_DESKTOP_WITHOUT_SUB_MENU: 56,
  FOOTER: 256,
  MENU: 40,
  SUBMENU: 458,
  MOBILE_SELECTOR: 48,
  SEARCH_BAR_MOBILE: 113,
  SEARCH_BAR_TABLET: 71,
  WISHLIST_SIZE_MODAL_MOBILE: 485,
  QUICK_ADD_MOBILE: 600,
  PRODUCT_SELECTOR_MOBILE: 400,
  CART_STEPS_PADDING_BOTTOM_MOBILE: [164, 116, 56],
  CART_FAQ: 1500,
  STICKY_SLIDER_HEIGHT: 48,
  get PAGE() {
    return `calc(100vh - ${this.BANNER}px - ${this.HEADER}px - ${this.FOOTER}px)`;
  },
} as const;

export const widths = {
  SIDEBAR: 460,
  DESKTOP_FILTER_BAR: 304,
  VISUAL_SIZE_GUIDE_ITEM: 414,
} as const;

export const opacities = {
  BACKGROUND: 0.3,
  LOADING: 0.5,
} as const;

export const durations = {
  MAP_SUGGESTIONS_DELAY: 200,
  MAP_SEARCH_DELAY: 400,
  MENU_MOBILE: 400,
  ENTER: 500,
  EXIT: 500,
  UNDO_DELAY: 2000,
  BUTTON_RESET_DELAY: 3000,
} as const;

export const transitions = {
  GENERIC: 'all ease-in-out 0.5s',
  MODAL: 'all ease-in-out 0.3s ',
  PRODUCT_ALT_IMAGE: 'opacity linear 0.4s ',
} as const;

export const boxShadows = {
  ON_TOP: 'rgba(0, 0, 0, 0.08) 4px 4px 4px 0, rgba(0, 0, 0, 0.16) 2px 2px 2px 0',
  HORIZONTAL: 'rgba(0, 0, 0, 0.08) 0 4px 4px 0, rgba(0, 0, 0, 0.16) 0 2px 2px 0',
} as const;

export const customStyles = {
  a: {
    color: '#000',
  },
  button: {
    a: {
      textDecoration: 'inherit',
      color: 'inherit',
    },
  },
} as const;

export const cdnBaseImageUrl = {
  development: 'https://images.prismic.io/project-dj/',
  production: 'https://cdn.new.darjeeling.fr/darjeeling-production/',
} as const;
