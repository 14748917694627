import * as React from 'react';
import { Box, colors, Text } from '../../../design-system';
import { NotificationType } from '../types';

type Props = {
  type: NotificationType;
  message: string;
  index?: number;
};

const NotificationItem = ({ type, message, index }: Props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    my="m"
    id={`box-cart-notification-${index}`}
  >
    <Text color={type === NotificationType.Error ? 'ERROR' : colors.MAIN_SUCCESS}>{message}</Text>
  </Box>
);

export default NotificationItem;
