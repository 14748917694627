import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../../design-system';
import { getColorImageLink } from '../../product/utils';
import { Color as ColorType } from '../../product/types';
import { ToolTip } from '../../common/components/Custom';
import { getColorBorder } from '../utils';

type Props = {
  productRef: string;
  isSelected: boolean;
  reverseToolTip?: boolean;
  leftAlignToolTip?: boolean;
  color: ColorType;
  handleColorClick?: (color: ColorType) => void;
};

const StyledButton = styled.button<{ border: string }>`
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  border: ${(props) => props.border};
  position: relative;

  .color-tool-tip-message {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${colors.MAIN_GREY};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Color = ({
  productRef,
  isSelected,
  reverseToolTip = false,
  leftAlignToolTip = false,
  color,
  handleColorClick = () => null,
}: Props) => {
  const { colorRef, realColorRef, detailedColorLabel } = color;
  const srcIcon = getColorImageLink(realColorRef || colorRef);

  const isWhitish =
    ['Blanc', 'Ivoire'].includes(detailedColorLabel) ||
    ['A1', 'AN', 'AW', 'CM', 'I0', 'I1', 'I2', 'I3', 'I4', 'I6', 'I8', 'IV', 'MK'].includes(
      colorRef
    );

  const objectID = `${productRef}/${colorRef}`;

  return (
    <StyledButton
      id={`product-card-item-color-${objectID}-button`}
      data-testid={`product-card-item-color-${objectID}-button`}
      onClick={() => handleColorClick(color)}
      border={getColorBorder({ isSelected, isWhitish })}
      aria-label={`Bouton pour sélectionner la couleur ${detailedColorLabel} du produit`}
    >
      <ToolTip
        background={`url(${srcIcon})`}
        backgroundPosition="center"
        backgroundSize="cover"
        border={isSelected ? '2px solid WHITE' : 'none'}
        borderRadius="50%"
        size="20px"
        reverse={reverseToolTip}
        isLeftAligned={leftAlignToolTip}
      >
        <div>
          <p className="color-tool-tip-message">{detailedColorLabel}</p>
        </div>
      </ToolTip>
      <span className="sr-only">{detailedColorLabel}</span>
    </StyledButton>
  );
};
