import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';
import pathOr from 'ramda/src/pathOr';
import { useFeatureGate } from '@statsig/react-bindings';

import { Box, DynamicButton, DynamicButtonStatus, Breadcrumbs } from '../../../design-system';
import { Status } from '../../../design-system/button/components/DynamicButton';
import {
  loadProduct,
  loadReviews,
  LoadProductParams,
  setSizeErrMsg,
  changeSize,
  requestProductSuccessModal,
  changeSizeModal,
} from '../actions';
import { addGiftCard, addToCart, ProductInput, TAddGiftCardRequest } from '../../cart/actions';
import Images from './Images';
import { RootState } from '../../../store/rootReducer';
import { State, Product as ProductType } from '../types';
import { CmsProduct, CmsUsp } from '../../cms/types';
import { cmsProductInit } from '../../cms/actions';
import {
  buildProductRichSnippet,
  sortImagePositions,
  getIsSizeAvailable,
  getProductImageLink,
  productImageWidths,
  verifyGiftCardInfos,
  TGiftCardErrors,
  isItGiftCard,
} from '../utils';
import { CTA_CONTENT, ERR_CHOOSE_SIZE } from '../locale';
import { ERR_GENERIC } from '../../common/locale';
import Reviews from '../../reviews';
import { InfoPanel } from './InfoPanel';
import { Details } from './Details';
import LoadingProduct from './LoadingProduct';
import { CrossSellProductPage } from './CrossSellProductPage';
import { UpSell } from './UpSell';
import { BreadcrumbsItems, AlgoliaQuery } from '../../catalog/types';
import { getBreadcrumbItems } from '../../catalog/utils';
import { NotFound } from '../../routing';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { UspSection } from '../../home/components/UspSection';
import { Selectors } from './Selectors';
import { ProductUsp } from './ProductUsp';
import { analyticsEvent } from '../../search/utils';
import { AnalyticsEventTypes, AnalyticsEvents } from '../../search/types';
import { PromotionLabel } from './PromotionLabel';
import { Feedback } from '../../cart/components/Feedback';
import {
  loadProductRecommendationsHomemade,
  loadProductRecommendations,
} from '../../recommendations/actions';
import { LoadProductRecommendationsParams } from '../../recommendations/types';
import { patternVariants, TPattern } from './PatternSelector';
import { getCanonicalUrl, scrollToTop } from '../../common/utils';
import { CsrBlock } from './CsrBlock';
import StickySelector from './StickySelector';
import { ImagesMozaic } from './ImagesMozaic';
import { heights } from '../../common/constants';
import { CommitmentsBanner } from '../../banners/components/BannerCommitments';
import { breakpoints } from '../../../design-system';
import { ProductVariantBlock } from './ProductVariantBlock';
import WishlistCta from '../../wishlist/components/WishlistCta';
import { GiftCard } from './GiftCard';

export const PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_DESKTOP = 496; // to align the size buttons
export const PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_TABLET = 368;

const StyledProductPage = styled.div`
  display: grid;
  grid-gap: 40px;
  margin: -56px auto 0;
  max-width: ${breakpoints.XL}px;
  @media (min-width: ${breakpoints.S}px) {
    margin: 16px auto;
  }

  .cta-for-variant {
    margin: 16px 0;
  }
  .add-to-cart-wishlist__btn-container {
    display: flex;
    gap: 8px;
  }
  .wishlist-cta {
    position: relative;
  }

  .reviews-container {
    width: 100%;
    max-width: 1072px;
    margin: auto;
    padding: 0;
    scroll-margin-top: ${heights.HEADER_MOBILE}px;

    @media (min-width: ${breakpoints.S}px) {
      padding: 0 16px;
      scroll-margin-top: ${heights.HEADER_TABLET}px;
    }

    @media (min-width: ${breakpoints.M}px) {
      padding: 0 24px;
      scroll-margin-top: ${heights.HEADER}px;
    }
  }
  .product-wrapper-desktop {
    width: calc(100vw - 48px);
    justify-self: center;

    @media (min-width: ${breakpoints.XL + 48}px) {
      width: 100%;
    }
  }
`;

type DispatchedActions = {
  cmsProductInit: () => void;
  loadProduct: ({ productRef, colorRef }: LoadProductParams) => void;
  loadReviews: (productCode: string) => void;
  loadProductRecommendations: (params: LoadProductRecommendationsParams) => void;
  loadProductRecommendationsHomemade: ({ productRef, colorRef }: LoadProductParams) => void;
  addToCart: (product: ProductInput, trackingProduct: ProductType) => void;
  addGiftCard: (
    giftCard: TAddGiftCardRequest,
    trackingProduct: ProductType
  ) => Promise<{ ok: boolean; data?: string }>;
  setSizeErrMsg: (msg: string) => void;
};

type Props = State &
  DispatchedActions & {
    cmsProduct: CmsProduct;
    cmsUsp: CmsUsp;
    ctaState: DynamicButtonStatus;
    algoliaQuery: AlgoliaQuery | undefined;
  };

export type TGiftCardInfos = {
  isAnotherAmount: boolean;
  giftCardAmount: string;
  senderName: string;
  receiverName: string;
  receiverEmail: string;
  dateOfSending: string;
  message: string;
  emailPattern: TPattern;
};

export const Product = ({
  loadProduct,
  loadReviews,
  loadProductRecommendations,
  loadProductRecommendationsHomemade,
  cmsProductInit,
  isFetching,
  errMsg,
  product,
  productReviews,
  size,
  sizeErrMsg,
  ctaState,
  cmsProduct,
  cmsUsp,
  algoliaQuery,
  addToCart,
  addGiftCard,
  setSizeErrMsg,
}: Props) => {
  const { pathname, hash } = useLocation();
  const { id } = useParams() as { id: string };
  const { isMobile, isTablet } = useMediaQueries();
  const dispatch = useDispatch();
  const reviewsRef = useRef<HTMLDivElement | null>(null);
  const pdpAddToCartRef = useRef<HTMLDivElement | null>(null);
  const [giftCardInfos, setGiftCardInfos] = useState<TGiftCardInfos>({
    isAnotherAmount: false,
    giftCardAmount: '',
    senderName: '',
    receiverName: '',
    receiverEmail: '',
    dateOfSending: '',
    message: '',
    emailPattern: patternVariants[0],
  });
  const [giftCardAmount, setGiftCardAmount] = useState<string>('');
  const [isCustomAmountDeselected, setIsCustomAmountDeselected] = useState<boolean>(false);
  const [giftCardErrMsg, setGiftCardErrMsg] = useState<TGiftCardErrors>({});
  const [customSizeBlockForVariantTwoIsOpen, setCustomSizeBlockForVariantTwoIsOpen] =
    useState(false);
  const cmsCommitmentsBanner = cmsProduct.body?.find(
    (el) => el.slice_type === 'commitments_banner'
  );
  const [paramColorRef, paramProductRef] = id.split('-').reverse();
  const isValidProduct = () =>
    product &&
    product.productRef &&
    product.colorRef &&
    product.productName &&
    product.categories &&
    product.productRef === paramProductRef &&
    product.colorRef === paramColorRef &&
    product.status;

  const showReviews = !productReviews.isFetching && Boolean(productReviews.reviews.product_code);
  const productCode = paramProductRef;

  const {
    productRef,
    colorRef,
    colorLabel,
    detailedColorLabel,
    productName,
    productDescription,
    productComposition,
    status,
    cleaningAdvice,
    categories,
    colorVariants,
    sizeVariants,
    model,
    moreDetails,
    promoLongLabel: promotionLongLabel,
    promoTColor: promotionTextColor,
    promoBColor: promotionBackgroundColor,
    videoUrl,
    label1: rankLabel,
    label2: attributeLabel,
    collectionName,
    typology,
  } = product ?? {};

  const { refinementsItems } = useSelector((state: RootState) => state.filters);
  const { showFeedback } = useSelector((state: RootState) => state.cart);

  const { productRef: productRefModal, colorLabel: colorLabelModal } = useSelector(
    (state: RootState) => state.product.productModal
  );
  const { crossSellIsLoading, upSellIsLoading } = useSelector((state: RootState) => state.product);

  const isQuickAddOnPDP =
    productRef !== productRefModal ||
    (productRef === productRefModal && colorLabel !== colorLabelModal);
  const isGiftCard = isItGiftCard(productRef);
  const hideCsrBlock = cmsProduct.hide_csr_block;

  useEffect(() => {
    if (sizeVariants.length > 0) {
      const refinedSizes = refinementsItems.reduce<string[]>((acc, cVal) => {
        if (cVal?.attribute === 'sizeVariants.sizeFilter') {
          return [...acc, cVal.label];
        }
        return acc;
      }, []);

      if (refinedSizes.length === 1) {
        const refinedSize = sizeVariants.filter((variant) =>
          [variant.size, `${variant.bandSize}${variant.cupSize}`].includes(refinedSizes[0])
        );
        if (refinedSize.length === 1 && getIsSizeAvailable(refinedSize[0])) {
          dispatch(changeSize(refinedSize[0]));
        }
      }
    }
  }, [sizeVariants]);

  useEffect(() => {
    if (!isValidProduct()) {
      loadProduct({ productRef: paramProductRef, colorRef: paramColorRef });
    }
    if (!isValidProduct() || !productReviews.reviews.product_code) {
      loadReviews(productCode);
    }
  }, [id]);

  const cmsThumbnailImage = cmsProduct?.thumbnail_image?.thumbnail?.url ?? '';

  useEffect(() => {
    if (isMobile && hash !== '#reviews') {
      scrollToTop();
    }
    if (!Object.values(cmsProduct)) {
      cmsProductInit();
    }
  }, []);

  const isHomeMadeRecommendationsEnabled = useFeatureGate('home-made-reco').value;

  useEffect(() => {
    if (isValidProduct()) {
      if (isHomeMadeRecommendationsEnabled) {
        loadProductRecommendationsHomemade({ productRef, colorRef });
      } else {
        loadProductRecommendations({
          productRef,
          colorRef,
        });
      }
    }
  }, [product]);

  useEffect(() => {
    if (isGiftCard && giftCardInfos && giftCardInfos.isAnotherAmount) {
      setGiftCardAmount(giftCardInfos.giftCardAmount);
    }
  }, [giftCardInfos]);

  useEffect(() => {
    if (showFeedback) {
      setCustomSizeBlockForVariantTwoIsOpen(false);
    }
  }, [showFeedback]);

  if (isFetching) {
    return <LoadingProduct />;
  }
  if (errMsg || !isValidProduct()) {
    return <NotFound />;
  }

  const { lvl0, lvl1, lvl2, lvl3 } = categories ?? {};
  const categoryLevels = [lvl0, lvl1, lvl2, lvl3].filter(Boolean);
  const breadcrumbItems: BreadcrumbsItems = getBreadcrumbItems(categoryLevels);
  const breadcrumbItemsWithCurrentProduct = [
    ...breadcrumbItems,
    {
      label: `${productName} - ${detailedColorLabel}`,
      value: '#',
      readonly: true,
    },
  ];

  const imagePositions: number[] = sortImagePositions(pathOr([], ['images'], product));

  const { sku } = size;
  const { storePrice, originalPrice } = sku ? size : (product ?? {});

  const feedbacks = pathOr([], ['reviews', 'feedbacks'], productReviews);
  const ratingAverage = pathOr('', ['reviews', 'rating_average'], productReviews);
  const ratingCount = pathOr('', ['reviews', 'rating_count'], productReviews);
  const JsonLdData = buildProductRichSnippet({
    productName,
    productRef,
    colorRef,
    imagePositions,
    productDescription,
    feedbacks,
    ratingAverage,
    ratingCount,
    pathname,
    storePrice,
  });

  const uspContent = cmsUsp?.usp ?? [];
  const uspTitle = cmsUsp?.usp_main_title ?? '';

  const metaTitle = `${productName} ${detailedColorLabel} | Darjeeling`;

  const objectID = `${productRef}/${colorRef}`;

  const handleCtaClick = async () => {
    if (sku) {
      if ((status && getIsSizeAvailable(size) && size.stockQuantity >= 1) || isGiftCard) {
        let errMsg: TGiftCardErrors = {};
        if (isGiftCard) {
          errMsg = verifyGiftCardInfos(giftCardInfos as TGiftCardInfos);
          setGiftCardErrMsg(errMsg);
          if (Object.keys(errMsg).length === 0 && errMsg.constructor === Object) {
            const giftCard = {
              productRef: productRef,
              colorRef: colorRef,
              sku: size.sku,
              price: Math.round(parseFloat(giftCardInfos.giftCardAmount) * 100) / 100,
              from: giftCardInfos.senderName,
              to: giftCardInfos.receiverName,
              email: giftCardInfos.receiverEmail,
              date: giftCardInfos.dateOfSending,
              message: giftCardInfos.message,
            };

            const response = await addGiftCard(giftCard, product);
            if (!response.ok) {
              setGiftCardErrMsg({ receiverEmail: response.data });
            }
          }
        } else {
          addToCart(
            {
              productRef,
              colorRef,
              sku,
              quantity: 1,
            },
            product
          );
          dispatch(requestProductSuccessModal(product));
          dispatch(changeSizeModal(size));
          if (algoliaQuery) {
            analyticsEvent({
              objectID,
              queryID: algoliaQuery.queryID,
              index: algoliaQuery.index,
              type: AnalyticsEventTypes.convert,
              eventName: AnalyticsEvents.addToCartFromPDP,
            });
          }
        }
      } else {
        setSizeErrMsg(ERR_GENERIC);
      }
    } else {
      setSizeErrMsg(ERR_CHOOSE_SIZE);
      setCustomSizeBlockForVariantTwoIsOpen(true);
    }
  };

  const handleGiftCardInfo = (infos: TGiftCardInfos) => {
    setGiftCardInfos(infos);
    if (infos.isAnotherAmount) {
      setIsCustomAmountDeselected(false);
    }
  };

  const handleNewAmount = (newAmount: string) => {
    setGiftCardAmount(newAmount);
    if (giftCardInfos?.isAnotherAmount) {
      setIsCustomAmountDeselected(true);
    }
  };

  const handleCustomSizeBlockToggle = (isOpen: boolean) => {
    setCustomSizeBlockForVariantTwoIsOpen(isOpen);
  };

  const CtaFragment = (
    <div ref={pdpAddToCartRef} className="add-to-cart-wishlist__btn-container">
      <DynamicButton
        id="btn-add-to-cart"
        onClick={handleCtaClick}
        data={CTA_CONTENT(isMobile)}
        feedback={isQuickAddOnPDP ? Status.Default : ctaState}
        disabled={!status}
      />
      {!isGiftCard && (
        <div className="wishlist-cta">
          <WishlistCta
            product={product}
            productRef={productRef}
            colorRef={colorRef}
            objectID={`${productRef}/${colorRef}`}
          />
        </div>
      )}
    </div>
  );

  const fragments = {
    InfoPanel: (
      <InfoPanel
        productName={productName}
        storePrice={storePrice}
        originalPrice={originalPrice}
        ratingAverage={ratingAverage}
        ratingCount={ratingCount}
        reviewsRef={reviewsRef}
        showReviews={showReviews}
        collectionName={collectionName}
        typology={typology}
      />
    ),
    Selectors: (
      <Selectors
        size={size}
        sizeErrMsg={sizeErrMsg}
        sizeVariants={sizeVariants}
        colorRef={colorRef}
        detailedColorLabel={detailedColorLabel}
        colorVariants={colorVariants}
        productRef={productRef}
        pathname={pathname}
        ctaState={ctaState}
        status={status}
        objectID={objectID}
        isGiftCard={isGiftCard}
        handleNewAmount={handleNewAmount}
        isCustomAmountDeselected={isCustomAmountDeselected}
        isQuickAddOnPDP={isQuickAddOnPDP}
        onCtaClick={handleCtaClick}
        onGiftCardInfo={handleGiftCardInfo}
        giftCardInfos={giftCardInfos}
        giftCardErrMsg={giftCardErrMsg}
        giftCardAmount={giftCardAmount}
        handleCustomSizeBlockToggle={handleCustomSizeBlockToggle}
        customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
      />
    ),
    Images: (
      <Images
        productRef={productRef}
        colorRef={colorRef}
        productName={productName}
        colorLabel={colorLabel}
        imagePositions={imagePositions}
        videoUrl={videoUrl}
        cmsThumbnailImage={cmsThumbnailImage}
        product={product}
        model={model}
        breadcrumbItems={breadcrumbItemsWithCurrentProduct}
      />
    ),
    Breadcrumbs: <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />,
    Details: (
      <Details
        productDescription={productDescription}
        cleaningAdvice={cleaningAdvice}
        productComposition={productComposition}
        moreDetails={moreDetails}
        product={product}
        isHomeMadeRecommendationsEnabled={isHomeMadeRecommendationsEnabled}
      />
    ),
    ProductUsp: <ProductUsp />,
    Reviews:
      !crossSellIsLoading && !upSellIsLoading ? (
        <div ref={reviewsRef} className="reviews-container">
          <Reviews
            reviews={productReviews.reviews}
            productCode={productCode}
            reviewsRef={reviewsRef}
          />
        </div>
      ) : null,
    CommitmentsBanner: (
      <CommitmentsBanner
        id={cmsCommitmentsBanner?.id}
        items={cmsCommitmentsBanner?.items}
        title={cmsCommitmentsBanner?.primary.commitments_banner_title}
      />
    ),
    CrossSell: <CrossSellProductPage />,
    UpSell: <UpSell />,
    PromotionLabel: (
      <PromotionLabel
        promotionLongLabel={promotionLongLabel}
        promotionTextColor={promotionTextColor}
        promotionBackgroundColor={promotionBackgroundColor}
        rankLabel={rankLabel}
        attributeLabel={attributeLabel}
        objectID={objectID}
      />
    ),
    StickySelector: (
      <StickySelector
        product={product}
        sizeErrMsg={sizeErrMsg}
        size={size}
        objectID={objectID}
        handleCtaClick={handleCtaClick}
        isQuickAddOnPDP={isQuickAddOnPDP}
        ctaState={ctaState}
        pdpAddToCartRef={pdpAddToCartRef}
      />
    ),
    UspSection: <UspSection content={uspContent} title={uspTitle} isProductPage />,
  };

  return (
    <StyledProductPage>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="canonical" href={getCanonicalUrl(pathname)} />
        <meta name="description" content={productDescription} />
        <script type="application/ld+json">{`${JsonLdData}`}</script>
        <meta property="og:title" content={metaTitle} />
        <meta
          property="og:image"
          content={getProductImageLink({
            productRef,
            colorRef,
            productName,
            position: 1,
            width: productImageWidths.SLIDER,
          })}
        />
        <meta property="og:description" content={productDescription} />
      </Helmet>
      <Feedback onClose={() => handleCustomSizeBlockToggle(false)} />
      {isGiftCard ? (
        <GiftCard
          product={product}
          giftCardInfos={giftCardInfos}
          handleGiftCardInfo={handleGiftCardInfo}
          handleNewAmount={handleNewAmount}
          handleCtaClick={handleCtaClick}
          isCustomAmountDeselected={isCustomAmountDeselected}
          giftCardErrMsg={giftCardErrMsg}
          isQuickAddOnPDP={isQuickAddOnPDP}
          pdpAddToCartRef={pdpAddToCartRef}
        />
      ) : isMobile ? (
        <Box className="product-wrapper-mobile" display="grid" gridGap="s" mx="m">
          <Box display="grid" gridGap="s">
            <Box mx="-16px">{fragments.Images}</Box>
            <ProductVariantBlock
              product={product}
              size={size}
              ratingAverage={ratingAverage}
              ratingCount={ratingCount}
              reviewsRef={reviewsRef}
              showReviews={showReviews}
              sizeErrMsg={sizeErrMsg}
              ctaState={ctaState}
              handleCtaClick={handleCtaClick}
              customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
              handleCustomSizeBlockToggle={handleCustomSizeBlockToggle}
            />
            <div className="cta-for-variant add-to-cart-wishlist__btn-container">
              <DynamicButton
                id="btn-add-to-cart"
                data-testid="btn-add-to-cart"
                onClick={handleCtaClick}
                data={CTA_CONTENT(true)}
                feedback={ctaState}
              />
              <div className="wishlist-cta">
                <WishlistCta
                  product={product}
                  productRef={productRef}
                  colorRef={colorRef}
                  objectID={`${productRef}/${colorRef}`}
                />
              </div>
            </div>
            {fragments.ProductUsp}
            {fragments.Details}
            {fragments.Selectors}
            <Box display="grid" mt="m">
              {fragments.CrossSell}
            </Box>
            {fragments.UpSell}
            {showReviews && fragments.Reviews}
            {fragments.CommitmentsBanner}
            {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
            {fragments.Breadcrumbs}
            {fragments.UspSection}
          </Box>
        </Box>
      ) : isTablet ? (
        <div className="product-wrapper-tablet">
          <Box display="grid" gridGap="l" width="calc(100% - 32px)" margin="0 auto">
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns={`1fr ${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_TABLET}px`}
              gridGap="m"
            >
              <Box
                display="grid"
                gridGap="xl"
                gridAutoRows="min-content"
                alignSelf="start"
                position="sticky"
                top={`${heights.HEADER_TABLET}px`}
                className="product-image-container"
              >
                {fragments.Images}
                <Box>{fragments.Details}</Box>
              </Box>
              <Box
                alignSelf="start"
                position="sticky"
                top={`${heights.HEADER_TABLET}px`}
                width={`${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_TABLET}px`}
                className="product-info-container"
              >
                <Box display="grid" gridGap="l">
                  <Box display="grid" gridGap="m" gridAutoRows="min-content">
                    {fragments.InfoPanel}
                    {fragments.PromotionLabel}
                    {fragments.Selectors}
                  </Box>
                  {CtaFragment}
                  {fragments.ProductUsp}
                  {fragments.CrossSell}
                </Box>
              </Box>
            </Box>
            {fragments.UpSell}
          </Box>
          {showReviews && fragments.Reviews}
          {fragments.CommitmentsBanner}
          {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
          <Box mx={'m'}>{fragments.Breadcrumbs}</Box>
          {fragments.UspSection}
          {fragments.StickySelector}
        </div>
      ) : (
        <>
          <div className="product-wrapper-desktop">
            <Box display="grid" gridGap="24px">
              {fragments.Breadcrumbs}
              <Box display="flex" gridGap="48px">
                <Box
                  flex={'1'}
                  alignSelf="start"
                  position="sticky"
                  top={`${heights.HEADER}px`}
                  className="product-image-container"
                >
                  <ImagesMozaic
                    productRef={productRef}
                    colorRef={colorRef}
                    productName={productName}
                    colorLabel={colorLabel}
                    imagePositions={imagePositions}
                    videoUrl={videoUrl}
                    product={product}
                    model={model}
                  />
                </Box>
                <Box
                  flex={`0 0 ${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_DESKTOP}px`}
                  alignSelf="start"
                  position="sticky"
                  top={`${heights.HEADER}px`}
                  className="product-info-container"
                >
                  <Box
                    display="grid"
                    gridGap="l"
                    width={`${PRODUCT_PAGE_RIGHT_BLOCK_WIDTH_DESKTOP}px`}
                  >
                    <Box display="grid" gridGap="m" gridAutoRows="min-content">
                      {fragments.InfoPanel}
                      {fragments.PromotionLabel}
                      {fragments.Selectors}
                    </Box>
                    {CtaFragment}
                    {fragments.ProductUsp}
                    {fragments.Details}
                    <Box mt="m">{fragments.CrossSell}</Box>
                  </Box>
                </Box>
              </Box>
              {fragments.UpSell}
            </Box>
            {showReviews && fragments.Reviews}
            {fragments.CommitmentsBanner}
            {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
            {fragments.UspSection}
          </div>
          {fragments.StickySelector}
        </>
      )}
    </StyledProductPage>
  );
};

export default connect(
  (state: RootState) => ({
    ...state.product,
    ctaState: state.cart.ctaState,
    cmsProduct: state.cms.product,
    cmsUsp: state.cms.usp,
    algoliaQuery: state.catalog.algoliaQuery,
  }),
  {
    addToCart,
    addGiftCard,
    loadProduct,
    loadReviews,
    loadProductRecommendations,
    loadProductRecommendationsHomemade,
    cmsProductInit,
    setSizeErrMsg,
  }
)(Product);
