import * as React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../../design-system';
import { Price } from '../../product/types';

type Props = {
  price: Price;
  isWishlist?: boolean;
};

const StyledProductPrice = styled.div`
  .product-price {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0;
  }
  .product-price--original {
    color: ${colors.GREY2};
    text-decoration: line-through;
    font-weight: 400;
  }
  .product-price--is-wishlist {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const ProductPrice = ({ price, isWishlist }: Props) => (
  <StyledProductPrice>
    <p id="pdp-price" className={`product-price ${isWishlist ? 'product-price--is-wishlist' : ''}`}>
      {price.storePrice}
    </p>
    {price.hasPromotion && (
      <p
        id="pdp-original-price"
        className={`product-price product-price--original ${isWishlist ? 'product-price--is-wishlist' : ''}`}
      >
        {price.originalPrice}
      </p>
    )}
  </StyledProductPrice>
);
