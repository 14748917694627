import React from 'react';
import styled from '@emotion/styled';

import { Stars } from '../../../design-system';

type Props = {
  ratingAverage: string;
  ratingCount: string;
  reviewsRef: React.RefObject<HTMLDivElement>;
};

const StyledRating = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .show-review__button {
    font-size: 1.2rem;
  }
`;

export const Rating = ({ ratingAverage, ratingCount, reviewsRef }: Props) => {
  const onClick = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const rating = Number.parseFloat(ratingAverage);
  const ratingCountToNumber = parseInt(ratingCount, 10) ?? 0;

  return ratingCountToNumber > 0 ? (
    <StyledRating>
      <Stars size={14} rating={rating} identifier="product-stars-block" />
      <div>
        <button
          type="button"
          className="anchor-animated show-review__button"
          aria-label="Bouton pour voir les avis des clients"
          data-testid="btn-pdp-scroll-to-reviews"
          id="btn-pdp-scroll-to-reviews"
          onClick={onClick}
        >
          {`${ratingCount} avis`}
        </button>
      </div>
    </StyledRating>
  ) : null;
};
