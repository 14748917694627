import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';
import { PrismicRichText } from '@prismicio/react';

import { breakpoints, colors } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { RankAttributeLabel } from './RankAttributeLabel';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import { closeModal, openModalNew } from '../../common/actions';
import { formatDate } from '../../cms/utils';
import { emptyText } from '../../cms/state';
import { offers as locale } from '../../cms/locale';

type Props = {
  objectID: string;
  promotionLongLabel?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  rankLabel?: string;
  attributeLabel?: string;
};

type StyledPromotionLabelContainerProps = {
  promotionLongLabelBgColor: string;
  promotionLongLabelTextcolor: string;
};

const StyledPromotionLabelContainer = styled.div<StyledPromotionLabelContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  width: 100%;

  @media (min-width: ${breakpoints.S}px) {
    margin: 8px 0;
  }
  .promotion-long-label__container {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background-color: ${(props) => props.promotionLongLabelBgColor};
    &.promotion-long-label--with-conditions {
      justify-content: space-between;
    }
  }

  .promotion-long-label {
    font-weight: 700;
    font-size: 1.4rem;
    color: ${(props) => props.promotionLongLabelTextcolor};
  }

  .show-promotion-condition__button {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${(props) => props.promotionLongLabelTextcolor};
  }
`;

const StyledConditionContent = styled.div`
  padding: 40px;
  max-width: 500px;
  .condition__name {
    font-weight: bold;
    font-size: 1.6rem;
    margin: 0 0 16px 0;
  }
  .condition__date {
    font-size: 1.4rem;
    margin: 0 0 8px 0;
  }
  .condition__code {
    font-size: 1.4rem;
    margin: 0 0 16px 0;
  }
  .condition__criteria,
  .condition__exclusions {
    font-size: 1.4rem;
    line-height: 2.1rem;
    > p {
      margin: 0 0 8px 0;
    }
  }
  .condition__criteria {
    margin-bottom: 16px;
  }
`;

export const PromotionLabel = ({
  promotionLongLabel,
  promotionTextColor,
  promotionBackgroundColor,
  rankLabel,
  attributeLabel,
  objectID,
}: Props) => {
  const dispatch = useDispatch();
  const { label_text_color: defaultTextColor, label_background_color: defaultBackgroundColor } =
    useSelector((state: RootState) => state.cms.promotion) ?? {};
  const cmsContent = useSelector((state: RootState) => state.cms.offers);
  const items = cmsContent?.items ?? [];

  useEffect(() => {
    if (items.length < 1) {
      dispatch(loadCmsContent(CmsCustomTypes.offers) as unknown as UnknownAction);
    }
  }, []);

  const offersDataDetails = items.find(
    (data) => data.name?.toLowerCase() === promotionLongLabel?.toLowerCase()
  );

  if (!(promotionLongLabel || rankLabel || attributeLabel || offersDataDetails)) {
    return null;
  }

  const onShowConditionsClick = () => {
    dispatch(closeModal());
    dispatch(
      openModalNew({
        content: (
          <StyledConditionContent>
            <p className="condition__name">{`Offre : ${offersDataDetails?.name}`}</p>
            <p className="condition__date">
              <strong>Validité : </strong>
              {`${offersDataDetails?.valid_from ? formatDate(offersDataDetails?.valid_from) : ''} - ${
                offersDataDetails?.valid_to ? formatDate(offersDataDetails?.valid_to) : ''
              }`}
            </p>
            <p className="condition__code">
              <strong>Code : </strong>
              {offersDataDetails?.coupon_code ?? locale.TXT_NO_COUPON_CODE}
            </p>
            <div className="condition__criteria">
              <span>
                <strong>Conditions : </strong>
              </span>
              <PrismicRichText field={offersDataDetails?.conditions || [...emptyText]} />
            </div>
            <div className="condition__exclusions">
              <span>
                <strong>Exclusions : </strong>
              </span>
              <PrismicRichText field={offersDataDetails?.exclusions || [...emptyText]} />
            </div>
          </StyledConditionContent>
        ),
        preset: 'inheritWidth',
      })
    );
  };
  return (
    <StyledPromotionLabelContainer
      promotionLongLabelBgColor={promotionBackgroundColor || defaultBackgroundColor || 'unset'}
      promotionLongLabelTextcolor={promotionTextColor || defaultTextColor || colors.BLACK}
    >
      <RankAttributeLabel rankLabel={rankLabel} attributeLabel={attributeLabel} />
      {(promotionLongLabel || offersDataDetails) && (
        <div
          className={`promotion-long-label__container ${offersDataDetails ? 'promotion-long-label--with-conditions' : ''}`}
        >
          {promotionLongLabel && (
            <span className="promotion-long-label" id={`pdp-item-promotion-label-${objectID}`}>
              {promotionLongLabel}
            </span>
          )}
          {offersDataDetails && (
            <button
              type="button"
              className="anchor-animated show-promotion-condition__button"
              data-testid="btn-show-promotion-condition"
              id="btn-show-promotion-condition"
              onClick={onShowConditionsClick}
            >
              Voir conditions
            </button>
          )}
        </div>
      )}
    </StyledPromotionLabelContainer>
  );
};
