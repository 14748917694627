import React from 'react';
import { heights, zIndex } from '../../common/constants';
import { Box, Text } from '../../../design-system';
import { H1 } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  amount: string;
};

export const GiftCardInfoPanel = ({ amount }: Props) => {
  const { isMobile } = useMediaQueries();
  return (
    <>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="start"
        justifyContent="space-between"
        bg="WHITE"
        zIndex={isMobile ? zIndex.STICKY : 'initial'}
        mb={isMobile ? 'na' : '-8px'}
        position={isMobile ? 'sticky' : 'initial'}
        bottom={isMobile ? `${heights.MOBILE_SELECTOR}px` : 'initial'}
        mx={isMobile ? '-16px' : 'na'}
        p={isMobile ? 'm' : 'na'}
      >
        <H1 id="pdp-product-name" preset={['body', 'subheading']} fontWeight={['regular', 'bold']}>
          E-Carte Cadeau Darjeeling
        </H1>
        <Text id="pdp-price" preset={['body', 'subheading']} color={'BLACK'} fontWeight="bold">
          {amount && amount !== '0' && amount + '€'}
        </Text>
      </Box>
    </>
  );
};
