import React, { useEffect, useState } from 'react';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { useSwipeable } from 'react-swipeable';
import { Box, Button, Icon, Text } from '../../../design-system';
import { getBFFData, Queries } from '../../api';
import { Colors } from '../../product/components/Colors';
import { Product, Size } from '../../product/types';
import { getImageList } from '../../product/utils';
import {
  getProductPrice,
  getSizeMessage,
  sortSizes,
  sizeNoLongerAvailable,
} from '../../product/utils';
import { ProductPrice } from '../../product/components/ProductPrice';
import { Sizes } from '../../product/components/Sizes';
import { EresaResults } from './EresaResults';
import {
  loadProductRecommendations,
  loadProductRecommendationsHomemade,
} from '../../recommendations/actions';
import { RootState } from '../../../store/rootReducer';
import { setEresaMainProduct } from '../actions';
import {
  capitalize,
  getColorForLeftArrow,
  getColorForRightArrow,
  getSizeForTracking,
  isValidProduct,
  onClickLeft,
  onClickRight,
  setSkuAndSizeForEresaMainProduct,
} from '../utils';
import { EresaStep } from '../utils';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { ERR_CHOOSE_SIZE } from '../../product/locale';
import { ERESA_NEXT } from '../locale';
import { openModal } from '../../common/actions';
import { getTrackingProduct, pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';

type Props = {
  originalProduct: Product;
  setStepEresaModal: (value: number) => void;
  isHomeMadeRecommendationsEnabled: boolean;
};

const EresaMainProduct = ({
  originalProduct,
  setStepEresaModal,
  isHomeMadeRecommendationsEnabled,
}: Props) => {
  const { isMobile } = useMediaQueries();
  const dispatch = useDispatch();
  const { productRef, colorRef } = originalProduct;
  const { cart } = useSelector((state: RootState) => state.cart);
  const { sku } = useSelector((state: RootState) => state.product.size);
  const [product, setProduct] = useState<Product>(originalProduct);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedColorRef, setSelectedColorRef] = useState(colorRef);
  const [selectedSku, setSelectedSku] = useState('');
  const [hoveredSku, setHoveredSku] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const pictureRatio = 346 / 339.97;
  const objectID = productRef + '-' + colorRef;

  const {
    images,
    productName,
    detailedColorLabel,
    sizeVariants,
    colorVariants,
    storePrice,
    originalPrice,
  } = product;

  const onColorClick = (colorRef: string) => setSelectedColorRef(colorRef);

  useEffect(() => {
    const fetchProduct = async () => {
      const res = await getBFFData(Queries.getProduct, { productRef, colorRef: selectedColorRef });
      const product = res.data.product as Product;
      const filteredProduct = {
        ...product,
        colorVariants: product.colorVariants.filter((color) => color.status),
      };
      setProduct(filteredProduct);
    };
    setSelectedSku('');
    setActiveIndex(0);
    if (product.colorRef !== selectedColorRef) {
      fetchProduct();
    }
  }, [selectedColorRef]);

  useEffect(() => {
    const originalSize = originalProduct.sizeVariants.find((size) => size.sku === sku);
    pushToGTM(Events.eresaIntent, {
      product: getTrackingProduct({
        product: {
          ...originalProduct,
          size: getSizeForTracking(originalSize),
          productRef,
          colorRef,
          sku,
        },
        isEresaIntent: true,
      }),
    });
  }, []);

  useEffect(() => {
    setSkuAndSizeForEresaMainProduct({ sortedSizes, sku, setSelectedSku, setSelectedSize });
  }, [selectedColorRef, sizeVariants, selectedSku]);

  useEffect(() => {
    setErrMsg('');
  }, [selectedSku, hoveredSku, selectedColorRef]);

  const sortedSizes = sortSizes(sizeVariants);

  const [selectedSize, setSelectedSize] = useState(sortedSizes.find((size) => size.sku === sku));

  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);
  const sizeMessage = getSizeMessage(hoveredSize);
  const isNoLongerAvailable = sizeNoLongerAvailable(
    cart,
    product.productRef,
    product.colorRef,
    sizeVariants
  );
  const onClick = async () => {
    if (selectedSize) {
      const productWithSelectedSize = {
        ...product,
        sizeVariants: product.sizeVariants.filter((size: Size) => size === selectedSize),
      };
      if (isValidProduct(productWithSelectedSize)) {
        dispatch(setEresaMainProduct(productWithSelectedSize));
        if (isHomeMadeRecommendationsEnabled) {
          dispatch(
            loadProductRecommendationsHomemade({
              productRef: productWithSelectedSize.productRef,
              colorRef: productWithSelectedSize.colorRef,
            }) as unknown as UnknownAction
          );
        } else {
          dispatch(
            loadProductRecommendations({
              productRef: productWithSelectedSize.productRef,
              colorRef: productWithSelectedSize.colorRef,
            }) as unknown as UnknownAction
          );
        }
        setStepEresaModal(EresaStep.STORES);
      } else {
        dispatch(
          openModal({
            content: <EresaResults isError />,
            preset: 'eresaModalResults',
          })
        );
      }
    } else {
      setErrMsg(ERR_CHOOSE_SIZE);
    }
  };

  const imageList = getImageList({
    imagePositions: images,
    productRef: product.productRef,
    colorRef: product.colorRef,
    productName: product.productName,
    colorLabel: product.colorLabel,
  });
  const carouselImages = imageList?.map((image) => image.default);
  const items = [...carouselImages];

  const price = getProductPrice({ storePrice, originalPrice });

  const handlers = useSwipeable({
    onSwipedLeft: () => onClickRight(activeIndex, items?.length, setActiveIndex),
    onSwipedRight: () => onClickLeft(activeIndex, setActiveIndex),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return !product ? null : (
    <>
      {isMobile ? (
        <Box
          id={`eresa-modal-main-product-${objectID}-mobile`}
          display="flex"
          flexDirection="column"
          minHeight="100%"
          justifyContent="space-between"
        >
          <Box>
            <Box display="flex" width="100%" overflow="hidden" position="relative" {...handlers}>
              {product.colorRef !== selectedColorRef && <LoadingScreen />}

              <CarouselItems
                activeIndex={activeIndex}
                length={items.length}
                width="calc(100vw - 32px)"
              >
                {items?.map((item, index) => (
                  <Box key={index} id={`eresa-modal-${objectID}-image`}>
                    <source srcSet={item.webp} type="image/webp" />
                    <source srcSet={item.jpg} type="image/jpeg" />
                    <img
                      style={{
                        width: 'calc(100vw - 32px)',
                        maxHeight: `calc((${window.innerWidth}px - 32px) / ${pictureRatio})`,
                        objectFit: 'cover',
                      }}
                      src={item.jpg}
                      alt={item.alt}
                    />
                  </Box>
                ))}
              </CarouselItems>
            </Box>
            <Box mt="s" mx="xs" display="flex" alignItems="center" justifyContent="space-between">
              <Icon
                data-cy="eresa-modal-mobile-icon-left-image-navigate-back"
                name="arrowLeft"
                color={getColorForLeftArrow(activeIndex)}
                onClick={() => onClickLeft(activeIndex, setActiveIndex)}
                id={`eresa-modal-${objectID}-image-navigate-back`}
              />
              <Text id={`eresa-modal-${objectID}-image-position-text`}>{`${activeIndex + 1} sur ${
                items.length
              }`}</Text>
              <Icon
                data-cy="eresa-modal-mobile-icon-right-image-navigate-forward"
                name="arrowRight"
                color={getColorForRightArrow(activeIndex, items.length)}
                onClick={() => onClickRight(activeIndex, items?.length, setActiveIndex)}
                id={`eresa-modal-${objectID}-image-navigate-forward`}
              />
            </Box>
            <Box my="l" display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex" fontSize="16px" fontWeight={700} lineHeight="110%">
                {`${capitalize(productName.split(' - ')?.[1])} - ${capitalize(
                  productName.split(' - ')?.[0]
                )}`}
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <ProductPrice price={price} />
              </Box>
            </Box>
            <Box display="grid" gridGap="m">
              <Colors
                objectID={objectID}
                productRef={productRef}
                detailedColorLabel={detailedColorLabel}
                colorVariants={colorVariants}
                onColorClick={onColorClick}
                colorRef={selectedColorRef}
              />
              <Sizes
                objectID={objectID}
                errMsg={errMsg}
                sizeMessage={sizeMessage}
                sortedSizes={sortedSizes}
                selectedSku={selectedSku}
                setSelectedSku={setSelectedSku}
                setHoveredSku={setHoveredSku}
                isNoLongerAvailable={isNoLongerAvailable}
                isEresaModal
                isQuickAdd
              />
            </Box>
          </Box>
          <Box my="m">
            <Button
              data-testid="eresa-modal-mobile-step-1-button-next-step"
              type="button"
              aria-label="Bouton pour passer à l'étape suivante de votre reservation"
              id="eresa-modal-button-step-1"
              disabled={!selectedSize}
              onClick={onClick}
            >
              {ERESA_NEXT}
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box id={`eresa-modal-main-product-${objectID}-dektop-tablet`}>
            <Box display="grid" gridGap="l" gridTemplateColumns="324px auto">
              <Box height="466px" width="324px" overflow="hidden" position="relative" {...handlers}>
                {product.colorRef !== selectedColorRef && <LoadingScreen />}
                <CarouselItems activeIndex={activeIndex} length={items.length} width="324px">
                  {items?.map((item, index) => (
                    <Box key={index} id={`eresa-modal-${objectID}-image`}>
                      <source srcSet={item.webp} type="image/webp" />
                      <source srcSet={item.jpg} type="image/jpeg" />
                      <img
                        style={{ width: '324px', height: '432px', objectFit: 'cover' }}
                        src={item.jpg}
                        alt={item.alt}
                      />
                    </Box>
                  ))}
                </CarouselItems>
                <Box
                  my="s"
                  mx="xs"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Icon
                    data-cy="eresa-modal-desktop-tablet-icon-left-image-navigate-back"
                    name="arrowLeft"
                    color={getColorForLeftArrow(activeIndex)}
                    onClick={() => onClickLeft(activeIndex, setActiveIndex)}
                    id={`eresa-modal-${objectID}-image-navigate-back`}
                  />
                  <Text id={`eresa-modal-${objectID}-image-position-text`}>{`${
                    activeIndex + 1
                  } sur ${items.length}`}</Text>
                  <Icon
                    data-cy="eresa-modal-dektop-tablet-icon-right-image-navigate-forward"
                    name="arrowRight"
                    color={getColorForRightArrow(activeIndex, items.length)}
                    onClick={() => onClickRight(activeIndex, items?.length, setActiveIndex)}
                    id={`eresa-modal-${objectID}-image-navigate-forward`}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="grid" gridGap="l" gridAutoRows="min-content">
                  <Box display="grid" gridAutoFlow="column" gridGap="s" alignItems="flex-start">
                    <Box display="flex" width="100%" justifyContent="space-between">
                      <Box display="flex" fontSize="16px" fontWeight={700} lineHeight="110%">
                        {`${capitalize(productName.split(' - ')?.[1])} - ${capitalize(
                          productName.split(' - ')?.[0]
                        )}`}
                      </Box>
                      <ProductPrice price={price} />
                    </Box>
                  </Box>
                  <Colors
                    objectID={objectID}
                    productRef={productRef}
                    detailedColorLabel={detailedColorLabel}
                    colorVariants={colorVariants}
                    onColorClick={onColorClick}
                    colorRef={selectedColorRef}
                    leftAlignToolTip
                  />
                  <Box display="grid" gridGap="m" gridAutoRows="min-content">
                    <Sizes
                      objectID={objectID}
                      errMsg={errMsg}
                      sizeMessage={sizeMessage}
                      sortedSizes={sortedSizes}
                      selectedSku={selectedSku}
                      setSelectedSku={setSelectedSku}
                      setHoveredSku={setHoveredSku}
                      isNoLongerAvailable={isNoLongerAvailable}
                      isEresaModal
                      isQuickAdd
                    />
                  </Box>
                </Box>

                <Button
                  data-testid="eresa-modal-desktop-tablet-step-1-button-next-step"
                  type="button"
                  aria-label="Bouton pour passer à l'étape suivante de votre reservation"
                  id="eresa-modal-button-step-1"
                  onClick={onClick}
                  disabled={!selectedSize}
                >
                  {ERESA_NEXT}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

type CarouselItemsProps = {
  activeIndex: number;
  length: number;
  width: string;
};

const CarouselItems = styled.div<CarouselItemsProps>(
  ({ activeIndex, length, width }) =>
    activeIndex > -1 && {
      transform: `translate3d(calc(-1 * ${activeIndex} * ${width}), 0px, 0px)`,
      display: 'flex',
      width: `calc(${length} * ${width})`,
      transition: 'transform 0.3s ease-in-out',
    }
);

export default EresaMainProduct;
