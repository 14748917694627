import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { zIndex } from '../../common/constants';
import { getPromotionColors } from '../utils';
import { colors, Icon } from '../../../design-system';

type Props = {
  objectID: string;
  promotionPercentage?: string;
  promotionLabel?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  isOutlet?: boolean;
  isPositionAbsolute?: boolean;
  rankLabel?: string;
};

type StyledSpanProps = {
  isPositionAbsolute: boolean;
  promotionBgColor: string;
  promotionTextColor: string;
};

const StyledPromotionLabelContainer = styled.div<StyledSpanProps>`
  display: flex;
  gap: 4px;
  max-width: calc(100% - 16px);
  position: ${(props) => (props.isPositionAbsolute ? 'absolute' : 'initial')};
  top: 8px;
  right: 8px;
  z-index: ${zIndex.PRODUCT_PROMOTION};

  .promotion-label-block,
  .rank-label-block {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 8px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 700;
    }
  }

  .promotion-label-block {
    background-color: ${(props) => props.promotionBgColor};

    span {
      color: ${(props) => props.promotionTextColor};
    }
  }

  .rank-label-block {
    background-color: ${colors.BLACK};
    gap: 4px;

    span {
      color: ${colors.WHITE};
    }
  }

  .icon-rank-label {
    margin-top: -3px;
  }
`;

export const PromotionLabel = ({
  promotionPercentage,
  promotionLabel,
  promotionTextColor,
  promotionBackgroundColor,
  isOutlet,
  objectID,
  isPositionAbsolute = false,
  rankLabel,
}: Props) => {
  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  if (!(promotionPercentage || promotionLabel || rankLabel)) {
    return null;
  }

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
    promotionTextColor,
    promotionBackgroundColor,
    isOutlet,
  });
  const isBestseller = rankLabel?.toLocaleLowerCase() === 'bestseller';
  const promotionLabelContent =
    promotionPercentage && !isOutlet ? `-${promotionPercentage}` : promotionLabel;

  return (
    <StyledPromotionLabelContainer
      id={`catalog-item-promotion-label-${objectID}`}
      isPositionAbsolute={isPositionAbsolute}
      promotionBgColor={backgroundColor}
      promotionTextColor={textColor}
    >
      {rankLabel && (
        <div className="rank-label-block">
          {isBestseller && (
            <Icon className="icon-rank-label" name="star" size={12} color={colors.WHITE} />
          )}
          <span title={rankLabel} aria-label={rankLabel}>
            {rankLabel}
          </span>
        </div>
      )}
      {(promotionLabel || promotionPercentage) && (
        <div className="promotion-label-block">
          <span title={promotionLabelContent} aria-label={promotionLabelContent}>
            {promotionLabelContent}
          </span>
        </div>
      )}
    </StyledPromotionLabelContainer>
  );
};
