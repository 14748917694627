import React from 'react';
import { css } from '@emotion/core';

import { breakpoints, theme } from '../../../design-system/systemprovider/constants';
import { CmsUspContent } from '../../cms/types';
import { Title } from '../../common/components/Custom';
import UspItem from './UspItem';

type Props = {
  content: CmsUspContent[];
  title: string;
  isProductPage?: boolean;
};

export const UspSection = ({ title, content, isProductPage = false }: Props) => {
  return (
    <div
      css={css`
        display: grid;
        text-align: center;
        margin: 32px 0;
        grid-gap: 24px;

        @media (min-width: ${breakpoints.M}px) {
          margin: 80px ${isProductPage ? '0' : '24px'};
          grid-gap: 40px;
        }
      `}
    >
      <Title>{title}</Title>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: initial;
          grid-auto-columns: initial;

          @media (min-width: ${breakpoints.S}px) {
            justify-content: center;
            align-items: start;
            gap: ${theme.space.m};
          }
          @media (min-width: ${breakpoints.M}px) {
            gap: ${theme.space.m};
          }
        `}
      >
        {content.map((item, index) => (
          <UspItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};
