export function getImageHeight({
  image,
  width,
}: {
  image?: {
    dimensions?: {
      width: number;
      height: number;
    };
  };
  width: string;
}): string {
  return `calc(${width} * ${(image?.dimensions?.height ?? 0) / (image?.dimensions?.width ?? 1)})`;
}

export function getTextCentered(text, banner) {
  if (banner.cta_text !== null) {
    return 'm';
  }
  const getFirstTextLineHeigh = () => {
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-80pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-80pt')
    ) {
      return 54;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-50pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-50pt')
    ) {
      return 44;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-40pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-40pt')
    ) {
      return 34;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-30pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-30pt')
    ) {
      return 24;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-20pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-20pt')
    ) {
      return 20;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-16pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-16pt')
    ) {
      return 18;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-12pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-12pt')
    ) {
      return 16;
    }
    return 0;
  };

  const getLastTextLineHeigh = () => {
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-80pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-80pt')
    ) {
      return 54;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-50pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-50pt')
    ) {
      return 44;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-40pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-40pt')
    ) {
      return 34;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-30pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-30pt')
    ) {
      return 24;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-20pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-20pt')
    ) {
      return 20;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-16pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-16pt')
    ) {
      return 18;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-12pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-12pt')
    ) {
      return 16;
    }
    return 0;
  };

  const firstLineHeight = getFirstTextLineHeigh();
  const lastLineHeight = getLastTextLineHeigh();
  const difLineHeightFirstLast = firstLineHeight - lastLineHeight;

  let fontFirstElement = '';
  text && text[0]?.spans[0]?.data?.label !== ''
    ? (fontFirstElement = text[0]?.spans[0]?.data?.label.slice(0, -5))
    : (fontFirstElement = '');
  const getAdjustedPaddingOggText = () => {
    if (difLineHeightFirstLast < -20) {
      return '16px';
    }
    if (difLineHeightFirstLast >= -20 && difLineHeightFirstLast <= -8) {
      return '12px';
    }
    if (difLineHeightFirstLast > -8 && difLineHeightFirstLast < 8) {
      return '10px';
    }
    if (difLineHeightFirstLast >= 8 && difLineHeightFirstLast <= 20) {
      return '8px';
    }
    if (difLineHeightFirstLast > 20 && difLineHeightFirstLast < 34) {
      return '6px';
    }
    return '4px';
  };

  const getAdjustedPaddingMaisonNeue = () => {
    if (difLineHeightFirstLast <= -20) {
      return '18px';
    }
    if (difLineHeightFirstLast > -20 && difLineHeightFirstLast < -8) {
      return '16px';
    }
    if (difLineHeightFirstLast === 0 && (firstLineHeight === 44 || firstLineHeight === 54)) {
      return '14px';
    }
    if (
      (difLineHeightFirstLast >= 14 && difLineHeightFirstLast <= 18) ||
      difLineHeightFirstLast === 8
    ) {
      return '10px';
    }
    if (difLineHeightFirstLast >= -8 && difLineHeightFirstLast <= 20) {
      return '12px';
    }
    return '8px';
  };

  const getAdjustedPaddingOnlyOneLine = () => {
    if (fontFirstElement !== 'MaisonNeue') {
      if (firstLineHeight >= 16 && firstLineHeight <= 34) {
        return '10px';
      }
      if (firstLineHeight > 34 && firstLineHeight <= 54) {
        return '14px';
      }
    }
    if (fontFirstElement === 'MaisonNeue') {
      if (firstLineHeight >= 16 && firstLineHeight < 24) {
        return '12px';
      }
      if (firstLineHeight >= 24 && firstLineHeight < 54) {
        return '14px';
      }
      if (firstLineHeight === 54) {
        return '24px';
      }
    }
    return 'm';
  };
  if (banner.text_right && banner.text_right.length !== 0 && banner.text_right?.[0]?.text !== '') {
    return 's';
  }
  if (lastLineHeight === 0) {
    return getAdjustedPaddingOnlyOneLine();
  }
  if (fontFirstElement === 'MaisonNeue') {
    return getAdjustedPaddingMaisonNeue();
  }
  if (fontFirstElement !== 'MaisonNeue') {
    return getAdjustedPaddingOggText();
  }

  return 'na';
}
