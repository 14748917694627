import React, { useEffect, useRef, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import {
  Icon,
  Field,
  DynamicButtonStatus,
  DynamicButton,
  breakpoints,
  colors,
} from '../../../design-system';
import { CmsFooter } from '../../cms/types';
import { Forms } from '../../form/types';
import { setFormValues, setFormValidation, setCtaState } from '../../form/actions';
import { validate } from '../../form/utils';
import { RootState } from '../../../store/rootReducer';
import { subscribeNewsletter } from '../utils';
import { CTA_SUBSCRIBE, LBL_EMAIL } from '../locale';
import { pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  cmsContent: CmsFooter;
};

const StyledBoxForRichText = styled.div`
  margin-bottom: 24px;
  color: ${colors.GREY};
  width: auto;
  text-align: center;
  @media (min-width: ${breakpoints.S}px) {
    width: 451px;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 8px;
  }
`;

export const Newsletter = ({ cmsContent }: Props) => {
  const icon = cmsContent?.icon_news_letter;
  const title = cmsContent?.news_title;
  const inputText = cmsContent?.news_input_text;
  const bodyRichText = cmsContent?.news_body_text;
  const backgroundColor = cmsContent?.news_background_color ?? 'BACKGROUND';
  const newsLetterRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form.footerNewsletter);

  useEffect(() => {
    return () => {
      dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: '' } }));
      dispatch(setFormValidation({ form: Forms.footerNewsletter, values: { email: '' } }));
      dispatch(
        setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Default })
      );
    };
  }, []);

  const validateEmail = (value: string): string =>
    validate({
      value: String(value),
      key: 'email',
      isRequired: true,
    });

  const onEmailChange = (value: string) => {
    if (form.validation.email) {
      dispatch(
        setFormValidation({
          form: Forms.footerNewsletter,
          values: { email: validateEmail(value) },
        })
      );
    }

    dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: value } }));
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errMsg = validateEmail(form.values.email);
    dispatch(
      setFormValidation({
        form: Forms.footerNewsletter,
        values: { email: errMsg },
      })
    );

    if (!errMsg) {
      dispatch(
        setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Loading })
      );
      const isOk = await subscribeNewsletter({ mail: form.values.email, origin: 'footer' });
      if (isOk) {
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Success })
        );
        pushToGTM(Events.newsletterCompletion, { type: 'footer' });

        await new Promise((resolve) => setTimeout(resolve, 3000));
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Default })
        );
        dispatch(setFormValues({ form: Forms.footerNewsletter, values: { email: '' } }));
      } else {
        dispatch(
          setCtaState({ form: Forms.footerNewsletter, ctaState: DynamicButtonStatus.Error })
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(
          setCtaState({
            form: Forms.footerNewsletter,
            ctaState: DynamicButtonStatus.Default,
          })
        );
      }
    }
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <div
        ref={newsLetterRef}
        id="box-newsletter"
        css={css`
          padding: 40px 16px;
          background-color: ${backgroundColor};
          display: flex;
          flex-direction: column;
          width: 100%;
          min-height: 385px;
          align-items: center;

          @media (min-width: ${breakpoints.S}px) {
            padding: 40px 0px;
            min-height: 297px;
          }

          @media (min-width: ${breakpoints.M}px) {
            min-height: 304px;
          }

          @media (min-width: ${breakpoints.XL + 16}px) {
            border-radius: 8px;
          }
        `}
      >
        {icon && <Icon name={icon} size={30} />}
        {title && (
          <h3
            css={css`
              margin: 12px 0 16px 0;
              font-size: 3rem;
              font-weight: 700;
              font-family: ogg-text;
              text-align: center;
              line-height: 110%;

              @media (min-width: ${breakpoints.S}px) {
                margin: 12px 0 8px 0;
                font-size: 2.4rem;
              }

              @media (min-width: ${breakpoints.M}px) {
                font-size: 3rem;
              }
            `}
          >
            {title}
          </h3>
        )}
        {hasValidCmsText(bodyRichText) && (
          <StyledBoxForRichText>
            <PrismicRichText field={bodyRichText} />
          </StyledBoxForRichText>
        )}
        <div
          css={css`
            display: flex;
            width: 100%;
            padding-bottom: ${form.validation.email !== '' ? '16px' : '4px'};

            @media (min-width: ${breakpoints.S}px) {
              width: 451px;
            }
          `}
        >
          <div
            css={css`
              height: 50px;
              border: solid 1px ${colors.LIGHT};
              width: 60%;

              @media (min-width: ${breakpoints.S}px) {
                width: 308px;
              }
            `}
          >
            <Field
              id="input-newsletter-email"
              value={form.values.email}
              onChange={onEmailChange}
              errMsg={form.validation.email}
              placeholder={LBL_EMAIL}
              type="email"
              autoComplete="email"
              isFooterInput
            />
          </div>
          <div
            css={css`
              height: 50px;
              background-color: ${colors.BLACK};
              padding: 2px 0;
              width: 40%;
              margin-top: 1px;
              border-radius: 8px;

              @media (min-width: ${breakpoints.S}px) {
                width: 143px;
              }
            `}
          >
            <DynamicButton
              id="btn-newsletter-send"
              type="submit"
              data={CTA_SUBSCRIBE}
              feedback={form.ctaState}
            />
          </div>
        </div>
        {inputText && (
          <div
            css={css`
              font-size: 1.2rem;
              line-height: 110%;
              margin-top: 8px;
              width: 100%;

              @media (min-width: ${breakpoints.S}px) {
                width: 451px;
              }
            `}
          >
            {inputText}
          </div>
        )}
      </div>
    </form>
  );
};
