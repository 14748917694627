export default {
  DAYS: 'jours',
  HOURS: 'heures',
  MINUTES: 'minutes',
  SECONDS: 'secondes',
  DAY: 'jour',
  HOUR: 'heure',
  MINUTE: 'minute',
  SECOND: 'seconde',
} as const;

export const offers = {
  TITLE: 'Offres en cours',
  LBL_CONDITIONS: 'Conditions',
  LBL_EXCLUSIONS: 'Exclusions',
  CTA_LINK: 'Voir la sélection',
  LBL_NAME: 'Offre',
  LBL_VALIDITY: 'Validité',
  LBL_COUPON_CODE: 'Code',
  TXT_NO_COUPON_CODE: 'Automatique',
  CTA_COPY: 'Copier',
  CTA_COPIED: 'Copié !',
  TOOLTIP_COPY: 'cliquez pour copier',
  EXPIRING_PROMOTION: 'C’est presque fini',
  EXPIRED_PROMOTION: 'L’offre est terminée',
};
