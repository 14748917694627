import React from 'react';
import styled from '@emotion/styled';

import { breakpoints } from '../../../design-system';
import { ColorPanel } from './ColorPanel';
import { Color } from '../../product/types';
import { HighlightedName } from './HighlightedName';
import { isItGiftCard } from '../../product/utils';
import PatternPanel from './PatternPanel';
import { Price } from './Price';
import { PromotionLabel } from '../../product/components/PromotionLabel';

type Props = {
  colorVariants: Color[];
  productRef: string;
  productName: string;
  objectID: string;
  typology?: string;
  collectionName: string;
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  selectedColor: Color;
  firstSelectedColor: { colorRef: string; originalPrice: number; storePrice: number };
  reverseToolTip?: boolean;
  leftAlignToolTip?: boolean;
  attributeLabel?: string;
  handleColorClick: (color: Color) => void;
  onLinkClick: () => void;
};

const StyledContainer = styled.div`
  padding: 8px 8px 16px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${breakpoints.S}px) {
    padding: 8px 0;
  }
`;

export const InfoPanel = ({
  productRef,
  productName,
  colorVariants,
  objectID,
  isFeedback,
  selectedColor,
  isDesktopFeedback,
  typology,
  collectionName,
  firstSelectedColor,
  reverseToolTip = false,
  leftAlignToolTip = false,
  attributeLabel,
  handleColorClick,
  onLinkClick,
}: Props) => {
  const { originalPrice, storePrice } =
    selectedColor.colorRef === firstSelectedColor.colorRef ? firstSelectedColor : selectedColor;

  const isGiftCard = isItGiftCard(productRef);

  return (
    <StyledContainer id={`catalog-item-info-panel-${objectID}`}>
      <HighlightedName
        objectID={objectID}
        productName={productName}
        typology={typology}
        collectionName={collectionName}
      />
      <Price originalPrice={originalPrice} storePrice={storePrice} />
      {!isGiftCard ? (
        <div className="info-panel-colors-block">
          <ColorPanel
            productRef={productRef}
            productName={productName}
            colorVariants={colorVariants}
            selectedColor={selectedColor}
            objectID={objectID}
            handleColorClick={handleColorClick}
            onLinkClick={onLinkClick}
            isFeedBack={isFeedback}
            isDesktopFeedback={isDesktopFeedback}
            reverseToolTip={reverseToolTip}
            leftAlignToolTip={leftAlignToolTip}
          />
        </div>
      ) : (
        <PatternPanel objectID={objectID} />
      )}
      {!(isDesktopFeedback || isFeedback) && (
        <PromotionLabel objectID={objectID} attributeLabel={attributeLabel} />
      )}
    </StyledContainer>
  );
};
