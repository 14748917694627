const TXT_RESERVATION = 'Réserver gratuitement';
const MSG_ADDED = 'Ajouté';
const MSG_ERROR = 'Une erreur est survenue. Réessayez';
export const ECART_CTA_CONTENT: [string, string, string] = [
  TXT_RESERVATION.toUpperCase(),
  MSG_ADDED.toUpperCase(),
  MSG_ERROR.toUpperCase(),
];
const MSG_ERROR_STICKY_CTA = 'Une erreur est survenue';
const MSG_ADD = 'Ajouter au panier';
const MSG_ADD_MOBILE = 'AJOUTER';
export const CTA_CONTENT = (isMobile?: boolean): [string, string, string] => [
  isMobile ? MSG_ADD_MOBILE : MSG_ADD,
  isMobile ? MSG_ADDED.toUpperCase() : MSG_ADDED,
  isMobile ? MSG_ERROR.toUpperCase() : MSG_ERROR,
];
export const CTA_CONTENT_STICKY_CTA = (isShortText: boolean): [string, string, string] => [
  isShortText ? MSG_ADD_MOBILE : MSG_ADD,
  MSG_ADDED,
  MSG_ERROR_STICKY_CTA,
];
export const CTA_CONTENT_PREORDER: [string, string, string] = [
  'PRÉ-COMMANDER',
  MSG_ADDED,
  MSG_ERROR,
];
export const TWO_LEFT = 'Plus que 2 pièces disponibles';
export const ONE_LEFT = 'Plus qu’une pièce disponible';
export const NONE_LEFT = 'Taille temporairement indisponible';
export const ERR_CHOOSE_SIZE = 'Choisissez votre taille';
export const LINK_SIZE_GUIDE = 'Guide des tailles';
export const LBL_COLOR = 'Couleur';
export const LBL_SIZE = 'Taille';
export const LBL_QUANTITY = 'Quantité';
export const LBL_PATTERN = 'Motif';
export const LBL_EMAIL_PATTERN = "Motif de l'e-mail";
export const LBL_AMOUNT = 'Montant';
export const TXT_DETAILS_DESCRIPTION = 'Description';
export const TXT_DETAILS_COMPOSITION = 'Composition et entretien';
export const TXT_DETAILS_DELIVERY_AND_RETURN = 'Livraison, échanges et retours';
export const TXT_DETAILS_E_RESERVATION = 'Réservation gratuite en boutique';
export const TXT_DETAILS_MORE_DETAILS = 'Le détail en plus';

export const CHECK_AVAILABILITY_IN_OTHER_STORES = 'Voir la disponibilité dans une autre boutique';
export const SELECT_SIZE = 'Sélectionnez votre taille';
export const MAX_LENGTH_GIFT_CARD_MESSAGE = 300;
export const LBL_GIFT_CARD_DESCRIPTION_TITLE = `Comment l'utiliser`;
export const LBL_GIFT_CARD_ONLINE = 'En ligne';
export const LBL_GIFT_CARD_DESCRIPTION_ONLINE = `Lors de l'étape du paiement, saisissez les 19 numéros inscrits sur votre carte cadeau dans le champ correspondant ainsi que votre code PIN.`;
export const LBL_GIFT_CARD_STORE = 'En boutique';
export const LBL_GIFT_CARD_DESCRIPTION_STORE =
  'Présentez votre carte cadeau directement sur votre téléphone ou imprimée.';
export const LBL_PRODUCT_REVIEWS_TITLE = 'Les avis de nos clientes';
